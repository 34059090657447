import { PlatformLocation } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AuthToken, User, Dashboard } from 'projects/shared/models/user';
import { HttpProvider } from 'projects/shared/services/http.provider';
import { FooterMenu } from '../models/menu';


@Injectable({ providedIn: 'root' })

export class BaseApiProvider {
  pathprefix = '';
  routeprefix = '';
  http: HttpProvider;
  platformLocation: PlatformLocation;

  constructor(injector: Injector) {
    this.http = injector.get(HttpProvider);
    this.platformLocation = injector.get(PlatformLocation);
    this.pathprefix = (this.platformLocation as any).location.origin + this.platformLocation.getBaseHrefFromDOM();
  }


  // returns url for ckeditor file uploader
  fileUploadUrl(): string {
    return this.http.fileUploadUrl(`${this.routeprefix}/files/uploader`);
  }
  apiHeader() {
    return this.http.apiHeader();
  }

  uploadFile(form: FormData): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.postForm('/admin/files/uploader', form, this.apiHeader()).subscribe((data) => {
        resolve(data);
      }, (e) => {
        reject(e);
      });
    });
  }


  // user auth
  getMe(): Promise<User> {
    return new Promise((resolve, reject) => {
      this.http.getData('/auth/me').subscribe((data) => {
        resolve(new User().fromJson(data));
      }, (e) => {
        reject(e);
      });
    });
  }

  // getFooter(): Promise<FooterMenu> {
  //     return new Promise((resolve, reject) => {
  //         this.http.getData('/menu/footer', null, true).subscribe((data) => {
  //             resolve(new FooterMenu().fromJson(data));
  //         }, (e) => {
  //             reject(e);
  //         });
  //     });
  // }

  login(json: any): Promise<AuthToken> {
    return new Promise((resolve, reject) => {
      this.http.postData('/auth/login', json).subscribe((data) => {
        resolve(new AuthToken().fromJson(data));
      }, (e) => {
        reject(e);
      });
    });
  }

  logout(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http.postData('/auth/logout', {}).subscribe((data) => {
        resolve(data as string);
      }, (e) => {
        reject(e);
      });
    });
  }

  refreshToken(): Promise<AuthToken> {
    return new Promise((resolve, reject) => {
      this.http.postData('/auth/refresh', {}).subscribe((data) => {
        resolve(new AuthToken().fromJson(data));
      }, (e) => {
        reject(e);
      });
    });
  }

  register(json: any): Promise<AuthToken> {
    return new Promise((resolve, reject) => {
      this.http.postData('/auth/register', json).subscribe((data) => {
        resolve(new AuthToken().fromJson(data));
      }, (e) => {
        reject(e);
      });
    });
  }


  forgotpassword(json: any): Promise<any> {
    json.host = this.pathprefix;
    return new Promise((resolve, reject) => {
      this.http.postData('/auth/forgotpassword', json).subscribe((data) => {
        resolve(data);
      }, (e) => {
        reject(e);
      });
    });
  }

  resetpassword(json: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.postData('/auth/resetpassword', json).subscribe((data) => {
        resolve(data);
      }, (e) => {
        reject(e);
      });
    });
  }


  getDashboard(): Promise<Dashboard> {
    return new Promise((resolve, reject) => {
      this.http.getData('/profile/dashboard', undefined).subscribe((data) => {
        resolve(new Dashboard().fromJson(data));
      }, (e) => {
        reject(e);
      });
    });
  }

  // generic crud
  getObject<T extends GenericModel>(c: T, t: string, id: string): Promise<T> {
    return new Promise((resolve, reject) => {
      this.http.getData(`${this.routeprefix}/${t.toLowerCase()}/${id}`, undefined).subscribe((data) => {
        resolve(c.fromJson(data));
      }, (e) => {
        reject(e);
      });
    });
  }

  getObjects<T extends GenericModel>(c: T, t: string, params = {}): Promise<T[]> {
    return new Promise((resolve, reject) => {
      const querystring = new HttpParams({ fromObject: params }).toString();
      this.http.getData(`${this.routeprefix}/${t.toLowerCase()}?${querystring}`, undefined).subscribe((results) => {
        resolve(c.listFromJson(results as []));
      }, (e) => {
        reject(e);
      });
    });
  }

  postObject<T extends GenericModel>(c: T, t: string, id: string, body: any): Promise<T> {
    return new Promise((resolve, reject) => {
      this.http.postData(`${this.routeprefix}/${t.toLowerCase()}/${id}`, body, undefined).subscribe((data) => {
        resolve(c.fromJson(data));
      }, (e) => {
        reject(e);
      });
    });
  }


  postObjects<T extends GenericModel>(c: T, t: string, body: any): Promise<T> {
    return new Promise((resolve, reject) => {
      this.http.postData(`${this.routeprefix}/${t.toLowerCase()}`, body, undefined).subscribe((data) => {
        resolve(c.fromJson(data));
      }, (e) => {
        reject(e);
      });
    });
  }

  deleteObject(t: string, id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.deleteData(`${this.routeprefix}/${t.toLowerCase()}/${id}`, {}, undefined).subscribe((data) => {
        resolve(data);
      }, (e) => {
        reject(e);
      });
    });
  }

  postForm<T extends GenericModel>(c: T, t: string, id: string, form: FormData): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.postForm(`${this.routeprefix}/${t.toLowerCase()}/${id}`, form, this.apiHeader()).subscribe((data) => {
        resolve(c.fromJson(data));
      }, (e) => {
        reject(e);
      });
    });
  }

  // External API


}
