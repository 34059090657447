import './genericmodel';

export class Category implements GenericModel {
    updated_at: Date;
    created_at: Date;

    id: string;
    label: string;
    url: string;
    children: Category[] = [];

    fromJson(json: any): Category {
        const ret = Object.assign(new Category(), json);
        ret.children = json.children ? (new Category()).listFromJson(json.children) : [];
        return ret;
    }

    listFromJson(json: Array<any>): Category[] {
        return json.map((i: any) => this.fromJson(i));
    }

    toJson() {
        return {
            id: this.id,
            label: this.label,
            url: this.url,
            children: this.children,
        };
    }

}
