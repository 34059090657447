import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare var UIkit: any;

// confirm dialog

@Component({
  selector: 'app-confirm-dialog',
  template: `
  <div uk-modal>
    <div class="uk-modal-dialog">
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <div class="uk-modal-header">
        <h2 class="uk-modal-title">{{title}}</h2>
      </div>
      <div class="uk-modal-body">
        <p [innerHtml]="message">{{message}}</p>
      </div>
      <div class="uk-modal-footer uk-text-right">
          <button type="button" class="uk-button uk-button-default uk-modal-close" (click)="activeModal.dismiss()">Cancel</button>
          <button type="button" class="uk-button uk-button-primary" (click)="activeModal.close(true)">Confirm</button>
      </div>
    </div>
  </div>
  `
})
export class ConfirmDialogComponent {
  @Input() title: string;
  @Input() message: string;

  constructor(public activeModal: NgbActiveModal) { }

  static show(modal: NgbModal, title: string, message: string): Promise<any> {
    return new Promise((resolve, reject) => {
      UIkit.modal.confirm(`<h4>${title}</h4><div>${message}</div>`, { labels: { ok: 'Confirm', cancel: 'Cancel' } }).then(() => {
        resolve(true);
      }, function () {
        reject();
      });
    });
  }
}

// message dialog

@Component({
  selector: 'app-message-dialog',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">{{title}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p [innerHtml]="message">{{message}}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn" (click)="activeModal.dismiss()">Close</button>
    </div>
  `
})
export class MessageDialogComponent {
  @Input() title: string;
  @Input() message: string;

  constructor(public activeModal: NgbActiveModal) { }

  static show(modal: NgbModal, title: string, message: string): Promise<any> {
    // const ref = modal.open(MessageDialogComponent, { centered: true });
    // ref.componentInstance.title = title;
    // ref.componentInstance.message = message;
    // return ref.result;

    return new Promise((resolve, reject) => {
      UIkit.modal.alert(`<h3>${title}</h3><p>${message}</p>`).then(() => {
        resolve(true);
      });
    });
  }

}
