import { Component, OnInit, Input } from '@angular/core';
import { Order } from 'projects/shared/models/order';

@Component({
  selector: 'app-order-tile',
  templateUrl: './order-tile.component.html',
  styleUrls: ['./order-tile.component.scss']
})
export class OrderTileComponent implements OnInit {

  @Input() item: Order;

  constructor() { }

  ngOnInit(): void {
  }

}
