import { Component, OnInit } from '@angular/core';
declare const TrackButton: any;

@Component({
  selector: 'app-track-parcel',
  templateUrl: './track-parcel.component.html',
  styleUrls: ['./track-parcel.component.scss']
})
export class TrackParcelComponent implements OnInit {

  constructor() { }

  trackno: String = "";

  ngOnInit(): void {
    this.loadScripts();
  }

  // Method to dynamically load JavaScript 
  loadScripts() {

    // This array contains all the files/CDNs 
    const dynamicScripts = [
      '//www.tracking.my/track-button.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  inputTrack() {
    if (this.trackno === "") {
      alert("Please enter tracking number");
      return;
    }
    new TrackButton.track({
      tracking_no: this.trackno
    });
  }
}
