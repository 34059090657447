import { Component, OnInit } from '@angular/core';
import { AccountProvider } from 'projects/shared/services/account.provider';
import { User } from 'projects/shared/models/user';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ApiProvider } from '../../../services/api.service';
import { ToastService } from 'projects/shared/services/toast.provider';
import { BrowserTitle } from 'projects/shared/services/utilities.provider';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class ProfileEditComponent implements OnInit {

  user: User;
  profileForm: FormGroup;
  validation = { name: '', email: '', phone: '', newpassword: '', newavatar: '' };
  showpassword = false;

  constructor(
    private account: AccountProvider,
    private fb: FormBuilder,
    private toast: ToastService,
    private title: BrowserTitle,
  ) {
    this.title.setTitle('Edit Profile');
    this.user = account.currentUser;
    this.profileForm = this.fb.group({
      name: [this.user.name, Validators.required],
      email: [this.user.email, [Validators.required, Validators.email]],
      phone: [this.user.phone, Validators.pattern('\\d{8,12}')],
      newpassword: ['', Validators.minLength(8)],
      newavatar: ['']
    });
  }

  ngOnInit(): void {
  }

  uploadFile(filelist: FileList) {
    const f = filelist.item(0);
    // check image size
    if (f.size > 1024 * 1024) {
      this.toast.show(f.name + ' is too large (' + (f.size / 1024 / 1024).toFixed(1) + ' MB)', { classname: 'text-light bg-danger' });
      return;
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      this.profileForm.patchValue({ newavatar: event.target.result.toString() });
    };
    reader.readAsDataURL(f);
  }
  update() {
    // update profile
    this.validation = { name: '', email: '', phone: '', newpassword: '', newavatar: '' };
    this.account.updateProfile(this.profileForm.value).then((result) => {
      this.profileForm.patchValue({ newavatar: '', newpassword: '' });
      this.toast.show('Profile Updated', { classname: 'text-light bg-success' });
    }, (response) => {
      if (response.error.validation) {
        this.validation = response.error.validation;
      } else if (response.error.error) {
        this.toast.show(response.error.error, { classname: 'uk-text-light uk-background-warning' });
      } else {
        this.toast.show(response.message, { classname: 'uk-text-light uk-background-warning' });
      }
    });
  }

}
