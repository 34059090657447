<h5>Recent Credit History</h5>
<hr>

<table>
  <tr *ngFor="let item of creditlogs">
    <td>{{item.created_at}}</td>
    <td>{{item.amount}}</td>
    <td>{{item.remark}}</td>
  </tr>
</table>
