<section class="uk-section uk-section-small uk-container">
    <article class="uk-article">
        <h3 class="uk-article-title uk-margin-remove-bottom">Track Your Parcel</h3>
        <ul class="uk-breadcrumb uk-margin-remove-top">
            <li><a [routerLink]="['/']">Home</a></li>
            <li><a [routerLink]="['/track-parcel']">Track Your Parcel</a></li>
        </ul>
        <div class="uk-margin">
            <p>You may enter your tracking number here to track your parcel:</p>
            <input type="text" name="trackno" maxlength="50" class="uk-input uk-width-1-3 uk-margin-small-right"
                [(ngModel)]="trackno">
            <button class="uk-button uk-button-secondar" (click)="inputTrack()">Track</button>
        </div>
    </article>
</section>