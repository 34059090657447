import { Component, Input, OnInit } from '@angular/core';
import { Banner } from 'projects/shared/models/banner';
import { ApiProvider } from '../../services/api.service';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  @Input() name: string;
  banner: Banner = new Banner();

  constructor(private api: ApiProvider) { }

  ngOnInit(): void {
    this.api.getBanner(this.name).then((result) => {
      this.banner = result;
    });
  }

}
