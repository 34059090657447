import './genericmodel';

export class Payment implements GenericModel {
    id = 'new';
    updated_at: Date;
    created_at: Date;
    order_id: string;
    method: string;
    amount: string;
    success: boolean;
    with_token: boolean;

    fromJson(json: any): Payment {
        const ret = Object.assign(new Payment(), json);
        return ret;
    }

    listFromJson(json: Array<any>): Payment[] {
        return json.map((i: any) => this.fromJson(i));
    }

    toJson() {
        return {};
    }

}
