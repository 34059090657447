import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'projects/shared/guards/auth-guard.guard';
import { AddressbookComponent } from './addressbook/addressbook.component';
import { AddressbookEditComponent } from './addressbook/edit-address/edit-address.component';
import { CreditsComponent } from './credits/credits.component';
import { ProfileEditComponent } from './edit-profile/edit-profile.component';
import { MemberPointsComponent } from './member-points/member-points.component';
import { OrderShowComponent } from './orders/order-show/order-show.component';
import { OrdersComponent } from './orders/orders.component';
import { ProfileComponent } from './profile/profile.component';
import { UserComponent } from './user.component';
import { VouchersComponent } from './vouchers/vouchers.component';
import { WishlistComponent } from './wishlist/wishlist.component';

const routes: Routes = [
  // routes that require login must go through canActivate: [AuthGuard]
  {
    path: '',
    component: UserComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: ProfileComponent },
      { path: 'addressbook', component: AddressbookComponent },
      { path: 'addressbook/:id', component: AddressbookEditComponent },
      { path: 'memberpoints', component: MemberPointsComponent },
      { path: 'credits', component: CreditsComponent },
      { path: 'vouchers', component: VouchersComponent },
      { path: 'wishlist', component: WishlistComponent },
      { path: 'orders', component: OrdersComponent },
      { path: 'orders/:refno', component: OrderShowComponent },
      { path: 'editprofile', component: ProfileEditComponent },
    ],
  },


];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
})
export class UserRoutingModule { }
