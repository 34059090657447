<div id="banner" class=" uk-visible-toggle uk-light" tabindex="-1" uk-slider="autoplay: true">

  <ul class="uk-slider-items uk-child-width-1">
    <li *ngFor="let item of banner.items">
      <a *ngIf="item.url != null;else img_only" [routerLink]="[item.url]"><img [src]="item.image" alt="" uk-svg></a>
      <ng-template #img_only><img [src]="item.image" alt="" uk-svg> </ng-template>
    </li>
  </ul>
  <!--
  <div class="uk-position-bottom-center uk-position-medium uk-light uk-visible@s">
    <ul class="el-nav uk-thumbnav uk-flex-center">
      <li *ngFor="let navitem of banner.items; let i = index" [attr.uk-slideshow-item]="i" class="uk-first-column">
        <a href="#"><img src="{{navitem.image}}" width="100" alt="" uk-img></a>
      </li>
    </ul>
  </div>
</div> -->
  <!-- <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slider>

  <ul class="uk-slider-items uk-child-width-1-2 uk-child-width-1-4@m uk-grid"> -->
  <!-- <li *ngFor="let item of banner.items">
      <div class="uk-panel">
        <a [routerLink]="[item.url]"><img [src]="item.image" alt=""></a>
      </div>
    </li>
  </ul>

  <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
  <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>

</div>
-->