import { Pipe, PipeTransform, NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import 'moment/min/locales';


// currency formatter
@Pipe({ name: 'price_format' })
export class PriceFormatPipe implements PipeTransform {
    transform(value: number): String {
        //Huawei browser cannot use this
        /*const formatter = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });*/
        return 'RM ' + value.toFixed(2);
    }
}

@Pipe({ name: 'date_display' })
export class DateDisplayPipe extends DatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        // console.log('value');
        // console.log(value); //Thu Jun 18 2020 10:22:07 GMT+0800 (Malaysia Time)
        // console.log('value');
        moment.locale('KUL');
        // let x = new Date();
        return moment(value).format("DD MMM y h:mm a");
        // return moment(value, 'DD MMM YYYY h:mm a').format();
        // return super.transform(value, 'd MMM y h:mm a');
    }
}

@Pipe({ name: 'safeHtml' })
export class SafePipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }
    transform(value: any, args?: any): any {
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
}


@Pipe({ name: 'striphtml' })
export class StripHtmlPipe implements PipeTransform {
    transform(value: string): any {
        return value.replace(/<.*?>/g, ''); // replace tags
    }
}


@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {
    transform(value: string, limit = 80, completeWords = false, ellipsis = '...') {
        if (completeWords) {
            limit = value.substr(0, limit).lastIndexOf(' ');
        }
        return value.length > limit ? value.substr(0, limit) + ellipsis : value;
    }
}

@Pipe({ name: 'replace' })
export class ReplacePipe implements PipeTransform {
    transform(value: string, find: string, replace: string = ' ') {
        return value.replace(RegExp(find, 'g'), replace);
    }
}

@NgModule({
    imports: [],
    declarations: [PriceFormatPipe, DateDisplayPipe, SafePipe, StripHtmlPipe, TruncatePipe, ReplacePipe],
    exports: [PriceFormatPipe, DateDisplayPipe, SafePipe, StripHtmlPipe, TruncatePipe, ReplacePipe]
})
export class CustomPipes { }
