import { Injectable, Injector } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
import { Address, SetAddressDefault } from 'projects/shared/models/address';
import { Banner } from 'projects/shared/models/banner';
import { Cart, DeliveryMethod, PaymentMethod, PaymentMode, OfflinePayments, CartPayments } from 'projects/shared/models/cart';
import { Menu, FooterMenu } from 'projects/shared/models/menu';
import { News } from 'projects/shared/models/news';
import { Order } from 'projects/shared/models/order';
import { Payment } from 'projects/shared/models/payment';
import { Product } from 'projects/shared/models/product';
import { CmsReview } from 'projects/shared/models/cmsreview';
import { User, Dashboard } from 'projects/shared/models/user';
import { Webpage } from 'projects/shared/models/webpage';
import { WishlistItem } from 'projects/shared/models/wishlist';
import { BaseApiProvider } from 'projects/shared/services/baseapi.provider';
import { ApiResult } from 'projects/shared/models/apiresult';
import { ProductReview } from 'projects/shared/models/productreview';
import { Category } from 'projects/shared/models/category';
import { OnlinePaymentTypes, OfflinePaymentTypes } from 'projects/shared/models/cmspayments';
import { ShippingFees } from 'projects/shared/models/cmsshippingfrees';
import { CreditLog } from 'projects/shared/models/credits';


@Injectable({ providedIn: 'root' })

export class ApiProvider extends BaseApiProvider {

    constructor(injector: Injector, private cache: SessionStorageService) {
        super(injector);
    }

    // webpages
    getWebpage(url: string): Promise<Webpage> {
        const cached = this.cache.get('webpage.' + url);
        if (cached) {
            return new Promise((success) => {
                success(cached);
            });
        }
        return this.getObject<Webpage>(new Webpage(), 'webpages', url)
            .then((result) => {
                this.cache.set('webpage.' + url, result);
                return result;
            });
    }

    // menus
    getMenu(name: string): Promise<Menu> {
        const cached = this.cache.get('menu.' + name);
        if (cached) {
            return new Promise((success) => {
                success(cached);
            });
        }
        return this.getObject<Menu>(new Menu(), 'menus', name)
            .then((result) => {
                this.cache.set('menu.' + name, result);
                return result;
            });
    }

    //categories
    getCategories(params = {}): Promise<Category[]> {
        const cached = this.cache.get('categories');
        if (cached) {
            return new Promise((success) => {
                success(cached);
            });
        }
        return this.getObjects<Category>(new Category(), 'categories', params).then((result) => {
            this.cache.set('categories', result);
            return result;
        });;
    }

    // banners
    getBanner(name: string): Promise<Banner> {
        const cached = this.cache.get('banner.' + name);
        if (cached) {
            return new Promise((success) => {
                success(cached);
            });
        }
        return this.getObject<Banner>(new Banner(), 'banners', name)
            .then((result) => {
                this.cache.set('banner.' + name, result);
                return result;
            }, () => { return null; });
    }

    //footer
    getFooter(): Promise<FooterMenu> {
        // const cached = this.cache.get('theFooter');
        // if (cached) {
        //     return new Promise((success) => {
        //         success(cached);
        //     });
        // }
        return new Promise((resolve, reject) => {
            this.http.getData('/menu/footer', null).subscribe((data) => {
                let obj = new FooterMenu().fromJson(data);
                this.cache.set('theFooter', obj);
                resolve(obj);
            }, (e) => {
                reject(e);
            });
        });
    }


    // products
    getProduct(id: string): Promise<Product> {
        // const cached = this.cache.get('product.' + id);
        // if (cached) {
        //     return new Promise((success) => {
        //         success(cached);
        //     });
        // }
        return this.getObject<Product>(new Product(), 'products', id)
            .then((result) => {
                this.cache.set('product.' + id, result);
                return result;
            });
    }

    getProducts(params = {}): Promise<Product[]> {
        return this.getObjects<Product>(new Product(), 'products', params);
    }

    //bundle
    getBundlebyParent(id: string): Promise<Product[]> {
        return this.getObjects<Product>(new Product(), 'products/bundlebyparent/' + id, null);
    }

    // cart
    getCart(id: string): Promise<Cart> {
        return this.getObject<Cart>(new Cart(), 'carts', id);
    }

    postCart(cart: Cart): Promise<Cart> {
        return this.postObject<Cart>(new Cart(), 'carts', cart.id, cart.toJson());
    }
    clearCart(cart: Cart): Promise<Cart> {
        return this.postObject<Cart>(new Cart(), 'carts/clear', cart.id, {});
    }

    postPromocode(cart: Cart): Promise<Cart> {
        return new Promise((resolve, reject) => {
            var param = "";
            if (cart.promocode.code != null && cart.promocode.code != "") {
                param = `/${cart.promocode.code}`;
            }
            this.http.postData(`/carts/promocode/${cart.id}${param}`, {}, this.apiHeader()).subscribe((data) => {
                resolve(new Cart().fromJson(data));
            }, (e) => {
                reject(e);
            });
        });
    }

    postCredit(credit_amount: number, cart_id?: string): Promise<Cart> {
        let jsonObj;
        if (cart_id != null) {
            jsonObj = { credit_amount: credit_amount, cart_id: cart_id };
        } else {
            jsonObj = { credit_amount: credit_amount };
        }
        return new Promise((resolve, reject) => {
            this.http.postData(`/carts/credit`, jsonObj, null).subscribe((data) => {
                resolve(new Cart().fromJson(data));
            }, (e) => {
                reject(e);
            });
        });
        //return this.postObject(new Cart(), `carts/credit`, cart.id, { credit_amount: cart.credit_amount }, true);
    }

    checkoutCart(cart: Cart): Promise<Order> {
        return this.postObject<Order>(new Order(), 'carts/checkout', cart.id, cart.toJson());
    }

    // checkout
    getPaymentMethods(): Promise<PaymentMethod[]> {
        const cached = this.cache.get('cart.paymentmethods');
        if (cached) {
            return new Promise((success) => {
                success(cached);
            });
        }
        return this.getObjects(new PaymentMethod(), 'carts/paymentmethods')
            .then((result) => {
                this.cache.set('cart.paymentmethods', result);
                return result;
            });
    }

    getPaymentMode(): Promise<PaymentMode[]> {
        //return this.getObjects<any>(new PaymentMode(), 'carts/paymentmode');
        return this.getObjects(new PaymentMode(), 'carts/paymentmode')
            .then((result) => {
                // this.cache.set('cart.paymentmode', result);
                return result;
            });
    }

    getOnlinePaymentsTypes(): Promise<OnlinePaymentTypes[]> {
        const cached = this.cache.get('online_payments');
        if (cached) {
            return new Promise((success) => {
                success(cached);
            });
        }
        return new Promise((resolve, reject) => {
            this.http.getData('/cms/online_payments', null).subscribe((data) => {
                let result = new OnlinePaymentTypes().listFromJson(data as []);
                this.cache.set('online_payments', result);
                resolve(result);
            }, (e) => {
                reject(e);
            });
        });
    }

    getOfflinePaymentsTypes(): Promise<OfflinePaymentTypes[]> {
        const cached = this.cache.get('offline_payments');
        if (cached) {
            return new Promise((success) => {
                success(cached);
            });
        }
        return new Promise((resolve, reject) => {
            this.http.getData('/cms/offline_payments', null).subscribe((data) => {
                let result = new OfflinePaymentTypes().listFromJson(data as []);
                this.cache.set('offline_payments', result);
                resolve(result);
            }, (e) => {
                reject(e);
            });
        });
    }

    getShippingFees(): Promise<ShippingFees[]> {
        const cached = this.cache.get('shipping_fee');
        if (cached) {
            return new Promise((success) => {
                success(cached);
            });
        }
        return new Promise((resolve, reject) => {
            this.http.getData('/cms/shipping_fee', null).subscribe((data) => {
                let result = new ShippingFees().listFromJson(data as []);
                this.cache.set('shipping_fee', result);
                resolve(result);
            }, (e) => {
                reject(e);
            });
        });
    }



    //test
    getCartPayments(): Promise<CartPayments[]> {
        // const cached = this.cache.get('cart.cartPayments');
        // if (cached) {
        //     return new Promise((success) => {
        //         success(cached);
        //     });
        // }
        return this.getObjects(new CartPayments(), 'carts/cartPayments')
            .then((result) => {
                // this.cache.set('cart.cartPayments', result);
                return result;
            });
    }
    //

    getDeliveryMethods(): Promise<DeliveryMethod[]> {
        const cached = this.cache.get('cart.deliverymethods');
        if (cached) {
            return new Promise((success) => {
                success(cached);
            });
        }
        return this.getObjects(new DeliveryMethod(), 'carts/deliverymethods')
            .then((result) => {
                this.cache.set('cart.deliverymethods', result);
                return result;
            });
    }

    // profile
    updateprofile(json: any): Promise<User> {
        return this.postObjects<User>(new User(), 'profile', json);
    }

    postAddress(address: Address): Promise<Address> {
        return this.postObjects<Address>(new Address(), 'profile/addresses', address.toJson());
    }

    postAddressesDefault(item: SetAddressDefault): Promise<any> {
        return this.postObjects<Address>(new Address(), 'profile/address/default', item);
    }

    postReview(form: FormData): Promise<ApiResult> {
        return new Promise((resolve, reject) => {
            this.http.postForm(`${this.routeprefix}` + '/order/review', form, this.apiHeader()).subscribe((data) => {
                resolve(new ApiResult().fromJson(data));
            }, (e) => {
                reject(e);
            });
        });
    }

    getReview(id: string): Promise<ProductReview[]> {
        return this.getObjects<ProductReview>(new ProductReview(), 'product/review', { id: id });
    }


    deleteAddress(id: string): Promise<any> {
        return this.deleteObject('profile/addresses', id);
    }

    getAddress(id: string): Promise<Address> {
        return this.getObject<Address>(new Address(), 'profile/addresses', id);
    }

    getAddresses(): Promise<Address[]> {
        return this.getObjects<Address>(new Address(), 'profile/addresses');
    }

    getCredit(json: any = {}): Promise<CreditLog[]> {
        return this.getObjects<CreditLog>(new CreditLog(), 'profile/credit', json);
    }

    // order
    getOrders(which: string): Promise<Order[]> {
        return this.getObjects<Order>(new Order(), 'profile/orders', which ? { type: which } : {});
    }

    getOrder(refno: string): Promise<Order> {
        return this.getObject<Order>(new Order(), 'profile/orders', refno);
    }

    // payment
    payOrder(order: Order): Promise<Order> {
        return this.postObject<Order>(new Order(), 'payment/order', order.id, order.toJson());
    }

    payTokenLink(id: string, form: FormData): Promise<Order> {
        return this.postForm<Order>(new Order(), 'payment/order', id, form);
    }

    getOrderWithToken(token: any): Promise<Order> {
        return this.getObject<Order>(new Order(), 'payment/token', token);
    }

    getOrderPaymentStatus(refno: string, id: string) {
        return this.getObject<Payment>(new Payment(), `payment/status/${refno}`, id);
    }

    // news
    getNews(id: string): Promise<News> {
        const cached = this.cache.get('news.' + id);
        if (cached) {
            return new Promise((success) => {
                success(cached);
            });
        }
        return this.getObject<News>(new News(), 'news', id)
            .then((result) => {
                this.cache.set('news.' + id, result);
                return result;
            });
    }

    getAllNews(params = {}): Promise<News[]> {
        return this.getObjects<News>(new News(), 'news', params);
    }

    // wishlist
    getWishlist(): Promise<WishlistItem[]> {
        return this.getObjects<WishlistItem>(new WishlistItem(), 'profile/wishlist');
    }

    postWishlistItem(item: WishlistItem): Promise<any> {
        return this.postObjects<WishlistItem>(new WishlistItem(), 'profile/wishlist', item);
    }

    deleteWishlistItem(id: string): Promise<any> {
        return this.deleteObject('profile/wishlist', id);
    }
}
