import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LocalStorageService } from 'angular-web-storage';

export function slugify(str: string) {
    return str
        // .replace('^\/', '')
        .toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');            // Trim - from end of text
}

@Injectable({ providedIn: 'root' })
export class BrowserTitle {

    public constructor(private titleService: Title, @Inject('appname') private appname: string) {
    }

    public setTitle(title: string = null) {
        if (!title) {
            this.titleService.setTitle(this.appname);
        } else {
            this.titleService.setTitle(this.appname + ' | ' + title);
        }
    }
}

@Injectable({ providedIn: 'root' })
export class SharedPrefs {
    public constructor(private storage: LocalStorageService) { }

    set(key: string, val: any) {
        // console.log('set', key, val);
        return this.storage.set(key, val);
    }

    get(key: string) {
        // console.log('get', key, this.storage.get(key));
        return this.storage.get(key);
    }


    remove(key: string) {
        // console.log('clear', key);
        return this.storage.remove(key);
    }

    clear() {
        // console.log('clear');
        return this.storage.clear();
    }
}


