import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading',
  template: `
  <div *ngIf="!loaded" class="uk-text-primary uk-text-center uk-inline uk-position-center">
    <div class="uk-position-center text-xs">Loading...</div>
    <div uk-spinner="ratio: 3"></div>
  </div>
  `
})
export class LoadingComponent {
  @Input() loaded = false;
  constructor() { }
}
