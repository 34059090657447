import { Injectable } from '@angular/core';
import { Cart, CartItem, CheckoutInfo, CartCharges, PickupInfo } from 'projects/shared/models/cart';
import { Order } from 'projects/shared/models/order';
import { Product } from 'projects/shared/models/product';
import { SharedPrefs } from 'projects/shared/services/utilities.provider';
import { ApiProvider } from './api.service';
import { PlatformLocation } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class CartProvider {

  content: Cart = new Cart();

  // priceSummary: string;

  constructor(private api: ApiProvider, private sharedprefs: SharedPrefs) {
    const data = this.sharedprefs.get('cart');
    if (data) {
      this.content = new Cart().fromJson(JSON.parse(data));
    }
  }

  clearCart() {
    this.api.clearCart(this.content);
    this.content = new Cart();
    this.sharedprefs.remove('cart');
  }

  setCart(data: Cart) {
    this.content = data;
    this.sharedprefs.set('cart', JSON.stringify(data));
  }

  saveChanges(): Promise<Cart> {
    this.sharedprefs.set('cart', JSON.stringify(this.content));
    return this.storeCart();
  }

  removeItem(item): Promise<Cart> {
    const idx = this.content.items.indexOf(item);
    this.content.items.splice(idx, 1);
    return this.saveChanges();
  }

  setShipping(v) {
    if (v == null) {
      let index = this.content.charges.findIndex((value) => value.type == "shipping");
      this.content.charges.splice(index, 1);
    } else {
      let ship = new CartCharges();
      let remark = v.remark ? " - " + v.remark : "";
      ship.title = v.title + remark;
      ship.amount = parseFloat(v.shipping_fee);
      ship.type = "shipping"
      ship.id = v.id;
      this.content.charges[0] = ship;;
    }
    return this.saveChanges();
  }

  addItem(quantity: number, product: Product, sku: any, color: string, size: string, flavour: string): Promise<Cart> {
    let newitem = new CartItem();
    newitem.product_id = sku.id;
    newitem.parent_id = product.id; //Parent ID
    newitem.sku = sku.sku;
    newitem.product_info = {
      title: product.title,
      url: product.url,
      image: product.images ? product.images[0] : '',
    };
    newitem.price = product.price;
    newitem.quantity = quantity;
    newitem.options = { color, size, flavour };
    if (product.isBundle) {
      //This one is only for bundle product, need a way to identify it is bundle
      newitem.promotion = sku.promotion[0];
    } else {
      if (sku.promotion && sku.promotion.length > 0) {
        sku.promotion.some((v) => {
          if (v.type != "bundle") {
            newitem.promotion = v;
            return true;
          }
        });
      }
    }
    this.content.items.push(newitem);
    return this.saveChanges();
  }

  // apply promo code
  setPromoCode(promocode: string): Promise<Cart> {
    this.content.promocode.code = promocode;
    return this.api.postPromocode(this.content).then((result) => {
      this.setCart(result);
      return result;
    }).catch((e) => {
      return Promise.reject(e);
    });
  }

  // replace browser's cart with server
  updateCart(): Promise<Cart> {
    return this.api.getCart(this.content.id || 'new').then((result) => {
      this.setCart(result);
      return result;
    });
  }

  // replace server's cart with browser
  storeCart(): Promise<Cart> {
    return this.api.postCart(this.content).then((result) => {
      this.setCart(result);
      return result;
    }).catch((e) => {
      this.content.items.pop(); //Got error remove last added item
      return Promise.reject(e);
    });
  }

  setCheckout(billing: any, delivery: any, same_as_delivery: boolean,
    delivery_method: string, payment_method: string, mode: string, pickup: boolean, pickup_info: PickupInfo): Promise<Cart> {
    this.content.checkout = new CheckoutInfo().fromJson({
      billing, delivery, same_as_delivery, delivery_method, payment_method, mode, pickup, pickup_info
    });

    return this.api.postCart(this.content).then((result) => {
      this.setCart(result);
      return result;
    });
  }

  createOrder(): Promise<Order> {
    return this.api.checkoutCart(this.content).then((result) => {
      // this.setCart(result);
      return result;
    });
  }

  update_price(sku = null, qty = null, product = null) {
    product.skus.forEach((v) => {
      if (sku != null && v.id == sku.id) {

        if (v.promotion) {
          //try
          let item = v.promotion.find((p) => p.type === 'mpq');
          if (item) {
            let lowest = item.mpq.reduce((r, e) => r.min_qty < e.min_qty ? r : e);
            product.price = v.price * qty;
            let selectedPromo = item.mpq.forEach((m) => {
              let min = m.min_qty;
              if (qty < lowest) {
                product.price = v.price * qty;
                return true;
              } else if (qty >= min && qty <= min) {
                product.price = this._calculateMpqPrice(m.discount, v.original_price, qty);
                return true;
              } else if (qty >= min) {
                product.price = this._calculateMpqPrice(m.discount, v.original_price, qty);
                return true;
              }
            });
          } else {
            product.price = v.price * qty;
          }
        } else product.price = v.price * qty;
      }
    });
  }

  async check_credit(use_credit: number, available_credit: number, isCart: boolean): Promise<string> {
    if (use_credit > 150) {
      return Promise.reject(`Insufficient credit`);
    }

    if (isCart) {
      try {
        let cart_id = this.content.id;
        let result = await this.api.postCredit(use_credit, cart_id);
        this.setCart(result);
      } catch (e) {
        return Promise.reject(e.error.error);
      }
    } else {
      try {
        await this.api.postCredit(use_credit);
      } catch (e) {
        return Promise.reject(e.error.error);
      }
    }

  }

  _calculateMpqPrice(discount, original_price, qty) {
    if (discount.includes("%")) {
      let dis = Number(discount.slice(0, -1));
      let thisdis = ((original_price * qty) * dis / 100);
      return (original_price * qty) - thisdis;
    } else {
      let dis = parseFloat(discount) * qty;
      return (original_price * qty) - dis;
    }
  }

}
