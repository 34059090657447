import { Injectable, TemplateRef } from '@angular/core';
declare var UIkit: any;

@Injectable({ providedIn: 'root' })
export class ToastService {
  // toasts: any[] = [];

  show(textOrTpl: string, options: any = null) {
    // this.toasts.push({ textOrTpl, ...options });
    const p = Object.assign({ pos: 'bottom-center', message: textOrTpl }, options);
    UIkit.notification(p);
  }

  remove(toast) {
    // this.toasts = this.toasts.filter(t => t !== toast);
  }
}
