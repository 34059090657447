import './genericmodel';

export class ProductReview implements GenericModel {

    id: string;
    updated_at: Date;
    created_at: Date;

    // public get description(): string {
    //     return `${this.result}`;
    // }

    fromJson(json: any): ProductReview {
        const ret = Object.assign(new ProductReview(), json);
        ret.id = json._id;
        return ret;
    }

    listFromJson(json: Array<any>): ProductReview[] {
        return json.map((i: any) => this.fromJson(i));
    }

    toJson() {
        return {
            id: this.id,
            // result: this.result,
        };
    }
}
