import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'projects/shared/guards/auth-guard.guard';
import { PaymentReturnComponent } from './payment-return/payment-return.component';
import { PaymentWithTokenComponent } from './payment-with-token/payment-with-token.component';
import { PaymentReceived } from '../../pages/payment-received/payment-received.component';

const routes: Routes = [
    { path: ':refno/return', component: PaymentReturnComponent },
    { path: '_t/:token', component: PaymentWithTokenComponent },
    { path: 'received/:refno', component: PaymentReceived },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
    ],
    exports: [RouterModule],
})
export class PaymentRoutingModule { }
