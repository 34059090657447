import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ApiProvider } from '../../services/api.service';
import { ShippingFees } from 'projects/shared/models/cmsshippingfrees';
import { CartProvider } from '../../services/cart.service';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit {

  @Input() parentForm: FormGroup;
  @Input() required = true;
  @Input() isSameDeliveryAddress;

  shippingFees: ShippingFees[];

  constructor(
    private api: ApiProvider,
    public cart: CartProvider,
  ) {

    this.api.getShippingFees().then((results) => {
      this.shippingFees = results;
    });
  }

  ngOnInit(): void {
  }

  shippingCountry(e) {
    if (this.parentForm.value.type == 'delivery') {
      this.shippingFees.filter((v) => {
        if (v.id == e) {
          this.cart.setShipping(v);
        }
      });
    }


  }
}
