import { Component, OnInit } from '@angular/core';
import { HttpProvider } from 'projects/shared/services/http.provider';
import { Product } from 'projects/shared/models/product';
import { ApiProvider } from '../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Category } from 'projects/shared/models/category';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit {
  products: Product[];
  page = 0;
  fragment: any;
  search: any;
  tags: any;
  category: Category;
  categories: Category[];
  title = 'All Products';

  filter = {
    size: '', color: ''
  };
  colors = ['RED', 'YELLOW', 'BLUE'];
  sizes = ['S', 'M', 'L', 'XL'];
  list_of_sort = ['Price Low to High', 'Price High to Low'];
  selected_sort = '';
  sort_param = {
    sort: '', //sort field
    dir: '', //asc or desc
  }

  constructor(
    private api: ApiProvider,
    private route: ActivatedRoute,
    private router: Router
  ) {
    // change the behavior for wildcard route
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    // look for hash
    this.products = null;

    this.fragment = this.route.snapshot.fragment || '';
    this.search = this.route.snapshot.queryParamMap.get('search') || '';
    this.tags = this.route.snapshot.queryParamMap.get('tags') || '';

    this.api.getCategories().then((results) => {
      this.myCategory(this.fragment, results);
      if (this.category) { this.title = this.category.label; }
    });

    this.refresh();
  }

  refresh() {
    this.page = 0;
    this.products = null;
    this.api.getProducts({ category: this.fragment || '', search: this.search, tags: this.tags, size: this.filter.size, color: this.filter.color, sort: this.sort_param.sort, dir: this.sort_param.dir, page: 0 }).then((results) => {
      this.products = results;
    });
  }

  ngOnInit() {
    this.api.getCategories().then((result) => {
      this.categories = result;
    });
  }

  onScroll() {
    const p = this.page += 1;
    this.api.getProducts({ page: p, category: this.fragment || '', search: this.search, tags: this.tags, size: this.filter.size, color: this.filter.color }).then((results) => {
      this.products = this.products.concat(results);
      return this.products;
    });
  }

  myCategory(url: string, category: Category[]) {
    for (let i = 0; i < category.length; i++) {
      const r = category[i];
      if (r.url === url) { this.category = r; break; } else { this.myCategory(url, r.children); }
    }
  }

  filterreset() {
    this.filter.color = '';
    this.filter.size = '';
    this.refresh();
  }

  filtercolor(c) {
    this.filter.color = c;
    this.refresh();
  }

  filtersize(s) {
    this.filter.size = s;
    this.refresh();
  }

  sortSelected(s) {
    this.selected_sort = s;
    switch (s) {
      case 'Price Low to High':
        this.sort_param.sort = "price";
        this.sort_param.dir = "asc";
        break;

      case 'Price High to Low':
        this.sort_param.sort = "price";
        this.sort_param.dir = "desc";
        break;
    }
    this.refresh();
  }

}
