import './genericmodel';
import { Address } from './address';
import { CartItem } from './cart';

export class AuthToken {
  token: string;

  fromJson(json: any): AuthToken {
    const ret = Object.assign(new AuthToken(), json);
    // ret.id = json._id;
    return ret;
  }

  listFromJson(json: Array<any>): AuthToken[] {
    return json.map((i: any) => this.fromJson(i));
  }
}

export class User implements GenericModel {
  id: string;
  name: string;
  email: string;
  phone: string;
  avatar: string;
  updated_at: Date;
  created_at: Date;
  accessed_at: Date;
  roles: string[] = [];
  dashboard: Dashboard = new Dashboard();

  fromJson(json: any): User {
    const ret = Object.assign(new User(), json);
    ret.id = json._id;
    ret.updated_at = new Date(json.updated_at);
    ret.created_at = new Date(json.created_at);
    ret.accessed_at = new Date(json.accessed_at);
    return ret;
  }


  listFromJson(json: Array<any>): User[] {
    return json.map((i: any) => this.fromJson(i));
  }

  toJson() {
    return {
      id: this.id,
      name: this.name,
      email: this.email,
      phone: this.phone,
      avatar: this.avatar,
      roles: this.roles,
    };
  }
}

export class Dashboard implements GenericModel {
  id: string;
  updated_at: Date;
  created_at: Date;
  new_orders = 0;
  pending_shipment = 0;
  pending_review = 0;
  member_points = 0;
  credits = 0;

  fromJson(json: any): Dashboard {
    const ret = Object.assign(new Dashboard(), json);
    ret.id = 1;
    ret.updated_at = new Date(json.updated_at);
    ret.created_at = new Date(json.created_at);
    return ret;
  }

  listFromJson(json: Dashboard[]): Dashboard[] {
    return json.map((i: Dashboard) => this.fromJson(i));
  }
}
