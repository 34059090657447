<div class="uk-container uk-margin-small-top">
  <div id="page-title" class="uk-text-center">
    <h1>Checkout</h1>
  </div>
  <!-- <div class="uk-child-width-expand uk-text-center uk-margin-remove uk-subnav uk-subnav-pill" uk-switcher="connect: #component-nav; animation: uk-animation-fade">
    <div class="uk-background-muted uk-padding-remove"><a>
        <h6 class="uk-margin-remove uk-padding-small uk-text-uppercase">Addresses</h6>
      </a></div>
    <div id="tab-payment" class="uk-background-muted uk-padding-remove">
      <a>
        <h6 class="uk-margin-remove uk-padding-small uk-text-uppercase">Payment</h6>
      </a></div>
  </div> -->
  <div uk-grid>
    <div class="uk-width-large@m uk-margin-medium-top">
      <div uk-sticky="offset: 60;media: @m">
        <app-cart-checkout-menu></app-cart-checkout-menu>
      </div>
    </div>
    <div class="uk-flex-first@m uk-width-expand@m">
      <div style="background-color: #fff;padding: 1em;">
        <h3 class="uk-heading-line"><span>1. Select delivery method</span></h3>
        <div id="component-nav" class="uk-margin-top">
          <div id="addresses">
            <form [formGroup]="checkoutForm">
              <fieldset class="uk-fieldset">

                <div class="form-check uk-width-large"
                  style="border:1px dotted #dfdfdf;padding:1em; margin-bottom: 1em;border-radius: 5px;background-color: #fff;">
                  <label class="uk-form-label uk-flex uk-flex-between uk-flex-middle uk-grid" for="pickup">
                    <div>
                      <input type="checkbox" class="uk-checkbox" id="pickup" (change)="pickupCheck()"
                        formControlName="pickup">
                    </div>
                    <div class="uk-width-expand">
                      <h5 class="uk-margin-remove">Select Self Pick up</h5>
                      <div class="uk-text-muted">Self Pick Up only available at weekday.</div>
                    </div>
                  </label>
                </div>
                <div class="uk-form-horizontal" [hidden]="!checkoutForm.value.pickup">
                  <div class="uk-margin">
                    <label class="uk-form-label uk-text-normal" for="pickup_name">Name <span
                        class="uk-text-danger">*</span></label>
                    <div class="uk-form-controls">
                      <input name="pickup_name" formControlName="pickup_name" class="uk-input">
                    </div>
                  </div>

                  <div class="uk-margin">
                    <label class="uk-form-label uk-text-normal" for="pickup_phone">Phone No <span
                        class="uk-text-danger">*</span></label>
                    <div class="uk-form-controls">
                      <input name="pickup_phone" formControlName="pickup_phone" class="uk-input"
                        placeholder="0123456789">
                      <div *ngIf="checkoutForm.controls.pickup_phone.invalid;" class="uk-text-danger">Invalid
                        Format, Numbers only</div>
                    </div>
                  </div>
                  <div class="uk-margin">
                    <label class="uk-form-label uk-text-normal">Message</label>
                    <div class="uk-form-controls">
                      <textarea class="uk-textarea uk-margin-large-bottom" name="pickup_message"
                        formControlName="pickup_message" placeholder="Leave your self pick up message here"></textarea>
                    </div>
                  </div>
                </div>

                <div [hidden]="checkoutForm.value.pickup">
                  <h5 class="uk-margin-top uk-margin-bottom uk-heading-bullet uk-text-normal">Delivery Address
                  </h5>
                  <app-address-form [parentForm]="checkoutForm.get('delivery')"></app-address-form>
                </div>
              </fieldset>

              <fieldset [hidden]="checkoutForm.value.pickup" class="uk-fieldset uk-margin-top uk-margin-large-bottom">
                <div class="form-check">
                  <label class="uk-form-label" for="sameAsDelivery">
                    <input type="checkbox" class="uk-checkbox" id="sameAsDelivery" (change)="sameAsDeliveryCheck()"
                      formControlName="same_as_delivery">
                    Same as Delivery Address</label>
                </div>

                <div [hidden]="checkoutForm.value.same_as_delivery" class="uk-margin-medium-top">
                  <h5 class="uk-margin-bottom uk-heading-bullet">Billing Address</h5>
                  <app-address-form [parentForm]="checkoutForm.get('billing')"
                    [isSameDeliveryAddress]="checkoutForm.value.same_as_delivery"
                    [required]="!checkoutForm.value.same_as_delivery"></app-address-form>
                </div>
              </fieldset>
              <!-- <div class="uk-text-center">
              <button id="next-btn" class="uk-button uk-button-secondary" [disabled]="!checkoutForm.valid" data-uk-switcher-item="next" (click)="addresses_next()">Next</button>
            </div> -->
            </form>
          </div>
          <div id="payment">
            <h3 class="uk-heading-line"><span>2. Payment Method</span></h3>
            <p *ngIf="offlinePaymentTypes">Please select payment method:</p>
            <!-- <ul class="uk-subnav uk-subnav-pill uk-text-center uk-child-width-expand" uk-switcher="connect: #payment-type; animation: uk-animation-fade">
              <li *ngIf="onlinePaymentTypes"><a href="#">Online Payments</a></li>
              <li *ngIf="offlinePaymentTypes"><a href="#">Offline Payments</a></li>
            </ul> -->
            <h5 class="uk-margin-small-top uk-margin-bottom uk-heading-bullet uk-text-normal">Online Payment</h5>
            <ul id="payment-type" class="uk-list uk-margin">
              <li *ngIf="onlinePaymentTypes">
                <div *ngFor="let item of onlinePaymentTypes; let i = index" (click)="select_payment(i,'online')">
                  <div>
                    <!-- <div *ngIf="item.active" class="uk-card-small" [ngClass]="paymentSelectedOnlineIndex==i ? 'uk-card-primary' : ''"> -->
                    <!-- <div class="uk-flex uk-flex-middle">
                      <div>
                        <div class="uk-margin-remove">
                          <img [src]="'/assets/bank/'+(item.title|lowercase)+'.png'">
                        </div>
                      </div> -->
                    <div>
                      <!-- <b>{{item.title}}</b><br />
                        <b *ngIf="item.number != ''">{{item.number}}</b> -->
                      <div class="uk-margin">
                        <p class="uk-margin-remove">Payment via FPX is available once you checkout from this website.
                          Kindly follow the instruction to make payment.</p>
                        <!-- <p [innerHtml]="item.remark"></p> -->
                        <div class="uk-margin uk-text-center"
                          [ngClass]="paymentSelectedOnlineIndex==i ? 'uk-card-secondary' : ''">
                          <img [src]="'/assets/bank/'+(item.title|lowercase)+'.png'">
                        </div>
                      </div>
                    </div>
                    <!-- </div> -->
                  </div>
                </div>
              </li>
              <li *ngIf="offlinePaymentTypes">
                <h5 class="uk-margin-small-top uk-margin-bottom uk-heading-bullet uk-text-normal">Offline Payment</h5>
                <div class="uk-grid-match uk-grid-small uk-flex-center uk-child-width-1-2 uk-child-width-1-4@s uk-grid">
                  <div *ngFor="let item of offlinePaymentTypes; let i = index" (click)="select_payment(i,'offline')">
                    <div *ngIf="item.active" class="uk-card-small"
                      [ngClass]="paymentSelectedOfflineIndex==i ? 'uk-card-secondary' : 'uk-box-shadow-small'">
                      <div class="uk-card-body">
                        <div class="radioimage">
                          <!-- <img [src]="'/assets/'+(item.id|lowercase)+'.png'"> -->
                          <img [src]="'/assets/bank/'+(item.title|lowercase)+'.png'">
                        </div>
                        <b>{{item.title}}</b><br />
                        <b>{{item.number}}</b>
                        <div class="uk-text-muted">
                          <div [innerHtml]="item.remark"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div class="uk-text-center" *ngIf="!offlinePaymentTypes && !onlinePaymentTypes">
              <h3 class="uk-text-danger">Oops, something went wrong. Please contact Admin.</h3>
            </div>
            <div class="uk-margin-small uk-grid-match uk-grid-small" uk-grid>
              <div *ngIf="available_credit > 0" class="uk-width-1-2@s uk-margin-bottom">
                <div class="uk-padding-small" style="background: rgb(253,245,236);
                background: linear-gradient(180deg, rgba(253,245,236,1) 0%, rgba(255,255,255,1) 80%);">
                  <h5 class="uk-margin-small-bottom uk-text-normal"><span class="uk-badge iconify"
                      data-icon="ic:sharp-attach-money" data-inline="false"></span> Your Credit</h5>
                  <p class="uk-margin-small-left uk-margin-small-bottom">Available Credit - <span
                      class="uk-text-success">{{available_credit|price_format}}</span></p>
                  <div uk-flex>
                    <input ngModel class="uk-input uk-text-center uk-form-width-expand" aria-label="Credit"
                      aria-describedby="button-credit" placeholder="Enter Use Amount" type="number"
                      [(ngModel)]="use_credit">
                    <button class="uk-button uk-button-default uk-border-pill uk-margin-small-top uk-width-1-1"
                      type="button" id="button-credit" (click)="apply_credit()">Apply</button>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@s uk-margin-bottom">
                <div class="uk-padding-small" style="background: rgb(253,245,236);
                background: linear-gradient(180deg, rgba(253,245,236,1) 0%, rgba(255,255,255,1) 80%);">
                  <h5 class="uk-margin-remove-bottom uk-text-normal"><span class="uk-badge iconify"
                      data-icon="ri:coupon-2-line" data-inline="false"></span> Promo Code</h5>
                  <p class="uk-margin-small-left uk-margin-small-bottom">Only one promo code can be applied per
                    transaction</p>
                  <div uk-flex>
                    <input ngModel class="uk-input uk-text-center uk-form-width-expand" aria-label="Promo Code"
                      aria-describedby="button-promocode" type="text" placeholder="Enter promo code here"
                      [(ngModel)]="promocode">
                    <button id="button-promocode"
                      class="uk-button uk-button-default uk-margin-small-top uk-border-pill uk-width-1-1"
                      (click)="apply_promocode()">Apply</button>
                  </div>
                </div>
              </div>
            </div>
            <!-- <button (click)="active='delivery'" class="btn btn-light mr-2">Back</button> -->
            <!-- <div *ngIf="needsPayment()" class="uk-alert-success" uk-alert>
              <a class="uk-alert-close" uk-close></a>
              <p>All set! Let's go to Payment.</p>
            </div> -->
            <div class="uk-child-width-expand" uk-grid>
              <!-- <div>
                <button class="uk-button uk-button-large uk-button-muted" (click)="active='addresses'" data-uk-switcher-item="previous">Back</button>
              </div> -->
              <div class="uk-text-center">
                <button (click)="payment_next()" class="uk-button uk-border-pill uk-button-large"
                  [ngClass]=" (!checkoutForm.valid || !paymentSelected  || submitDisabled) ? '' : 'uk-button-success' "
                  [disabled]="!checkoutForm.valid || !paymentSelected  || submitDisabled">{{needsPayment() ? 'Make
                  Payment' : 'Place Order'}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>