import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BrowserTitle } from 'projects/shared/services/utilities.provider';
import { ApiProvider } from '../../services/api.service';
import { CartProvider } from '../../services/cart.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private api: ApiProvider,
    public cart: CartProvider,
    private cartProvider: CartProvider,
    private title: BrowserTitle,
  ) {
    this.title.setTitle('Shopping Cart');
    this.cart.updateCart();
  }

  ngOnInit() {
  }

  clear() {
    this.cartProvider.clearCart();
  }
  onQuantity() {

  }

  onDeleteRow() {

  }
}
