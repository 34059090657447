import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'projects/shared/models/product';
import { ToastService } from 'projects/shared/services/toast.provider';
import { CartProvider } from '../../../services/cart.service';
import { WishlistProvider } from '../../../services/wishlist.service';

@Component({
  selector: 'app-shop-product',
  templateUrl: './shop-product.component.html',
  styleUrls: ['./shop-product.component.scss']
})
export class ShopProductComponent implements OnInit {

  @Input() product: Product;

  constructor(
    private cartProvider: CartProvider,
    private wishlist: WishlistProvider,
    private toast: ToastService,
  ) { }

  ngOnInit() {
  }

  add_to_cart(event) {
    this.cartProvider.addItem(1, this.product, this.product.skus, null, null, null).then((result) => {
      this.toast.show(`${this.product.title} added to cart!`, { classname: 'uk-dark uk-text-success' });
    });
    event.stopPropagation();
  }

  add_to_wishlist(event) {
    this.wishlist.addWishlistItem(this.product).then((ret) => {
      if (ret.message) {
        this.toast.show(ret.message, { classname: ret.success ? 'uk-dark uk-text-success' : 'uk-light uk-text-danger' });
      }
    });
    event.stopPropagation();
  }

  remove_from_wishlist(event) {
    this.wishlist.removeWishlistItem(this.product).then((ret) => {
      if (ret.message) {
        this.toast.show(ret.message, { classname: ret.success ? 'uk-dark uk-text-success' : 'uk-light uk-text-danger' });
      }
    });
    event.stopPropagation();
  }

}
