import './genericmodel';

export class Webpage implements GenericModel {
    id: string;
    url: string;
    title: string;
    content: string;
    tags: string[] = [];
    image: string;
    updated_at: Date;
    created_at: Date;

    fromJson(json: any): Webpage {
        const ret = Object.assign(new Webpage(), json);
        ret.id = json._id;
        ret.updated_at = new Date(json.updated_at);
        ret.created_at = new Date(json.created_at);
        return ret;
    }


    listFromJson(json: Array<any>): Webpage[] {
        return json.map((i: any) => this.fromJson(i));
    }

    toJson() {
        return {
            id: this.id,
            url: this.url,
            title: this.title,
            content: this.content,
            newimage: this.image,
            tags: this.tags,
        };
    }

}

