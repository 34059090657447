import { Component, OnInit, Input, ViewChild, Inject } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
import { Menu, FooterMenu } from 'projects/shared/models/menu';
import { Router } from '@angular/router';
import { AccountProvider } from 'projects/shared/services/account.provider';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiProvider } from '../../services/api.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  copyright: string;
  infommenu = new Menu();
  servicemmenu = new Menu();
  menu = new Menu();
  footer = new FooterMenu();
  showpassword = false;
  isMenuCollapsed = true;
  @Input() name: string;
  @Input() links: string;


  constructor(private api: ApiProvider,
              public router: Router,
              public account: AccountProvider,
              public modal: NgbModal,
              private cache: SessionStorageService,
              @Inject('appname') public appname: string) {

    // move to api?
    this.copyright = `Copyright &copy; ${new Date().getFullYear()} ${appname}`;

  }

  ngOnInit() {

    this.api.getFooter().then((result) => {
      this.footer = result;
    });

    this.api.getMenu(this.name || 'FOOTER').then((result) => {
      this.menu = result;
    }, () => { });

    this.api.getMenu(this.links || 'INFORMATION').then((result) => {
      this.infommenu = result;
    }, () => { });
    this.api.getMenu(this.links || 'CUSTOMER_CARE').then((result) => {
      this.servicemmenu = result;
    }, () => { });
  }

  isActive(url: string) {
    if (url === '/') { return (this.router.url === url); }
    return this.router.url.startsWith(url);
  }

  navClick(url: string, type: string) {
    if (url && url !== '') {
      if (type === 'tag') {
        this.router.navigateByUrl('/shop?tags=' + url);
      } else {
        this.router.navigateByUrl('/shop#' + url);
      }
    }
  }
}
