export class WishlistItem implements GenericModel {
    id: string;
    product_id: string;
    title: string;
    url: string;
    thumbnail: string;
    updated_at: Date;
    created_at: Date;

    fromJson(json: any): WishlistItem {
        const ret = Object.assign(new WishlistItem(), json);
        ret.id = json._id;
        ret.updated_at = new Date(json.updated_at);
        ret.created_at = new Date(json.created_at);
        return ret;
    }

    listFromJson(json: Array<any>): WishlistItem[] {
        return json.map((i: any) => this.fromJson(i));
    }

    toJson() {
        return {
            // id: this.id,
            title: this.title,
            url: this.url,
            thumbnail: this.thumbnail,
        };
    }
}
