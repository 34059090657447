<h1>News</h1>

<app-loading [loaded]="news"></app-loading>

<div *ngIf="news">
  <p *ngIf="news && news.length==0">No News</p>

  <div class="card-columns">

    <div *ngFor="let n of news | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize" class="card border-0 mb-3">
      <a [routerLink]="['/news', n.id, n.url]">
        <div *ngIf="n.api_thumbnail" class="bg-image-cover rounded shadow mb-3" [ngStyle]="{'background-image':'url('+n.api_thumbnail+')'}" style="padding-bottom:50%;"></div>
      </a>
      <div class="uk-padding">
        <h4 class="text-secondary mb-3" [routerLink]="['/news', n.id, n.url]">{{n.title}}</h4>
        <p>
          {{n.content | striphtml | truncate : 200 }}
        </p>
      </div>
    </div>
  </div>

  <ngb-pagination *ngIf="news.length > pageSize" class="d-flex justify-content-center" [(page)]="page" [pageSize]="pageSize" [collectionSize]="news.length"></ngb-pagination>
</div>