import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Address } from 'projects/shared/models/address';
import { AccountProvider } from 'projects/shared/services/account.provider';
import { ToastService } from 'projects/shared/services/toast.provider';
import { BrowserTitle } from 'projects/shared/services/utilities.provider';
import { ApiProvider } from 'projects/web/src/app/services/api.service';

@Component({
  selector: 'app-edit-address',
  templateUrl: './edit-address.component.html',
  styleUrls: ['./edit-address.component.scss']
})
export class AddressbookEditComponent implements OnInit {

  id: string;
  address: Address;
  validation: any = {};
  addressForm: FormGroup = this.fb.group({
    id: [''],
    name: ['', Validators.required],
    company: [''],
    phone: ['', [Validators.required, Validators.pattern('\\d{8,12}')]],
    address_1: ['', Validators.required],
    address_2: [''],
    address_3: [''],
    city: ['', Validators.required],
    postcode: ['', Validators.required],
    state: ['', Validators.required],
    country: ['', Validators.required]
  });
  accountProvider: AccountProvider;

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private account: AccountProvider,
    private api: ApiProvider,
    private toast: ToastService,
    private title: BrowserTitle,
  ) {
    this.accountProvider = account;
  }

  ngOnInit(): void {

    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id === 'new') {
      this.title.setTitle('Add Address');
      this.address = new Address();
      this.addressForm.patchValue({
        phone: this.accountProvider.currentUser.phone,
        name: this.accountProvider.currentUser.name,
      });
    } else {
      this.title.setTitle('Edit Address');
      this.api.getAddress(this.id).then((result) => {
        this.address = result;
        this.addressForm.patchValue(this.address);
      });
    }
  }

  save() {
    let ret = Object.assign(new Address(), this.addressForm.value);
    this.api.postAddress(ret).then((result) => {
      this.addressForm.controls['id'].setValue(result.id);

      this.toast.show('Address Updated', { classname: 'uk-text-light uk-background-success' });
    }, (response) => {
      if (response.error.validation) {
        this.validation = response.error.validation;
      } else if (response.error.error) {
        this.toast.show(response.error.error, { classname: 'uk-text-light uk-background-warning' });
      } else {
        this.toast.show(response.message, { classname: 'uk-text-light uk-background-warning' });
      }
    });
  }

}
