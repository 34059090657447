<app-loading [loaded]="order"></app-loading>

<form [formGroup]="paymentForm" (submit)="pay()" autocomplete="off">
    <section *ngIf="order" class="uk-container uk-section">
        <h1 class="uk-text-center">Checkout</h1>
        <div style="border:2px solid #f8f8f8; background-color: #fff;">
            <div id="page-title" class="uk-padding-small" style="background-color: #dedede;">
                <h5 class="uk-clearfix uk-margin-remove" style="color: #333;">
                    <span class="uk-float-left">Order #{{order.refno}}</span>
                    <span class="uk-float-right" [innerHtml]="sBeautify(order.status)"></span>
                </h5>
                <p *ngIf="order.paid_at">This order has been paid on {{order.paid_at | date_display}}.</p>
            </div>
            <div class="uk-padding-small">

                <div *ngIf="order.status == 'new'" class="form-check uk-width-large" style="border:1px dotted #dfdfdf;padding:1em; margin-bottom: 1em;border-radius: 5px;background-color: #fff;">
                    <label class="uk-form-label uk-flex uk-flex-between uk-flex-middle uk-grid" for="pickup">
            <div>
              <input type="checkbox" class="uk-checkbox" id="pickup" (change)="pickupCheck()" formControlName="pickup">
            </div>
            <div class="uk-width-expand">
              <h5 class="uk-margin-remove">Select Self Pick up</h5>
              <div class="uk-text-muted">Self Pick Up only available at weekday.</div>
            </div>
          </label>
                </div>


                <div formGroupName="pickup_info" [hidden]="!paymentForm.value.pickup">
                    <div class="uk-card uk-card-small">
                        <div class="uk-card-header uk-padding-remove" style="font-size: 16pt;">
                            <h5 class="uk-margin-remove">
                                <!-- <span style="margin-right:10px" class="iconify uk-margin-right-small" data-icon="mdi-van-utility"></span> -->
                                PICK UP INFO
                            </h5>
                        </div>
                        <div class="uk-card-body uk-form-horizontal">
                            <div class="uk-margin">
                                <label class="uk-form-label" for="form-horizontal-text">Name <span
                    class="uk-text-danger">*</span></label>
                                <div class="uk-form-controls">
                                    <input formControlName="pickup_name" class="uk-input" [required]="required" placeholder="Name" [readonly]="isReadOnly">
                                </div>
                            </div>
                            <div class="uk-margin">
                                <label class="uk-form-label" for="form-horizontal-text">Phone <span
                    class="uk-text-danger">*</span></label>
                                <div class="uk-form-controls">
                                    <input formControlName="pickup_phone" class="uk-input" [required]="required" placeholder="Phone" type="tel" [readonly]="isReadOnly">
                                    <div *ngIf="paymentForm.get('pickup_info.pickup_phone').invalid" class="uk-text-danger">Invalid Format, Numbers only
                                    </div>
                                </div>
                            </div>
                            <div class="uk-margin">
                                <label class="uk-form-label" for="form-horizontal-text">Email <span
                    class="uk-text-danger">*</span></label>
                                <div class="uk-form-controls">
                                    <input formControlName="pickup_email" class="uk-input" [required]="required" placeholder="Email" [readonly]="isReadOnly">
                                </div>
                            </div>


                            <div class="uk-margin">
                                <label class="uk-form-label" for="form-horizontal-text">Message <span
                    class="uk-text-danger">*</span></label>
                                <div class="uk-form-controls">
                                    <textarea class="uk-textarea uk-margin-large-bottom" formControlName="pickup_message" placeholder="Leave your self pick up message here" [readonly]="isReadOnly"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div formGroupName="shipping" [hidden]="paymentForm.value.pickup">
                    <div class="uk-card uk-card-small">
                        <div class="uk-card-header uk-padding-remove" style="font-size: 16pt;">
                            <h5 class="uk-margin-remove">
                                <!-- <span style="margin-right:10px" class="iconify uk-margin-right-small" data-icon="mdi-van-utility"></span> -->
                                DELIVERY ADDRESS
                            </h5>
                        </div>
                        <div class="uk-card-body uk-form-horizontal">
                            <div class="uk-margin">
                                <label class="uk-form-label" for="form-horizontal-text">Name <span
                    class="uk-text-danger">*</span></label>
                                <div class="uk-form-controls">
                                    <input formControlName="name" class="uk-input" [required]="required" placeholder="Name" [readonly]="isReadOnly">
                                </div>
                            </div>

                            <div class="uk-margin">
                                <label class="uk-form-label" for="form-horizontal-text">Email <span
                    class="uk-text-danger">*</span></label>
                                <div class="uk-form-controls">
                                    <input formControlName="email" class="uk-input" [required]="required" placeholder="Email" [readonly]="isReadOnly">
                                </div>
                            </div>

                            <div class="uk-margin">
                                <label class="uk-form-label" for="form-horizontal-text">Phone <span
                    class="uk-text-danger">*</span></label>
                                <div class="uk-form-controls">
                                    <input formControlName="phone" class="uk-input" [required]="required" placeholder="Phone" type="tel" [readonly]="isReadOnly">
                                    <div *ngIf="paymentForm.get('shipping.phone').invalid" class="uk-text-danger">Invalid Format, Numbers only
                                    </div>
                                </div>
                            </div>

                            <div class="uk-margin">
                                <label class="uk-form-label" for="form-horizontal-text">Company</label>
                                <div class="uk-form-controls">
                                    <input formControlName="company" class="uk-input" placeholder="Company" [readonly]="isReadOnly">
                                </div>
                            </div>

                            <div class="uk-margin">
                                <label class="uk-form-label" for="form-horizontal-text">Address <span
                    class="uk-text-danger">*</span></label>
                                <div class="uk-form-controls">
                                    <input formControlName="address_1" class="uk-input" [required]="required" placeholder="Address" [readonly]="isReadOnly">
                                </div>
                            </div>

                            <div class="uk-margin">
                                <label class="uk-form-label" for="form-horizontal-text">City <span
                    class="uk-text-danger">*</span></label>
                                <div class="uk-form-controls">
                                    <input formControlName="city" class="uk-input" [required]="required" placeholder="City" [readonly]="isReadOnly">
                                </div>
                            </div>

                            <div class="uk-margin">
                                <label class="uk-form-label" for="form-horizontal-text">Postcode <span
                    class="uk-text-danger">*</span></label>
                                <div class="uk-form-controls">
                                    <input formControlName="postcode" class="uk-input" [required]="required" placeholder="postcode" [readonly]="isReadOnly">
                                </div>
                            </div>

                            <div class="uk-margin">
                                <!-- <span class="uk-form-icon" uk-icon="icon: location"></span> -->
                                <!-- <input formControlName="state" class="uk-input" [required]="required" placeholder="state"> -->
                                <label class="uk-form-label" for="form-horizontal-text">State <span
                    class="uk-text-danger">*</span></label>
                                <div class="uk-form-controls" *ngIf="!isReadOnly">
                                    <select formControlName="state" class="uk-select" [required]="required">
                    <option value="">Please Select</option>
                    <option *ngFor="let s of states" [value]="s">{{ s }}</option>
                  </select>
                                </div>
                                <div class="uk-form-controls" *ngIf="isReadOnly">
                                    <input formControlName="state" class="uk-input" [readonly]="isReadOnly">
                                </div>
                            </div>

                            <div class="uk-margin">
                                <!-- <span class="uk-form-icon" uk-icon="icon: location"></span> -->
                                <!-- <input formControlName="country" class="uk-input" [required]="required" placeholder="country"> -->
                                <label class="uk-form-label" for="form-horizontal-text">Country <span
                    class="uk-text-danger">*</span></label>
                                <div class="uk-form-controls" *ngIf="!isReadOnly">
                                    <select formControlName="country" class="uk-select" [required]=required (change)="shippingCountry($event.target.value)">
                    <option value="">Please Select</option>
                    <option *ngFor="let fee of shippingFees" [value]="fee.id">{{ fee.id }}
                    </option>
                  </select>
                                </div>
                                <div class="uk-form-controls" *ngIf="isReadOnly">
                                    <input formControlName="country" class="uk-input" [readonly]="isReadOnly">
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <!-- <div class="uk-width-expand">
          <div formGroupName="billing">
            <div class="uk-card uk-card-small">
              <div class="uk-card-header uk-padding-remove">
                <div class="uk-flex uk-flex-between">
                  <h5 class="uk-margin-remove">
                    BILLING ADDRESS
                  </h5>
                  <span class="uk-form-small uk-text-small"><input type="checkbox" class="uk-checkbox" (change)="copyShipping()" formControlName="same_as_delivery"> Copy from Delivery Address</span>
                </div>
              </div>
              <div class="uk-card-body uk-form-horizontal">

                <div class="uk-margin">
                  <label class="uk-form-label" for="form-horizontal-text">Name</label>
                  <div class="uk-form-controls">
                    <input name="billing.name" formControlName="name" class="uk-input" [required]="required" placeholder="Name">
                  </div>
                </div>

                <div class="uk-margin">
                  <label class="uk-form-label" for="form-horizontal-text">Email</label>
                  <div class="uk-form-controls">
                    <input name="billing.email" formControlName="email" class="uk-input" [required]="required" placeholder="Email">
                  </div>
                </div>

                <div class="uk-margin">
                  <label class="uk-form-label" for="form-horizontal-text">Phone</label>
                  <div class="uk-form-controls">
                    <input formControlName="phone" class="uk-input" [required]="required" placeholder="Phone" type="tel">
                  </div>
                </div>
                <div *ngIf="paymentForm.get('billing.phone').invalid" class="uk-text-danger">Invalid Format, Numbers only
                </div>

                <div class="uk-margin">
                  <label class="uk-form-label" for="form-horizontal-text">Company</label>
                  <div class="uk-form-controls">
                    <input formControlName="company" class="uk-input" placeholder="Company">
                  </div>
                </div>

                <div class="uk-margin">
                  <label class="uk-form-label" for="form-horizontal-text">Address</label>
                  <div class="uk-form-controls">
                    <input formControlName="address_1" class="uk-input" [required]="required" placeholder="Address">
                  </div>
                </div>

                <div class="uk-margin">
                  <label class="uk-form-label" for="form-horizontal-text">City</label>
                  <div class="uk-form-controls">
                    <input formControlName="city" class="uk-input" [required]="required" placeholder="City">
                  </div>
                </div>

                <div class="uk-margin">
                  <label class="uk-form-label" for="form-horizontal-text">Postcode</label>
                  <div class="uk-form-controls">
                    <input formControlName="postcode" class="uk-input" [required]="required" placeholder="postcode">
                  </div>
                </div>

                <div class="uk-margin">
                  <label class="uk-form-label" for="form-horizontal-text">State</label>
                  <div class="uk-form-controls">
                    <select formControlName="state" class="uk-select" [required]="required">
                      <option value="">Please Select</option>
                      <option *ngFor="let s of states" [value]="s">{{ s }}</option>
                    </select>
                  </div>

                </div>

                <div class="uk-margin">
                  <label class="uk-form-label" for="form-horizontal-text">Country</label>
                  <div class="uk-form-controls">
                    <select formControlName="country" class="uk-select" [required]="required">
                      <option value="">Please Select</option>
                      <option *ngFor="let fee of shippingFees" [value]="fee.id">{{ fee.id }}</option>
                    </select>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div> -->
            </div>
            <hr />
            <h3 class="uk-padding-small uk-margin-remove uk-padding-remove-bottom">Cart items</h3>
            <div class="uk-card uk-card-small">
                <div class="uk-card-body">
                    <ul class="uk-list uk-child-width-1-2@m" uk-grid>
                        <li *ngFor="let item of order.items; let idx = index">
                            <div class="uk-grid-small" uk-grid>
                                <app-order-item class="uk-width-expand" [item]="item"></app-order-item>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="uk-flex uk-flex-right uk-margin" uk-grid>
            <div class="uk-width-xlarge@s">
                <div class="text-xs uk-padding-small" style="background-color: #EEF8F1;">
                    <div class="uk-grid-small" uk-grid>
                        <div class="uk-width-expand">Subtotal</div>
                        <div>{{order.subtotal | price_format}}</div>
                    </div>
                    <div *ngIf="order.discount_amount" class="uk-grid-small" uk-grid>
                        <div class="uk-width-expand">Discount ({{order.total_discount_value}})</div>
                        <div>-{{order.discount_amount | price_format}}</div>
                    </div>
                    <div *ngIf="order.promocode.promocode_amount" class="uk-grid-small" uk-grid>
                        <div class="uk-width-expand">Promocode ({{order.promocode.code}})</div>
                        <div>-{{order.promocode.promocode_amount | price_format}}</div>
                    </div>
                    <div *ngIf="order.voucher_amount" class="uk-grid-small" uk-grid>
                        <div class="uk-width-expand">Voucher ({{order.vouchercode}})</div>
                        <div>-{{order.voucher_amount | price_format}}</div>
                    </div>
                    <div *ngFor="let c of order.charges" class="uk-grid-small" uk-grid>
                        <div class="uk-width-expand">{{c.title}}</div>
                        <div>{{c.amount | price_format}}</div>
                    </div>
                    <div class="uk-grid-small" style="font-size: 12pt;" uk-grid>
                        <div class="uk-width-expand"><b>Total</b></div>
                        <div><b>{{order.total | price_format}}</b></div>
                    </div>
                </div>
                <!-- <div *ngIf="order.balance" class="text-xs uk-padding-small" style="background-color: #def3e4;">
          <div class="uk-grid-small" uk-grid>
            <div class="uk-width-expand">Payment Made</div>
            <div>{{order.total_paid | price_format}}</div>
          </div>
          <div class="uk-grid-small" uk-grid>
            <div class="uk-width-expand">Balance Due</div>
            <div>{{order.balance | price_format}}</div>
          </div>
        </div> -->
            </div>
        </div>
        <div *ngIf="order.images">
            <h5 class="mt-5">Photos </h5>
            <div class="row mb-3" *ngIf="order.images">
                <div class="col-6 col-sm-4 col-lg-2" *ngFor="let im of order.images">
                    <div class="card card-2 border-0">
                        <div class="card-body">
                            <a href="{{im}}" target="_blank"><img class="card-img" src="{{im}}" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="payment" class="uk-margin-large-top" *ngIf="!order.paid && order.status != 'cancel'">
            <div *ngIf="order.status == 'new' || order.balance">
                <!-- <h3 class="uk-text-center uk-text-danger">Select Payment Method </h3> -->

                <ul class="uk-subnav uk-subnav-pill uk-text-center uk-child-width-expand" uk-switcher="connect: #payment-type; animation: uk-animation-fade">
                    <li *ngIf="onlinePaymentTypes"><a href="#">Select Payment Method</a></li>
                    <li *ngIf="offlinePaymentTypes"><a href="#">Offline Payments</a></li>
                </ul>

                <ul id="payment-type" class="uk-switcher uk-margin">
                    <li *ngIf="onlinePaymentTypes">
                        <div class="uk-flex-center uk-grid-match uk-grid-small uk-child-width-1-1 uk-child-width-1-2@s uk-grid">
                            <div *ngFor="let item of onlinePaymentTypes; let i = index" (click)="select_payment(i,'online')">
                                <div *ngIf="item.active" class="uk-card-small" [ngClass]="paymentSelectedOnlineIndex==i ? 'uk-border-selected' : 'uk-border-unselected'">
                                    <div class="uk-card-body" style="cursor: pointer;">
                                        <label>
                      <div class="uk-flex uk-flex-middle" uk-grid>
                        <div class="uk-width-auto"><input type="radio" class="uk-radio"></div>
                        <div class="uk-width-expand uk-margin-small-bottom">
                          <h3 class="uk-margin-remove">{{item.title}}</h3>
                          <div class="uk-text-muted" [innerHtml]="item.remark"></div>
                        </div>
                      </div>
                      <div class="radioimage"><img [src]="'/assets/bank/'+(item.title|lowercase)+'.png'"></div>
                    </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="uk-margin uk-text-center">
                            <button [disabled]="saveDisabled" class="uk-button uk-button-large uk-border-pill uk-button-success uk-width-expand uk-width-large@s">PAY
                NOW</button>
                        </div>
                    </li>
                    <li *ngIf="offlinePaymentTypes">
                        <div class="uk-grid-match uk-grid-small uk-child-width-1-2 uk-child-width-1-4@s uk-grid">
                            <div *ngFor="let item of offlinePaymentTypes; let i = index" (click)="select_payment(i,'offline')">
                                <div *ngIf="item.active" class="uk-card-small" [ngClass]="paymentSelectedOfflineIndex==i ? 'uk-card-secondary' : 'uk-box-shadow-small'">
                                    <div class="uk-card-body uk-text-center ">
                                        <div class="radioimage">
                                            <img [src]="'/assets/bank/'+(item.title|lowercase)+'.png'">
                                        </div>
                                        <b>{{item.title}}</b>
                                        <div class="uk-text-muted">
                                            Number: {{item.number}}
                                            <div [innerHtml]="item.remark"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="uk-padding-small uk-margin-bottom uk-width-expand uk-text-center uk-background-muted">
                            <div *ngIf="available_credit > 0" class="uk-margin uk-width-expand">
                                <h5 class="uk-margin-small-bottom">Available Credit<br /><span class="uk-text-success">{{available_credit|price_format}}</span></h5>
                                <div uk-flex>
                                    <input ngModel class="uk-input uk-form-width-medium" aria-label="Credit" aria-describedby="button-credit" placeholder="Enter Use Amount" type="number" [(ngModel)]="use_credit">
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div id="proof" class="uk-width-expand uk-text-center">
                            <h5 class="uk-heading"><span style="margin-right:10px" class="iconify" data-icon="mdi-receipt"></span>Proof of Payment
                                <i><small>(Limit of 10MB)</small></i>
                            </h5>
                            <small class="uk-text-muted">Upload your receipt by either taking a photo or screenshot of it.</small>
                            <div>
                                <div class="uk-text-danger">{{validation.paymentproof}}</div>
                                <input hidden #fileInput type="file" (change)="uploadFile($event.target.files)" accept="image/jpeg,image/png,application/pdf">
                                <div>
                                    <button class="uk-button uk-button-primary" (click)="fileInput.click()">Select File</button>
                                    <div *ngIf="paymentForm.value.paymentproof">
                                        <img *ngIf="noimg;else showIcon" src="assets/pdf.svg" style="height: 100px;margin-bottom: 20px;"><br />
                                        <ng-template #showIcon>
                                            <img [src]="paymentForm.value.paymentproof" style="max-height: 100px;padding: 10px 0;">
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="uk-margin uk-text-center">
                            <button class="uk-margin uk-button uk-button-secondary" [disabled]="saveDisabled">SUBMIT</button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</form>