import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { NgbDropdownMenu, NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseLoginComponent } from 'projects/shared/components/base-auth.component';
import { ConfirmDialogComponent } from 'projects/shared/components/basic-dialogs.component';
import { Menu } from 'projects/shared/models/menu';
import { ApiProvider } from '../../services/api.service';
import { CartProvider } from '../../services/cart.service';
import { Webpage } from 'projects/shared/models/webpage';
import { Category } from 'projects/shared/models/category';


@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class TopMenuComponent extends BaseLoginComponent implements OnInit {

  menu: Menu;
  isMenuCollapsed = true;
  @Input() name: string;
  @ViewChild(NgbDropdownMenu) accountDropdownMenu: NgbDropdownMenu;
  categories: Category[];
  contentWebpage: Webpage;
  shopscat: Menu;
  tagsmenu = new Menu();
  @Input() links: string;

  constructor(
    config: NgbModalConfig,
    private modalService: NgbModal,
    private api: ApiProvider,
    public cart: CartProvider,
    injector: Injector
  ) {
    super(injector);
    this.api.getWebpage('home-body').then((ret) => {
      this.contentWebpage = ret;
    }, () => { });
    config.backdrop = 'static';
    config.keyboard = false;
  }

  open(content) {
    this.modalService.open(content);
  }

  afterLogin(): void {
    this.isMenuCollapsed = !this.isMenuCollapsed;
  }

  ngOnInit() {
    super.ngOnInit();
    // this.api.getMenu(this.name || 'SHOPS').then((result) => {
    //   // console.log(result);
    //   this.shopscat = result;
    // }, () => { });
    this.api.getMenu(this.name || 'TOPNAV').then((result) => {
      this.menu = result;
    }, () => { });

    this.api.getMenu(this.links || 'PRODUCT_TAGS').then((result) => {
      this.tagsmenu = result;
    }, () => { });

    this.api.getCategories().then((result) => {
      this.categories = result;
    }, () => { });
  }

  logout() {
    ConfirmDialogComponent.show(this.modal, 'Logout', 'Are you sure?').then((result) => {
      if (result === true) {
        this.account.logout().finally(() => {
          this.router.navigate(['/']);
          this.isMenuCollapsed = !this.isMenuCollapsed;
        });
      }
    });
  }

  isActive(url: string) {
    if (url === '/') { return (this.router.url === url); }
    return this.router.url.startsWith(url);
  }

  navClick(url) {
    if (url && url !== '') {
      this.router.navigateByUrl(url);
    }
    this.isMenuCollapsed = !this.isMenuCollapsed;
  }

}
