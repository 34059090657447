<div>
  <div class="uk-margin uk-text-left@s uk-text-center">
    <a class="uk-link-reset" [routerLink]="['/shop', product.id]">
      <div class="uk-inline-clip uk-transition-toggle uk-margin-small-bottom" tabindex="0">
        <div class="uk-position-top-right uk-position-z-index">
          <div class="promo-tag item-mnm text-xs" *ngIf="product.skus[0].promotion">
            <div *ngFor="let p of product.skus[0].promotion">
              {{p.short_label}}
            </div>
          </div>
        </div>
        <div *ngIf="product.isOutOfStock"
          class="uk-position-center uk-position-z-index uk-padding-small uk-overlay uk-overlay-default uk-text-center uk-light uk-background-secondary ofs-label">
          SOLD OUT
        </div>
        <div class="uk-cover-container">
          <canvas width="500" height="680"></canvas>
          <img *ngIf="product.images.length > 0; else defaultImg" [src]="product.images[0]" alt="" uk-cover>
          <ng-template #defaultImg><img src="assets/placeholder.jpg" alt="" uk-cover></ng-template>
          <img *ngIf="product.images.length > 1" class="uk-transition-scale-up uk-position-cover"
            [src]="product.images[1]" alt="" uk-cover>
        </div>
      </div>
      <div class="uk-margin-small-bottom uk-margin-remove-bottom"><span
          class="uk-link-heading text-gold">{{product.title| slice:0:100}}</span></div>
      <div *ngIf="product.skus[0].promotion; else originalprice">
        <div class="uk-text-secondary uk-text-uppercase uk-text-normal">{{product.price|price_format}}</div>
        <small><del class="uk-text-danger">{{product.original_price|price_format}}</del></small>
      </div>
      <ng-template #originalprice>
        <div class="uk-text-secondary uk-text-normal">{{product.price|price_format}}</div>
      </ng-template>
    </a>
  </div>
</div>