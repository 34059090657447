import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Webpage } from 'projects/shared/models/webpage';
import { BrowserTitle } from 'projects/shared/services/utilities.provider';
import { ApiProvider } from '../../services/api.service';

@Component({
  selector: 'app-webpage',
  templateUrl: './webpage.component.html',
  styleUrls: ['./webpage.component.scss'],
})
export class WebpageComponent implements OnInit {
  url: string;
  webpage: Webpage;
  constructor(private router: Router, private api: ApiProvider, private browserTitle: BrowserTitle) {
    // change the behavior for wildcard route
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    // strip leading '/'
    this.url = this.router.url.substr(1);
    this.api.getWebpage(this.url).then((result) => {
      this.webpage = result;
      this.browserTitle.setTitle(this.webpage.title);
    }, () => {
      this.router.navigate(['404-not-found']);
    });

  }

  ngOnInit() {

  }

}
