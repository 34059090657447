export class CreditLog implements GenericModel {
    id: string;
    updated_at: Date;
    created_at: Date;

    fromJson(json: any): CreditLog {
        const ret = Object.assign(new CreditLog(), json);
        ret.id = json._id;
        ret.updated_at = new Date(json.updated_at);
        ret.created_at = new Date(json.created_at);
        return ret;
    }

    listFromJson(json: Array<any>): CreditLog[] {
        return json.map((i: any) => this.fromJson(i));
    }

    toJson() {
        return {
            id: this.id,

        };
    }

}
