<app-carousel name="HOMEBANNER"></app-carousel>
<section class="uk-section-small uk-margin-remove-bottom uk-padding-remove-bottom uk-container uk-text-center">
  <div id="newarrive">
    <h1 class="m-space uk-margin-remove-bottom text-gold dancing-font ">Latest Products</h1>
    <h6 class="uk-margin-top uk-margin-remove-top uk-text-uppercase text-gold caveat-font">New Arrivals</h6>
  </div>
  <div class="uk-grid-small uk-grid-row-small uk-child-width-1-2 uk-child-width-1-4@s" uk-grid>
    <div *ngFor="let p of newProducts">
      <app-product-tile [product]="p"></app-product-tile>
    </div>
  </div>
  <div id="mixmatch">
    <h1 class="m-space uk-margin-remove-bottom text-gold dancing-font ">Best Buy</h1>
    <h6 class="uk-margin-top uk-margin-remove-top uk-text-uppercase text-gold caveat-font">Shop Best Buy for selected items</h6>
  </div>
  <div class="uk-grid-small uk-grid-row-small uk-child-width-1-2 uk-child-width-1-4@s" uk-grid>
    <div *ngFor="let p of promoProducts">
      <app-product-tile [product]="p"></app-product-tile>
    </div>
  </div>
</section>
<!--
<section class="container py-4 mb-2">
  <h1 class="main-title text-center">每周特价</h1>
  <h2 class="sub-title text-center"> Weekly specials </h2>
  <div class="row my-3 justify-content-center">
    <div class="col-6 col-md-4 col-lg-3 mb-3" *ngFor="let p of weeklySpecials">
      <app-product-tile [product]="p"></app-product-tile>
    </div>
  </div>
  <h1 class="mt-5 pt-5 main-title text-center" style="border-top: 1px solid #e1e1e1;">热销产品</h1>
  <h2 class="sub-title text-center"> Hot Products </h2>
  <div class="row my-3 justify-content-center">
    <div class="col-6 col-md-4 col-lg-3 mb-3" *ngFor="let p of hotProducts">
      <app-product-tile [product]="p"></app-product-tile>
    </div>
  </div>
  <h1 class="mt-5 pt-5 main-title text-center" style="border-top: 1px solid #e1e1e1;">特色产品</h1>
  <h2 class="sub-title text-center"> Featured products </h2>
  <div class="row my-3 justify-content-center">
    <div class="col-6 col-md-4 col-lg-3 mb-3" *ngFor="let p of featuredProducts">
      <app-product-tile [product]="p"></app-product-tile>
    </div>
  </div>
</section> -->
<!--
<section name="intro21" class="container mb-5 border-bottom pb-3">
  <div class="media">
    <img class="mr-3" [src]="contentWebpage.image" style="max-width: 100px;">
    <div class="media-body" [innerHtml]="contentWebpage.content">
    </div>
  </div>
</section>
<section class="container py-4 mb-2">
  <h1 class="main-title text-center">每周特价</h1>
  <h2 class="sub-title text-center"> Weekly specials </h2>
  <div class="row my-3 justify-content-center">
    <div class="col-6 col-md-4 col-lg-3 mb-3" *ngFor="let p of weeklySpecials">
      <app-product-tile [product]="p"></app-product-tile>
    </div>
  </div>
  <h1 class="mt-5 pt-5 main-title text-center" style="border-top: 1px solid #e1e1e1;">热销产品</h1>
  <h2 class="sub-title text-center"> Hot Products </h2>
  <div class="row my-3 justify-content-center">
    <div class="col-6 col-md-4 col-lg-3 mb-3" *ngFor="let p of hotProducts">
      <app-product-tile [product]="p"></app-product-tile>
    </div>
  </div>
  <h1 class="mt-5 pt-5 main-title text-center" style="border-top: 1px solid #e1e1e1;">特色产品</h1>
  <h2 class="sub-title text-center"> Featured products </h2>
  <div class="row my-3 justify-content-center">
    <div class="col-6 col-md-4 col-lg-3 mb-3" *ngFor="let p of featuredProducts">
      <app-product-tile [product]="p"></app-product-tile>
    </div>
  </div>
</section> -->

<!-- <section name="intro22" class="container mb-5">
  <h1 class="main-title text-center">Hot News</h1>

  <div class="card-columns">
    <div *ngFor="let n of news" class="card border-0 mb-3">
      <div class="bg-image-cover rounded shadow mb-3" [ngStyle]="{'background-image':'url('+n.image+')'}" style="padding-bottom:50%;background-position: center;
      background-size: cover;" [routerLink]="['/news', n.id, n.url]"></div>
      <h4 class="text-secondary mb-3" [routerLink]="['/news', n.id, n.url]">{{n.title}}</h4>
      <p>
        {{n.content | striphtml | truncate : 200 }}
      </p>
    </div>
  </div>

</section> -->