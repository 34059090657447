import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Product } from 'projects/shared/models/product';
import { AccountProvider } from 'projects/shared/services/account.provider';
import { ToastService } from 'projects/shared/services/toast.provider';
import { BrowserTitle } from 'projects/shared/services/utilities.provider';
import { ApiProvider } from '../../../services/api.service';
import { CartProvider } from '../../../services/cart.service';
import { WishlistProvider } from '../../../services/wishlist.service';
import { ConfirmDialogComponent } from 'projects/shared/components/basic-dialogs.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Sku, PromoBundle, Promo } from 'projects/shared/models/sku';
import { Meta } from '@angular/platform-browser';
import { SeoSocialShareService, SeoSocialShareData } from 'ngx-seo';
import { Category } from 'projects/shared/models/category';
import { Webpage } from 'projects/shared/models/webpage';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})

export class ProductComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private api: ApiProvider,
    private cartProvider: CartProvider,
    private title: BrowserTitle,
    private toast: ToastService,
    public wishlist: WishlistProvider,
    public account: AccountProvider,
    private modal: NgbModal,
    public meta: Meta,
    private readonly seoSocialShareService: SeoSocialShareService,
    @Inject('siteUrl') private siteUrl: string,
  ) {
    this.productId = this.route.snapshot.paramMap.get('id');
    this.api.getProduct(this.productId).then((result) => {
      this.product = result;
      this.title.setTitle(this.product.title);
      this.get_review();
      this.checkPromo();
      this.get_webpage();
      this.buildPromotionsLabel();

      if (this.product.skus.length > 0) {
        this.selectedSku = this.product.skus[0];
      }

      this.buildDimentionTable();
      const url = this.siteUrl + 'shop/' + this.product.id;
      const content = this.product.content?.replace(/<[^>]+>/g, '').substring(0, 200) + '...';
      this.meta.updateTag({ property: 'og:url', content: url });
      this.meta.updateTag({ property: 'og:title', content: this.product.title });
      this.meta.updateTag({ property: 'og:description', content });
      if (this.product.images) { this.meta.updateTag({ property: 'og:image', content: this.siteUrl + this.product.images[0] }); }

      this.meta.updateTag({ property: 'og:type', content: 'og:product' });
      this.meta.updateTag({ property: 'product:price:amount', content: this.product.price.toFixed(2) });
      this.meta.updateTag({ property: 'product:price:currency', content: 'MYR' });

      const seoData: SeoSocialShareData = {
        title: this.product.title,
        description: content,
        image: this.siteUrl + this.product.images[0],
        url,
        type: 'og:product',
      };
      this.seoSocialShareService.setData(seoData);
    });

  }
  list = [1, 2, 3, 4, 5];
  rate = [1, 2, 3, 4, 5];

  public isCollapsed = false;

  contentWebpage: Webpage;
  product: Product;
  productId: string;
  selectedColor: string;
  selectedSize: string;
  selectedFlavour: string;
  quantity = 1;
  activeSize: any[];
  activeFlavour: any[];
  reviews: any[];
  reviewCount: number;
  isBundle = false;
  categories: Category[];
  // bundlesBox: Promo[] = [];
  bundlesBox: Product[] = [];
  dimensiontable = [['a', 'b', 'c'], [1, 2, 3], [4, 5, 6]];
  selectedSku: Sku;
  promotionsLabel: any[] = [];


  // OwlCarousel
  customOptions: OwlOptions = {
    loop: false,
    autoplay: true,
    dots: false,
    URLhashListener: true,
    navSpeed: 700,
    navText: ['<', '>'],
    items: 1,
    nav: true
  };

  ngOnInit() {
    this.api.getCategories().then((result) => {
      this.categories = result;
    });
  }

  checkPromo() {
    let haveBundle = false;
    this.product.skus.filter((v) => {
      if (v.promotion) {
        v.promotion.forEach((p) => {
          if (p.type === 'bundle') { haveBundle = true; }
          if (p.type !== 'bundle') { this.product.isPromo = true; }
        });
      }
    });

    if (haveBundle) {
      this.api.getBundlebyParent(this.product.id).then((result) => {
        if (result) {
          this.isBundle = true;
          this.bundlesBox = result;
        }
      });
    }
  }

  rmdisc(v) {
    if (!v.includes('%')) {
      v = 'RM ' + parseInt(v).toFixed(2);
    }
    return v;
  }

  dec() {
    this.quantity = this.quantity - 1;
    if (this.quantity < 1) { this.quantity = 1; }
    this.cartProvider.update_price(this.selectedSku, this.quantity, this.product);
  }

  inc() {
    this.quantity = this.quantity + 1;
    this.cartProvider.update_price(this.selectedSku, this.quantity, this.product);
  }

  buildPromotionsLabel() {
    this.product.skus.forEach((v) => {
      if (v.promotion) {
        v.promotion.forEach((p) => {
          const data = { id: p.promo_id, name: p.name, description: p.description, type: p.type };
          if (!this.promotionsLabel.some(el => el.id === data.id)) {
            this.promotionsLabel.push(data);
          }
        });
      }
    });
  }

  // improve later
  // add_bundle(bundlesBoxPromoId) {

  //   let discount = "";//this.bdl_saving(bset);
  //   const msg =
  //     `<p class="text-primary">Awesome, you have saved more with this bundle deal!</p>
  //     <h3>${discount} Saved</h3>`;

  //   ConfirmDialogComponent.show(this.modal, 'Bundle Deal Confirmation', msg)
  //     .then((success) => {
  //       if (success === true) {
  //         this.bundle_to_cart(bundlesBoxPromoId);
  //       }
  //     });
  // }

  bundle_to_cart(bundlesBoxPromoIdle) {
    const theBundle = this.bundlesBox.find((v) => bundlesBoxPromoIdle === v.id);
    theBundle.isBundle = true;
    this.cartProvider.addItem(1, theBundle, theBundle.skus[0], '', '', '').then((result) => {
      this.toast.show(`${this.product.title} added to cart!`, { status: 'danger' });
    }).catch((e) => this.toast.show(`Error: ${e.error.error}`, { status: 'danger' }));
  }

  select_sku(sku: Sku) {
    if (sku.max > 0) {
      this.selectedSku = sku;
      this.cartProvider.update_price(this.selectedSku, this.quantity, this.product);
    } else {
      this.toast.show(`Selected product is out of stock`, { status: 'danger' });
    }
  }

  add_to_cart(event) {
    if (this.product.skus.length === 1) {
      this.selectedSku = this.product.skus[0];
    } else if (this.selectedSku == null) {
      this.toast.show(`Please select a product`, { status: 'danger' });
      return;
    }

    this.selectedColor = this.selectedSku.colors;
    this.selectedSize = this.selectedSku.sizes;
    this.selectedFlavour = this.selectedSku.flavour;

    // const selectedSku = this.product.skus.find((v) => {
    //   const color = this.selectedColor ? this.selectedColor : '';
    //   const size = this.selectedSize ? this.selectedSize : '';
    //   // const flavour = this.selectedFlavour ? this.selectedFlavour : '';
    //   // if (v.colors === color &&
    //   //   v.sizes === size &&
    //   //   v.flavour === flavour) {
    //   //   return v;
    //   // }

    //   // This no need flavour, also need make variant dynamic checking in future
    //   if (v.colors === color &&
    //     v.sizes === size) {
    //     return v;
    //   }
    // });

    // if (this.product.options.colors && this.product.options.colors.length !== 0 && this.selectedColor == null) {
    //   this.toast.show(`Please pick a color`, { status: 'danger' });
    //   return;
    // }

    // if (!selectedSku) {
    //   this.toast.show(`Please pick a size`, { status: 'danger' });
    //   return;
    // }

    if (this.selectedSku.max <= 0) {
      this.toast.show(`${this.product.title} is out of stock`, { status: 'uk-text-danger' });
      return;
    } else if (this.selectedSku.max < this.quantity) {
      this.toast.show(`${this.product.title} the maximum quantity can buy at once is ${this.selectedSku.max}`, { status: 'uk-text-danger' });
      return;
    }
    this.cartProvider.addItem(this.quantity, this.product, this.selectedSku, this.selectedColor, this.selectedSize, this.selectedFlavour).then((result) => {
      this.toast.show(`<b>${this.product.title}</b> added to your cart.`, { status: ' uk-text-success' });
    }).catch((e) => this.toast.show(`Error: ${e.error.error}`, { status: 'uk-text-danger' }));
  }

  add_to_wishlist(event) {
    this.wishlist.addWishlistItem(this.product).then((ret) => {
      if (ret.message) {
        this.toast.show(ret.message, { status: ret.success ? ' uk-text-success' : ' uk-text-danger' });
      }
    });
  }

  remove_from_wishlist(event) {
    this.wishlist.removeWishlistItem(this.product).then((ret) => {
      if (ret.message) {
        this.toast.show(ret.message, { status: ret.success ? 'uk-danger' : 'uk-success' });
      }
    });
  }

  // select_color(c) {
  //   this.selectedColor = c;
  //   this.macthing_check(c, null);
  //   this.cartProvider.update_price(this.selectedColor, this.selectedSize ? this.selectedSize : '', this.selectedFlavour ? this.selectedFlavour : '', this.quantity, this.product);
  // }

  // select_size(s) {
  //   this.selectedSize = s;
  //   this.macthing_check(null, s);
  //   this.cartProvider.update_price(this.selectedColor ? this.selectedColor : '', this.selectedSize, this.selectedFlavour ? this.selectedFlavour : '', this.quantity, this.product);
  // }

  // select_flavour(f) {
  //   this.selectedFlavour = f;
  //   this.cartProvider.update_price(this.selectedColor ? this.selectedColor : '', this.selectedSize, this.selectedFlavour, this.quantity, this.product);
  // }

  macthing_check(color = null, size = null) {
    if (color) {
      const arr_size = [];
      const arr_flavour = [];
      this.product.skus.filter((v) => {
        if (v.colors === color) {
          arr_size.push(v.sizes);
          arr_flavour.push(v.flavour);
        }
      });
      this.selectedSize = null;
      this.selectedFlavour = null;
      this.activeSize = arr_size;
      this.activeFlavour = arr_flavour;
    }
    if (size) {
      const arr_flavour = [];
      this.product.skus.filter((v) => {
        if (v.sizes === size) {
          arr_flavour.push(v.flavour);
        }
      });
      this.selectedFlavour = null;
      this.activeFlavour = arr_flavour;
    }
  }

  // update_price(colors = null, size = null, flavour = null, qty = null) {
  //   //
  //   this.product.skus.forEach((v) => {
  //     if (v.colors == colors && v.sizes == size && v.flavour == flavour) {
  //       if (v.promotion.type == 'mpq') {
  //         let selectedPromo = v.promotion.mpq.forEach((m) => {
  //           if (qty <= 1) {
  //             this.product.price = v.price;
  //           }
  //           else if (m.min_qty <= qty) {
  //             if (m.discount.includes("%")) {
  //               let dis = Number(m.discount.slice(0, -1));
  //               let thisdis = ((v.price * qty) * dis / 100);
  //               this.product.price = (v.price * qty) - thisdis;
  //               // this.priceSummary = '1.(%)=> v.original_price(' + v.original_price + ') x qty(' + qty + ') = v.price(' + v.price * qty + ') - disc ' + m.discount + '(' + thisdis + ') = ' + this.product.price;
  //               return true;
  //             } else {
  //               let dis = parseFloat(m.discount) * qty;
  //               this.product.price = (v.price * qty) - dis;
  //               // this.priceSummary = '2.(no %)=> v.original_price(' + v.original_price + ') x qty(' + qty + ') = v.price(' + v.price * qty + ') - disc ' + m.discount + '(' + dis + ') = ' + this.product.price;
  //               return true;
  //             }
  //           }
  //         });
  //         // if (this.priceSummary) console.log(this.priceSummary);
  //       }
  //       else {
  //         this.product.price = v.price * qty;
  //       }
  //     }
  //   });
  // }

  get_review() {
    this.api.getReview(this.product.id).then((result) => {
      if (result) {
        this.reviewCount = result.length ? result.length : 0;
        this.reviews = result;
      } else {
        this.reviews = null;
      }
    });
  }
  get_webpage() {
    this.api.getWebpage('delivery-info').then((result) => {
      if (result) {
        this.contentWebpage = result;
      } else {
        this.contentWebpage = null;
      }
    });
  }

  bdl_saving(bundle) {
    let discounted = 0;
    bundle.items.filter((v) => {
      if (v.discount.includes('%')) {
        const dis = Number(v.discount.slice(0, -1));
        discounted += (v.unit_price * dis / 100);
      } else {
        discounted += parseFloat(v.discount); // v.unit_price - parseFloat(v.discount);

      }
    });

    return 'RM ' + discounted.toFixed(2);
  }

  bdl_total(bundle) {
    let discounted = 0;
    bundle.items.filter((item) => {
      const dis = this.bdl_discount(item.unit_price, item.discount, true);
      discounted += parseFloat(dis);
    });
    return 'RM ' + discounted.toFixed(2);
  }

  bdl_discount(price, discount, norm = false) {
    let ad = 0;
    if (discount.includes('%')) {
      const dis = Number(discount.slice(0, -1));
      const thisdis = (price * dis / 100);
      ad = price - thisdis;
    } else {
      ad = price - discount;
    }

    if (norm) { return ad.toFixed(2); } else { return 'RM ' + ad.toFixed(2); }
  }

  buildDimentionTable() {
    if (this.product.dimension && this.product.dimension !== '') {
      this.dimensiontable = this.product.dimension.split('\n').map((row) => row.split('|'));
    } else {
      this.dimensiontable = null;
    }
  }

}
