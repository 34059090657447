import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from 'projects/shared/components/basic-dialogs.component';
import { DateDisplayPipe, PriceFormatPipe } from 'projects/shared/pipes/custom.pipe';
import { ToastService } from 'projects/shared/services/toast.provider';
import { CartProvider } from '../../../services/cart.service';
import { Cart, PaymentMethod, DeliveryMethod, CartPayments, CartPromocode, PickupInfo } from 'projects/shared/models/cart';
import { Router } from '@angular/router';
import { BrowserTitle } from 'projects/shared/services/utilities.provider';
import { ApiProvider } from '../../../services/api.service';
import { OnlinePaymentTypes, OfflinePaymentTypes } from 'projects/shared/models/cmspayments';
import { ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { AccountProvider } from 'projects/shared/services/account.provider';
import { Address } from 'projects/shared/models/address';
import { ShippingFees } from 'projects/shared/models/cmsshippingfrees';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  providers: [PriceFormatPipe]
})
export class CheckoutComponent implements OnInit, AfterContentChecked {

  paymentSelectedOnlineIndex: number;
  paymentSelectedOfflineIndex: number;
  paymentSelected: string;
  deliverySelected: string;
  paymentMethods: PaymentMethod[];
  deliveryMethods: DeliveryMethod[];
  promocode = '';
  available_credit = 0;
  use_credit = 0;
  active = 'addresses';
  submitDisabled = false;

  // online & offline payments
  cartPayments: CartPayments[];
  onlinePaymentTypes: OnlinePaymentTypes[];
  offlinePaymentTypes: OfflinePaymentTypes[];

  paymentMode: string;
  disableBilling = true;

  shippingFees: ShippingFees[];
  deliveryAddress: Address;
  billingAddress: Address;
  checkoutForm = this.fb.group({
    pickup: [false],
    pickup_message: [''],
    pickup_name: ['', [Validators.required]],
    pickup_phone: ['', [Validators.required, Validators.pattern('\\d{10,11}')]],
    delivery: this.fb.group({
      type: ['delivery'],
      name: ['', Validators.required],
      phone: ['', [Validators.required, Validators.pattern('\\d{10,11}')]],
      company: [''],
      address_1: ['', Validators.required],
      address_2: [''],
      address_3: [''],
      city: ['', Validators.required],
      postcode: ['', Validators.required],
      state: ['', Validators.required],
      country: ['', Validators.required],
    }),
    same_as_delivery: [true],
    billing: this.fb.group({
      type: ['billing'],
      name: [{ value: '', disabled: this.disableBilling }, ''],
      phone: [{ value: '', disabled: this.disableBilling }, Validators.pattern('\\d{10,11}')],
      company: [{ value: '', disabled: this.disableBilling }, ''],
      address_1: [{ value: '', disabled: this.disableBilling }, ''],
      address_2: [{ value: '', disabled: this.disableBilling }, ''],
      address_3: [{ value: '', disabled: this.disableBilling }, ''],
      city: [{ value: '', disabled: this.disableBilling }, ''],
      postcode: [{ value: '', disabled: this.disableBilling }, ''],
      state: [{ value: '', disabled: this.disableBilling }, ''],
      country: [{ value: '', disabled: this.disableBilling }, ''],
    }),
  });

  constructor(public cart: CartProvider, private toast: ToastService,
    private fb: FormBuilder, private modal: NgbModal,
    private priceFormat: PriceFormatPipe, private router: Router,
    private api: ApiProvider, private title: BrowserTitle,
    private cdref: ChangeDetectorRef, public account: AccountProvider,
  ) {
    this.title.setTitle('Checkout');
    this.checkoutForm.patchValue(cart.content.checkout);
    this.promocode = cart.content.promocode.code ?? '';
    this.deliverySelected = cart.content.checkout.delivery_method;
    this.paymentSelected = cart.content.checkout.payment_method;
    this.paymentMode = cart.content.checkout.mode;

    // this.api.getPaymentMethods().then((results) => {
    //   this.paymentMethods = results;
    // });

    this.api.getDeliveryMethods().then((results) => {
      this.deliveryMethods = results;
    });

    this.api.getCartPayments().then((results) => {
      this.cartPayments = results.filter((item) => item.id !== 'cod');
    });

    this.api.getOnlinePaymentsTypes().then((results) => {
      this.onlinePaymentTypes = results;
      if (results.length > 0) {
        this.select_payment(0, 'online');
      }
    });
    this.api.getOfflinePaymentsTypes().then((results) => {
      this.offlinePaymentTypes = results;
    });

    // Obtain available credit
    this.api.getDashboard().then((result) => {
      this.available_credit = result.credits;
    });

    this.api.getShippingFees().then((results) => {
      this.shippingFees = results;
    });

    this.api.getAddresses().then((results) => {
      if (results) {
        results.forEach((p) => {
          p.types.filter((t) => {
            if (t === 'shipping') {
              this.deliveryAddress = p;
              this.checkoutForm.patchValue({
                delivery: {
                  name: this.deliveryAddress.name ? this.deliveryAddress.name : '',
                  phone: this.deliveryAddress.phone ? this.deliveryAddress.phone : '',
                  company: this.deliveryAddress.company ? this.deliveryAddress.company : '',
                  address_1: this.deliveryAddress.address_1 ? this.deliveryAddress.address_1 : '',
                  address_2: this.deliveryAddress.address_2 ? this.deliveryAddress.address_2 : '',
                  address_3: this.deliveryAddress.address_3 ? this.deliveryAddress.address_3 : '',
                  city: this.deliveryAddress.city ? this.deliveryAddress.city : '',
                  postcode: this.deliveryAddress.postcode ? this.deliveryAddress.postcode : '',
                  state: this.deliveryAddress.state ? this.deliveryAddress.state : '',
                  country: this.deliveryAddress.country ? this.deliveryAddress.country : '',
                }
              });

              this.assignShippingFee();
            }
            if (t === 'billing') {
              this.billingAddress = p;
              this.checkoutForm.patchValue({
                billing: {
                  name: this.billingAddress.name ? this.billingAddress.name : '',
                  phone: this.billingAddress.phone ? this.billingAddress.phone : '',
                  company: this.billingAddress.company ? this.billingAddress.company : '',
                  address_1: this.billingAddress.address_1 ? this.billingAddress.address_1 : '',
                  address_2: this.billingAddress.address_2 ? this.billingAddress.address_2 : '',
                  address_3: this.billingAddress.address_3 ? this.billingAddress.address_3 : '',
                  city: this.billingAddress.city ? this.billingAddress.city : '',
                  postcode: this.billingAddress.postcode ? this.billingAddress.postcode : '',
                  state: this.billingAddress.state ? this.billingAddress.state : '',
                  country: this.billingAddress.country ? this.billingAddress.country : '',
                }
              });
            }
          });
        });


        if (this.deliveryAddress.id !== this.billingAddress.id) {
          this.checkoutForm.patchValue({
            same_as_delivery: false,
          });
          this.checkoutForm.controls.billing.enable();
        }
      }
    });

    this.checkoutForm.patchValue(
      {
        pickup_name: this.account.currentUser.name,
        pickup_phone: this.account.currentUser.phone
      }
    );
  }

  ngOnInit() {
    this.pickupCheck();
  }

  apply_promocode() {
    this.cart.setPromoCode(this.promocode).then(() => {
      this.toast.show(`Promo Code ${this.promocode} applied`, { classname: 'text-white bg-success' });
    }, (reason) => {
      this.toast.show(`Invalid Promo Code ${this.promocode}, ${reason.error.error}`, { classname: 'bg-warning' });
      this.cart.content.promocode = new CartPromocode();
      this.promocode = '';
    });
  }

  apply_credit() {
    this.cart.check_credit(this.use_credit, this.available_credit, true).then((msg) => {

    }).catch((e) => {
      this.toast.show(e, { classname: 'bg-warning' });
    });
  }

  addresses_next() {
    this.updateCheckout().then(() => {
      // this.active = 'delivery';
      this.active = 'payment';
    });
  }

  select_delivery(item: DeliveryMethod) {
    this.deliverySelected = null;
  }

  delivery_next() {
    this.updateCheckout().then(() => {
      this.active = 'payment';
    });
  }

  select_payment(index: number, mode) {
    let paymentMethodId;
    this.paymentSelectedOnlineIndex = null;
    this.paymentSelectedOfflineIndex = null;

    if (mode === 'online') {
      paymentMethodId = this.onlinePaymentTypes[index].id;
      this.paymentSelectedOnlineIndex = index;
    } else {
      paymentMethodId = this.offlinePaymentTypes[index].id;
      this.paymentSelectedOfflineIndex = index;
    }
    this.paymentSelected = paymentMethodId;
    this.paymentMode = mode;
  }

  payment_next() {
    this.submitDisabled = true;
    this.updateCheckout().then(() => {
      let msg =
        `<p>Select <b>Confirm</b> to continue, You will be taken to the payment website.</p>
        <p>Your payable amount is <span class="uk-text-large uk-text-bold">${this.priceFormat.transform(this.cart.content.total)}</span></p>`;

      if (!this.needsPayment()) {
        msg =
          `<p>Complete your order by selecting <b>Confirm</b>.</p>
          <p>Your payable amount is <span class="uk-text-large uk-text-bold">${this.priceFormat.transform(this.cart.content.total)}</span></p>`;
      }

      ConfirmDialogComponent.show(this.modal, 'Checkout Confirmation', msg)
        .then((success) => {
          if (success === true) {
            this.createOrderAndRedirect();
          }
        }, (reason) => {

          this.submitDisabled = false;

        });
    }).catch((e) => {
      this.submitDisabled = false;
    });
  }

  needsPayment(): boolean {
    return (this.cart.content.total > 0 && this.paymentSelected !== 'cod' && this.paymentMode !== 'offline');
  }

  createOrderAndRedirect() {
    this.cart.createOrder().then((order) => {
      // redirect to payment or view order
      if (order.clear_cart === true) {
        this.cart.clearCart();
        this.router.navigate(['/user', 'orders', order.refno], { queryParams: { status: 'success' } });
      } else {
        window.location.href = order.checkout_url;
      }
    }, (reason) => {
      this.submitDisabled = false;
      this.toast.show(`${reason.error.error}`, { classname: 'bg-warning' });
    });
  }

  updateCheckout(): Promise<Cart> {
    let billing_address = {};
    let pickup_info = new PickupInfo();
    if (this.checkoutForm.value.same_as_delivery) {
      billing_address = this.checkoutForm.value.delivery;
    } else if (this.checkoutForm.value.pickup) {
      billing_address = {
        name: this.checkoutForm.value.pickup_name,
        phone: this.checkoutForm.value.pickup_phone,
      };
      pickup_info.pickup_name = this.checkoutForm.value.pickup_name;
      pickup_info.pickup_phone = this.checkoutForm.value.pickup_phone;
      pickup_info.pickup_message = this.checkoutForm.value.pickup_message;
    } else {
      billing_address = this.checkoutForm.value.billing;
    }


    return this.cart.setCheckout(
      billing_address,
      this.checkoutForm.value.delivery,
      this.checkoutForm.value.same_as_delivery,
      this.deliverySelected,
      this.paymentSelected,
      this.paymentMode,
      this.checkoutForm.value.pickup,
      pickup_info,
    );
  }

  sameAsDeliveryCheck() {
    if (this.checkoutForm.value.same_as_delivery) {
      this.checkoutForm.controls.billing.disable();
    } else {
      this.checkoutForm.controls.billing.enable();
    }
  }

  pickupCheck() {
    //Control Submit button can be click or not
    if (this.checkoutForm.value.pickup) {
      this.checkoutForm.controls.pickup_phone.enable();
      this.checkoutForm.controls.pickup_name.enable();

      this.checkoutForm.controls.same_as_delivery.setValue(false);
      this.sameAsDeliveryCheck();
      this.checkoutForm.controls.delivery.disable();
      this.checkoutForm.controls.billing.disable();
      this.checkoutForm.controls.same_as_delivery.disable();
      this.cart.setShipping(null);
    } else {
      this.checkoutForm.controls.pickup_phone.disable();
      this.checkoutForm.controls.pickup_name.disable();
      this.checkoutForm.controls.same_as_delivery.setValue(true);
      this.sameAsDeliveryCheck();
      this.checkoutForm.controls.delivery.enable();
      this.checkoutForm.controls.same_as_delivery.enable();
      this.assignShippingFee();
    }
  }

  assignShippingFee() {
    this.shippingFees.filter((v) => {
      if (v.id === this.checkoutForm.value.delivery.country) {
        this.cart.setShipping(v);
      }
    });
  }


  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }


}
