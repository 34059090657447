import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-tags-input',
  template: `
  <div class="form-control height-auto" (click)="entry.focus()">
    <span class="badge badge-pill badge-primary mr-2 badge-tag" *ngFor="let t of tags; let i = index">
      {{t}} <a (click)="removeTagAt(i)"><span class="iconify" data-icon="mdi-close-circle"></span></a>
    </span>
    <input class="noshadow" #entry (keyup)="onKeyup($event)" (blur)="onBlur($event)" placeholder="...">
  </div>
`,
  styles: [
    `.noshadow {
      border: none !important;
      box-shadow: none !important;
      outline: none;
    }`,
    `.badge-tag {
      font-size: 1em;
      font-weight: normal;
    }`
  ],
  // templateUrl: './tags-input.component.html',
  // styleUrls: ['./tags-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagsInputComponent),
      multi: true
    }
  ]
})
export class TagsInputComponent implements ControlValueAccessor {

  tags: string[] = [];

  constructor() { }

  onValueChanged() {
    this.propagateChange(this.tags);
  }

  onKeyup(e) {
    if (e.keyCode === 13 || e.key === ',') {
      const val = e.target.value.replace(',', '');
      this.addTag(val);
      e.target.value = '';
    } else if (e.keyCode === 8 && e.target.value === '') {
      this.tags.pop();
    }
  }

  onBlur(e) {
    const val = e.target.value.replace(',', '');
    this.addTag(val);
    e.target.value = '';
  }

  addTag(t) {
    t = t.trim();
    if (t === '') { return; }
    const idx = this.tags.indexOf(t);
    if (idx === -1) { this.tags.push(t); }
  }

  removeTagAt(idx) {
    this.tags.splice(idx, 1);
  }

  // ControlValueAccessor interfaces
  private propagateChange = (_: any) => { };

  writeValue(value: any): void {
    this.tags = value;
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void { }
}
