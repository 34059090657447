<section class="uk-container uk-section">
  <div class="uk-flex-center" uk-grid>
    <div class="uk-width-large uk-card uk-card-default uk-card-body">
      <h1 class="uk-margin-remove-bottom">Sign Up</h1>
      <h6 class="uk-margin-remove-top">Please add your details.</h6>

      <form class="uk-margin-medium uk-form-horizontal" [formGroup]="signupForm" (submit)="signup()">
        <div class="uk-margin">
          <!-- <label class="uk-form-label" for="name">Your Name</label> -->

          <input formControlName="name" class="uk-input" type="text" id="name" placeholder="Your name" required>
          <div class="uk-text-danger">{{validation.name}}</div>
        </div>
        <div class="uk-margin">
          <!-- <label class="uk-form-label" for="email">Email</label> -->

          <input formControlName="email" class="uk-input" type="email" id="email" placeholder="Email" required>
          <div class="uk-text-danger">{{validation.email}}</div>
        </div>
        <div class="uk-margin">
          <!-- <label class="uk-form-label" for="password">Password</label> -->

          <div class="uk-inline uk-width-expand">
            <a class="form-check uk-form-icon uk-form-icon-flip">
              <input type="checkbox" class="uk-checkbox form-check-input" (change)="showpassword=$event.target.checked"
                uk-toggle="target: #iconeye; cls: show" id="showpassword" placeholder="Password" hidden>
              <label id="iconeye" for="showpassword">
                <span class="iconify" data-icon="la:eye" data-inline="false" data-width="25" data-height="25"></span>
              </label>
            </a>
            <input formControlName="password" [type]="showpassword ? 'text' : 'password'" class="uk-input" id="password"
              placeholder="Password" required>
          </div>
          <div class="uk-text-muted" *ngIf="!validation.password">At least 8 characters</div>
          <div class="uk-text-danger">{{validation.password}}</div>

        </div>
        <div class="form-check uk-margin uk-grid-small uk-child-width-auto uk-grid">
          <label for="agreetnc">
            <input type="checkbox" class="uk-checkbox" formControlName="agreetnc" id="agreetnc" required>
            By signing up with us, you have read and agree with our <a href="/tnc" target="_blank">Terms and
              Conditions</a>.
          </label>
        </div>
        <button type="submit" class="uk-button uk-button-secondary uk-width-1-1" [disabled]="!signupForm.valid">Create
          Account</button>
      </form>

      <p class="uk-text-center">
        <a [routerLink]="['/auth', 'login']" class="uk-link-heading"><u>Login</u></a> |
        <a [routerLink]="['/auth', 'forgot-password']" class="uk-link-heading"><u>Forgot Password?</u></a>
      </p>
    </div>
  </div>
</section>
