<div id="sidebar">
  <!-- <div class="input-group mb-3">
    <input type="search" [(ngModel)]="keywords" class="form-control" (keyup)="onKeyup($event)" placeholder="Search..." aria-label="Search" aria-describedby="search">
    <div class="input-group-append">
      <button class="btn btn-secondary" type="submit" id="search" (click)="onKeyup($event)">&nbsp;
      </button>
    </div>
  </div> -->
  <div class="uk-margin">
    <div class="uk-search uk-search-default">
      <span uk-search-icon></span>
      <input class="uk-search-input" [(ngModel)]="keywords" (keyup)="onKeyup($event)" type="search" placeholder="Search..." aria-describedby="search">
    </div>
  </div>
  <h5 class="uk-margin-small-bottom caveat-font"><b>Categories</b></h5>
  <ul class="uk-nav-default uk-nav-parent-icon" uk-nav>

    <li class="uk-active">
      <a (click)="router.navigateByUrl('shop')" class="caveat-font">All Products</a>
    </li>
    <li class="uk-parent caveat-font" *ngFor="let item of categories; let i=index;"
      [class.uk-open]="selectedIndex === i" (mouseout)="removeIndex(i, item.label)" (mouseover)="setIndex(i, item.label)">

      <a (click)="router.navigateByUrl('shop#'+item.url)" [class.uk-active]="isActive('/shop#'+item.label)">{{item.label}}</a>

      <ul class="uk-nav-sub uk-padding-remove" id={{item.label}}>
        <li *ngFor="let subitem of item.children">
          <a (click)="router.navigateByUrl('shop#'+subitem.url)" [class.uk-active]="isActive('/shop#'+subitem.url)">{{subitem.label}}</a>
        </li>
      </ul>
    </li>

    <li class="uk-parent" *ngFor="let item of tagsmenu.items">
      <ng-template v-if="item.title != null">
        <a>{{item.title}}</a>
        <ul class="uk-nav-sub uk-padding-remove">
          <li *ngFor="let subitem of item?.items"><a href="{{subitem.url}}" [class.active]="isActive(subitem.title)">{{subitem.title}}</a></li>
        </ul>
      </ng-template>
    </li>
    <!-- <li class="cat-item" *ngFor="let item of menu.items">
      <div class="count text-muted small mt-4">{{item.title}}</div>
      <div *ngFor="let subitem of item.items">
        <a (click)="router.navigateByUrl(subitem.url)" [class.current]="isActive(subitem.url)">{{subitem.title}}</a>
      </div>
    </li> -->
  </ul>
</div>
