import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Order } from 'projects/shared/models/order';
import { AccountProvider } from 'projects/shared/services/account.provider';
import { ToastService } from 'projects/shared/services/toast.provider';
import { BrowserTitle } from 'projects/shared/services/utilities.provider';
import { ApiProvider } from '../../../services/api.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'projects/web/src/environments/environment';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

  orders: Order[];
  which: string;
  pageSize = environment.pageSize;
  page = 1;

  constructor(
    public account: AccountProvider,
    title: BrowserTitle,
    private modal: NgbModal,
    private api: ApiProvider,
    private toast: ToastService,
    private route: ActivatedRoute,
  ) {
    title.setTitle('My Orders');

    // get fragment
    this.route.fragment.subscribe((f) => {
      this.api.getOrders(f).then((results) => {
        this.which = f;
        this.orders = results;
      });
    });
  }


  ngOnInit(): void {

  }

}
