import { Injectable } from '@angular/core';
import { Product } from 'projects/shared/models/product';
import { WishlistItem } from 'projects/shared/models/wishlist';
import { ApiProvider } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class WishlistProvider {

    content: WishlistItem[] = [];

    constructor(private api: ApiProvider) { }

    loadWishlist(): Promise<any> {
        return this.api.getWishlist().then((result) => {
            this.content = result;
            return result;
        });
    }

    isWishlistItem(product: Product): boolean {
        const item = this.content.find((e) => e.product_id === product.id);
        return (item !== undefined);
    }

    addWishlistItem(product: Product): Promise<any> {
        const item = new WishlistItem();
        item.product_id = product.id;
        item.title = product.title;
        item.url = product.url;
        item.thumbnail = product.images[0];
        return this.api.postWishlistItem(item).then((result) => {
            if (result.success === true) { this.content.push(item); }
            return result;
        });
    }

    removeWishlistItem(product: Product | WishlistItem): Promise<any> {
        let item: WishlistItem;
        if (product instanceof WishlistItem) {
            item = product;
        } else {
            item = this.content.find((e) => e.product_id === product.id);
        }
        if (item === undefined) {
            throw new Error('har?');
        }

        const idx = this.content.indexOf(item);
        this.content.splice(idx, 1);
        return this.api.deleteWishlistItem(item.id);
    }
}
