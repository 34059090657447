import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AccountProvider } from '../services/account.provider';
import { PlatformLocation } from '@angular/common';


@Injectable()
export class AuthGuard implements CanActivate {
    platformLocation: PlatformLocation;
    urlPath: String;

    constructor(private account: AccountProvider, private router: Router, injector: Injector) {
        this.platformLocation = injector.get(PlatformLocation);
        this.urlPath = this.platformLocation.getBaseHrefFromDOM();
    }

    // if not logged in, redirect to login page with parameter redir=XXX
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this.account.isLoggedIn) {
            this.router.navigate(['/auth', 'login'], { queryParams: { redir: state.url } });
            return false;
        } else if (this.account.isLoggedIn && this.urlPath == "/admin/" && !this.account.isAdmin) {
            this.account.logout();
            this.router.navigate(['/auth', 'login'], { queryParams: { redir: state.url } });
            return false;
        }
        return true;
    }
}


@Injectable()
export class SignedInGuard implements CanActivate {

    constructor(private account: AccountProvider, private router: Router) { }

    // if not logged in, redirect to login page with parameter redir=XXX
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.account.isLoggedIn) {
            this.router.navigate(['/user']);
            return false;
        }
        return true;
    }
}
