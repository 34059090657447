import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { News } from 'projects/shared/models/news';
import { ApiProvider } from '../../../services/api.service';
import { BrowserTitle } from 'projects/shared/services/utilities.provider';
import { environment } from 'projects/web/src/environments/environment';

@Component({
    selector: 'app-news-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.scss'],
})
export class NewsComponent implements OnInit {
    url: string;
    news: News[];
    pageSize = environment.pageSize * 4;
    page = 1;

    constructor(
        private api: ApiProvider,
        private route: ActivatedRoute,
        private router: Router,
        private title: BrowserTitle,
    ) {

        this.title.setTitle('News');

        // change the behavior for wildcard route
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;

        // look for hash
        this.news = null;

        const f = this.route.snapshot.fragment || '';
        const s = this.route.snapshot.queryParamMap.get('search') || '';
        this.api.getAllNews({ tags: f || '', search: s }).then((results) => {
            this.news = results;
        });

    }

    ngOnInit() { }
}
