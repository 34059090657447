import { Component, OnInit } from '@angular/core';
import { AccountProvider } from 'projects/shared/services/account.provider';
import { User } from 'projects/shared/models/user';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ApiProvider } from '../../../services/api.service';
import { ToastService } from 'projects/shared/services/toast.provider';
import { BrowserTitle } from 'projects/shared/services/utilities.provider';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor(
    public account: AccountProvider,
    private fb: FormBuilder,
    private api: ApiProvider,
    private toast: ToastService,
    title: BrowserTitle
  ) {
    title.setTitle('Profile');
  }

  ngOnInit(): void {
  }

}
