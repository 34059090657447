import { Payment } from 'projects/shared/models/payment';
import { Cart, CartItem, PickupInfo } from './cart';
import './genericmodel';

export class Order extends Cart {
  static statuses = ['new', 'processing', 'ready_to_ship', 'shipping', 'complete', 'cancel'];

  refno: string;
  clear_cart: boolean;
  checkout_url: string;
  status: string;
  delivery_trackingno: string;
  processing_at: Date;
  processed_at: Date;
  delivery_at: Date;
  completed_at: Date;
  cancelled_at: Date;
  payment: Payment;
  rating: boolean;
  rated_at: Date;
  paid: boolean;
  paid_at: Date;
  shipping: Shipping;
  billing: Billing;
  delivery_method: string;
  payment_method: string;
  created_at: Date;
  items: CartItem[] = [];
  payment_mode: string;
  images: string;
  token: string;
  balance: number;
  total_paid: number;
  records: [];
  pickup: boolean;
  remark_text: string;
  pickup_info: PickupInfo;
  voucher_amount: number;
  discount_amount: number;
  total_discount_value: number;
  vouchercode:string;

  public get isReview(): boolean {
    return this.status === 'complete' && !this.rated_at;
  }


  // override toJson if necessary
  // currently no need

  public get statusclass(): string {
    switch (this.status.toLowerCase()) {
      case 'processing':
      case 'ready_to_ship':
        return 'uk-label-default';
      case 'shipping':
        return 'uk-label-warning';
      case 'complete':
        return 'uk-label-success';
      case 'cancel':
        return 'uk-label-muted';
      default:
        return 'uk-label-primary';
    }
  }


  public get canCancel(): boolean {
    return (this.status === 'new');
  }

  public get canChangeSatus(): boolean {
    return (this.status !== 'cancel' && this.status !== 'complete');
  }

  fromJson(json: any): Order {
    const ret = Object.assign(new Order(), super.fromJson(json));
    ret.delivery_at = json.delivery_at ? new Date(json.delivery_at) : null;
    ret.processed_at = json.processed_at ? new Date(json.processed_at) : null;
    ret.cancelled_at = json.cancelled_at ? new Date(json.cancelled_at) : null;
    ret.completed_at = json.completed_at ? new Date(json.completed_at) : null;
    ret.created_at = json.created_at ? new Date(json.created_at) : null;
    ret.paid_at = json.paid_at ? new Date(json.paid_at) : null;
    ret.rated_at = json.rated_at ? new Date(json.rated_at) : null;
    ret.payment = (new Payment()).fromJson(json.payment);
    ret.items = json.carts ? (new CartItem()).listFromJson(json.carts) : [];
    ret.remark_text = json.remark_text ? json.remark_text.replace(/\r\n/gi, '<br>') : "";
    return ret;
  }

  listFromJson(json: Array<any>): Order[] {
    return json.map((i) => this.fromJson(i));
  }

  updatableJson() {
    return {
      id: this.id,
      status: this.status,
      delivery_trackingno: this.delivery_trackingno,
      items: this.items.map((i) => i.toJson()),
      // checkout: this.checkout.toJson(),
      delivery_method: this.delivery_method,
      payment_method: this.payment_method,
      // balance: this.balance,
      // total_paid: this.total_paid,
    };
  }
}


export class Shipping {
  address_1: string;
  address_2: string;
  address_3: string;
  company: string;
  city: string;
  name: string;
  phone: string;
  postcode: string;
  state: string;
  country: string;

  public get address(): string {
    return `${this.address_1 ?? ""} ${this.address_2 ?? ""} ${this.address_3 ?? ""}`;
  }

  fromJson(json: any): Cart {
    const ret = Object.assign(new Cart(), json);
    return ret;

  }

  listFromJson(json: Array<any>): Cart[] {
    return json.map((i) => this.fromJson(i));
  }

  toJson() {
    return {
      address: this.address,
      address_1: this.address,
      address_2: this.address,
      address_3: this.address,
      company: this.company,
      city: this.city,
      name: this.name,
      phone: this.phone,
      postcode: this.postcode,
      state: this.state,
      country: this.country,
    };
  }
}



export class Billing {
  address_1: string;
  address_2: string;
  address_3: string;
  company: string;
  city: string;
  name: string;
  phone: string;
  postcode: string;
  state: string;
  country: string;
  email: string;

  public get address(): string {
    return `${this.address_1 ?? ""} ${this.address_2 ?? ""} ${this.address_3 ?? ""}`;
  }

  fromJson(json: any): Billing {
    const ret = Object.assign(new Billing(), json);
    return ret;
  }

  listFromJson(json: Array<any>): Billing[] {
    return json.map((i) => this.fromJson(i));
  }

  toJson() {
    return {
      address: this.address,
      address_1: this.address,
      address_2: this.address,
      address_3: this.address,
      company: this.company,
      city: this.city,
      name: this.name,
      phone: this.phone,
      postcode: this.postcode,
      state: this.state,
      country: this.country,
    };
  }
}
