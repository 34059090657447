import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-rating',
  styles: [`
    :host {
      display: flex;
    }
  `],
  template: `
    <app-star
      *ngFor="let item of iterable; let i = index"
      [active]="rating > i"
      (setActive)="value = i+1"
    >{{ i+1 }}</app-star>
  `
})
export class RatingComponent {

  public iterable = Array(5);
  public rating = 0;

  @Input() set max(value) { this.iterable = Array(+value); }

  get max() { return this.iterable.length; }

  @Input() set value(value: number) {
    value > this.max
      ? this.rating = this.max
      : this.rating = value;
    this.vote.emit(value);
  }

  @Output() vote = new EventEmitter<number>();
}
