import { Component, OnInit, Inject, Input, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Payment } from 'projects/shared/models/payment';
import { ToastService } from 'projects/shared/services/toast.provider';
import { BrowserTitle } from 'projects/shared/services/utilities.provider';
import { ApiProvider } from '../../../services/api.service';
import { CartProvider } from '../../../services/cart.service';
import { Order } from 'projects/shared/models/order';
import { PaymentMethod, CheckoutInfo, PaymentMode, OfflinePayments, CartPayments, CartCharges } from 'projects/shared/models/cart';
import { ConfirmDialogComponent } from 'projects/shared/components/basic-dialogs.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DOCUMENT, TranslationWidth } from '@angular/common';
import { PriceFormatPipe } from 'projects/shared/pipes/custom.pipe';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { OnlinePaymentTypes, OfflinePaymentTypes } from 'projects/shared/models/cmspayments';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { ShippingFees } from 'projects/shared/models/cmsshippingfrees';
import { AccountProvider } from 'projects/shared/services/account.provider';

@Component({
  selector: 'app-payment-with-token',
  templateUrl: './payment-with-token.component.html',
  styleUrls: ['./payment-with-token.component.scss'],
  providers: [PriceFormatPipe]
})
export class PaymentWithTokenComponent implements OnInit {

  @Input() required = true;


  order: Order;
  token: string;
  paymentSelectedOnlineIndex: number;
  paymentSelectedOfflineIndex: number;
  paymentSelected: string;
  // paymentMode: PaymentMode[];

  offlinePayments: OfflinePayments[];
  cartPayments: CartPayments[];
  onlinePaymentTypes: OnlinePaymentTypes[];
  offlinePaymentTypes: OfflinePaymentTypes[];
  shippingFees: ShippingFees[];
  states: Array<String>;
  oriTotal: number;

  paymentMode: string;
  paymentForm: FormGroup;
  validation = { paymentproof: '' };
  file: File;
  noimg = false;

  saveDisabled = false;
  isReadOnly = false;

  available_credit = 0;
  use_credit = 0;


  constructor(
    private api: ApiProvider,
    private route: ActivatedRoute,
    public cart: CartProvider,
    private modal: NgbModal,
    private toast: ToastService,
    private router: Router,
    private title: BrowserTitle,
    private priceFormat: PriceFormatPipe,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private cdref: ChangeDetectorRef, public account: AccountProvider,
    @Inject(DOCUMENT) private document,
  ) {
    // make payment from token
    title.setTitle('Payment');
    this.token = this.route.snapshot.paramMap.get('token');

    //
    // this.api.getPaymentMethods().then((results) => {
    //   this.paymentMethods = results.filter((item) => item.id !== 'cod');
    // });

    // // this.api.getPaymentMode().then((results) => {
    // //   this.paymentMode = results;
    // // });

    // this.api.getOfflinePayments().then((results) => {
    //   this.offlinePayments = results;
    // });

    // note: remove api.getCartPayments once settle api
    // this.api.getCartPayments().then((results) => {
    //   this.cartPayments = results.filter((item) => item.id !== 'cod');
    // });

    this.states = [
      'Johor', 'Kedah', 'Kelantan', 'Melaka', 'Negeri Sembilan', 'Pahang', 'Perak', 'Perlis', 'Pulau Pinang',
      'Sabah', 'Sarawak', 'Selangor', 'Terengganu', 'W.P. Kuala Lumpur', 'W.P. Labuan', 'W.P. Putrajaya'
    ];



    function requiredIfValidator(predicate) {
      return (formControl => {
        if (!formControl.parent) {
          return null;
        }
        if (predicate()) {
          return Validators.required(formControl);
        }
        return null;
      });
    }

    this.paymentForm = this.fb.group({
      shipping: this.fb.group({
        name: ['', Validators.required],
        email: ['', Validators.required],
        phone: ['', [Validators.required, Validators.pattern('\\d{10,11}')]],
        company: [''],
        address_1: ['', Validators.required],
        city: ['', Validators.required],
        postcode: ['', Validators.required],
        state: ['', Validators.required],
        country: ['', Validators.required],
      }),
      // billing:this.fb.group({
      //   name: ['',Validators.required],
      //   email: ['',Validators.required],
      //   phone: ['', [Validators.required, Validators.pattern('\\d{8,12}')]],
      //   company: [''],
      //   address_1: ['',Validators.required],
      //   city: ['',Validators.required],
      //   postcode: ['',Validators.required],
      //   state: ['',Validators.required],
      //   country: ['',Validators.required],
      //   same_as_delivery:[''],
      // }),
      id: [''],
      paymentproof: [''],
      payment_method: [''],
      mode: [''],
      proof: [''],
      shippingCharges: [''],

      pickup: [false],
      pickup_info: this.fb.group({
        pickup_message: ['', Validators.required],
        pickup_name: ['', Validators.required],
        pickup_phone: ['', [Validators.required, Validators.pattern('\\d{10,11}')]],
        pickup_email: ['', Validators.required],
      }),
    });

    this.api.getOrderWithToken(this.token).then((order) => {
      this.order = order;
      this.oriTotal = this.order.total;

      if (this.order.status !== 'new') {
        this.isReadOnly = true;
      }

      // if (this.order.shipping.country) {
      //   this.shippingCountry(this.order.shipping.country);
      // }

      // this.order.charges.filter((c) => {
      //   console.log(c);
      //   if (c.type == 'shipping') {
      //     this.shippingCountry(c.id);
      //   }
      // });

      this.paymentForm.patchValue({
        shipping: this.order.shipping,
        // billing: this.order.billing,
        id: this.order.id,
        pickup: this.order.pickup,
      });

      this.paymentForm.controls.pickup_info.patchValue({
        pickup_name: this.order.pickup_info?.pickup_name ?? this.account.currentUser.name,
        pickup_phone: this.order.pickup_info?.pickup_phone ?? this.account.currentUser.phone,
        pickup_message: this.order.pickup_info?.pickup_message,
        pickup_email: this.order.billing.email ?? '',
      });

      this.api.getOnlinePaymentsTypes().then((results) => {
        this.onlinePaymentTypes = results;
      });
      this.api.getOfflinePaymentsTypes().then((results) => {
        this.offlinePaymentTypes = results;
      });
      this.api.getShippingFees().then((results) => {
        this.shippingFees = results;
        this.pickupCheck();
      });

    });

    // Obtain available credit
    // this.api.getDashboard().then((result) => {
    //   this.available_credit = result.credits;
    // });
  }


  ngOnInit(): void {
    // this.pickupCheck();
    // this.document.body.style.background = '#f7f7f7';
  }

  select_payment(index: number, mode) {
    let paymentMethodId;
    this.paymentSelectedOnlineIndex = null;
    this.paymentSelectedOfflineIndex = null;
    if (mode === 'online') {
      paymentMethodId = this.onlinePaymentTypes[index].id;
      this.paymentSelectedOnlineIndex = index;
    } else {
      paymentMethodId = this.offlinePaymentTypes[index].id;
      this.paymentSelectedOfflineIndex = index;
    }
    this.paymentSelected = paymentMethodId;
    this.paymentMode = mode;
  }

  async pay() {

    if (this.paymentForm.value.pickup && !this.paymentForm.controls.pickup_info.valid) {
      this.toast.show('Pick Up details are missing', { classname: 'text-light bg-danger' });
      return;
    } else if (!this.paymentForm.value.pickup && !this.paymentForm.controls.shipping.valid) {
      this.toast.show('Delivery details are missing', { classname: 'text-light bg-danger' });
      return;
    }

    this.order.billing = this.paymentForm.value.shipping;
    this.order.shipping = this.paymentForm.value.shipping;


    // [disabled]="!paymentSelected || !paymentForm.value.paymentproof"
    if (!this.paymentSelected) {
      this.toast.show('Please select a payment method for your order.', { classname: 'text-light bg-danger' });
      return;
    }

    try {
      await this.apply_credit();
    } catch (e) {
      this.toast.show(e, { classname: 'text-light bg-danger' });
      return;
    }

    if (this.paymentMode === 'offline') {
      if (!this.file) {
        this.toast.show('Please upload your payment proof.', { classname: 'text-light bg-danger' });
        return;
      } else {
        this.createPaymentAndRedirect();
      }
    } else {
      const msg =
        `<p class="text-primary">Select <b>Confirm</b> to continue, You will be taken to the payment website.</p>
          Your payable amount is <h3>${this.priceFormat.transform(this.order.total)}</h3>`;

      ConfirmDialogComponent.show(this.modal, 'Checkout Confirmation', msg)
        .then((success) => {
          if (success === true) {
            this.createPaymentAndRedirect();
          }
        });
    }
  }

  createPaymentAndRedirect() {

    this.saveDisabled = true;

    const fd = new FormData();
    fd.append('payment_method', this.paymentSelected);
    fd.append('mode', this.paymentMode);
    fd.append('proof', this.file ? this.file : null);
    if (this.use_credit > 0) {
      fd.append('credit_amount', this.use_credit.toString());
    }

    fd.append('pickup', this.paymentForm.value.pickup);
    if (this.paymentForm.value.pickup) {
      fd.append('pickup_info', JSON.stringify(this.paymentForm.value.pickup_info));
    } else {
      fd.append('billing', JSON.stringify(this.paymentForm.value.shipping));
      fd.append('shipping', JSON.stringify(this.paymentForm.value.shipping));
      fd.append('charges', JSON.stringify(this.paymentForm.value.shippingCharges));
    }

    this.api.payTokenLink(this.order.id, fd).then((order) => {
      if (order.payment_mode === 'offline') {
        this.router.navigate(['/payment', 'received', order.refno]);
      } else {
        window.location.href = order.checkout_url;
      }
    }).catch((e) => {
      this.saveDisabled = false;
    });
  }

  uploadFile(filelist: FileList) {
    this.noimg = false;
    this.file = filelist.item(0);
    // check image size
    if (this.file.size > (1024 * 1024) * 10) {
      this.toast.show(this.file.name + ' is too large (' + (this.file.size / 1024 / 1024).toFixed(1) + ' MB)', { classname: 'text-light bg-danger' });
      return;
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      if (this.file.type === 'application/pdf') {
        this.changeFile(this.file).then((encodeFile: string): any => {
          const theFile = this.sanitizer.bypassSecurityTrustResourceUrl(encodeFile);
          this.paymentForm.patchValue({ paymentproof: theFile });
        });

        this.noimg = true;
      } else {
        this.paymentForm.patchValue({ paymentproof: event.target.result.toString() });
      }
    };
    reader.readAsDataURL(this.file);
  }

  changeFile(file) {
    return new Promise((resolve, reject) => {

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  async apply_credit(): Promise<boolean> {
    if (this.use_credit > 0) {
      return this.cart.check_credit(this.use_credit, this.available_credit, false).then((v) => {
        return true;
      }).catch((e) => {
        return Promise.reject(e);
      });
    }
    return true;
  }

  sBeautify(v) {
    if (!v) { return ''; }
    v = v.toString();
    v = v.replace(/_/g, ' ');
    return 'Status: ' + v.charAt(0).toUpperCase() + v.slice(1);
  }

  copyShipping() {
    const copyShippingDetails = this.paymentForm.value.billing.same_as_delivery ? true : false;
    if (copyShippingDetails) {
      this.paymentForm.patchValue({ billing: this.paymentForm.value.shipping });
    }
  }

  shippingCountry(e) {
    let allChargesTotal = 0;
    this.order.charges.forEach((c) => {
      allChargesTotal += c.amount;
    });
    const orderShipping = this.order.charges.find((v) => 'shipping' === v.type);
    const shipping = orderShipping ? orderShipping.amount : 0;
    const chargesTotal = allChargesTotal - shipping;
    const promoAmount = this.order.promocode.promocode_amount ?? 0;
    const voucherAmount = this.order.voucher_amount ?? 0;
    const discountAmount = this.order.discount_amount ?? 0;

    const subtotal = this.order.subtotal + chargesTotal - discountAmount - voucherAmount - promoAmount;
    if (e === 'pickup') {
      const x = this.order.charges.findIndex((v) => 'shipping' === v.type);
      this.order.charges.splice(x, 1);
      this.order.total = subtotal;
      this.paymentForm.patchValue({ shippingCharges: [] });
    } else {
      this.shippingFees.filter((v) => {
        if (v.id === e) {
          var isFree = false;
          if (v.free != "" && subtotal > parseFloat(v.free)) {
            isFree = true;
          }

          if (this.order.status === 'new' || this.order.status === 'cancel') {
            const ship = new CartCharges();
            const remark = v.remark ? ' - ' + v.remark : '';
            ship.title = 'Shipping Fee (' + v.title + remark + ')';
            ship.amount = isFree ? 0 : parseFloat(v.shipping_fee);
            ship.type = 'shipping';
            ship.id = v.id;
            this.order.charges[0] = ship;
            this.order.total = subtotal + ship.amount;
            this.paymentForm.patchValue({ shippingCharges: [ship] });
            // } else {
            // this.order.total = subtotal;
          }
        }
      });
    }
  }

  pickupCheck() {
    if (this.paymentForm.value.pickup) {
      this.paymentForm.controls.pickup_info.setValidators([Validators.required]);
      this.paymentForm.controls.shipping.setValidators(null);
      // this.paymentForm.controls.billing.disable();
      this.shippingCountry('pickup');
    } else {
      this.paymentForm.controls.pickup_info.setValidators(null);
      this.paymentForm.controls.shipping.setValidators([Validators.required]);
      // this.paymentForm.controls.billing.enable();
      this.shippingCountry(this.paymentForm.value.shipping.country);
    }
  }

}
