<div id="page-title" class="uk-clearfix">
  <h2 class="uk-float-left">Manage Addresses</h2>
  <a class="uk-button uk-button-default uk-float-right" [routerLink]="['/user','addressbook', 'new']">+ New Address</a>
</div>
<hr>
<app-loading [loaded]="addresses"></app-loading>

<div *ngIf="addresses">
  <p class="uk-text-center" *ngIf="addresses.length==0 else adaAddress">No Address, <a
      [routerLink]="['/user','addressbook', 'new']">create one</a> to begin.</p>
  <ng-template #adaAddress>
    <div class="small float-right">
      <a (click)="chooseD()">Choose Default Address</a>
    </div>
  </ng-template>

  <div class="uk-card uk-box-shadow-small uk-padding-small uk-margin-bottom"
    *ngFor="let item of addresses; let idx = index">
    <div>
      <div>
        <div class="uk-clearfix">
          <ul class="uk-iconnav uk-float-right">
            <li><a [routerLink]="['/user', 'addressbook', item.id]" uk-icon="icon: pencil"></a></li>
            <li><a (click)="onDelete(item)" uk-icon="icon: trash"></a></li>
          </ul>
          <div class="uk-float-left">
            <div *ngIf="showTypes">
              <div class="uk-margin-small" *ngIf="item.types.indexOf('shipping') !== -1">Default Shipping</div>
              <div class="uk-margin-small" *ngIf="item.types.indexOf('billing') !== -1">Default Billing</div>
            </div>
            <div *ngIf="chooseTypes">
              <div class="form-check">
                <input (change)="chooseThisTypes($event)" class="form-check-input" type="radio"
                  [checked]="item.types.indexOf('shipping') !== -1" name="shipping" [attr.data-id]="item.id"
                  [attr.data-type]="'shipping'" [id]="[idx]+'_shipping'" />
                <label class="form-check-label small" [for]="[idx]+'_shipping'">Default Shipping</label>
              </div>
              <div class="form-check">
                <input (change)="chooseThisTypes($event)" class="form-check-input" type="radio"
                  [checked]="item.types.indexOf('billing') !== -1" name="billing" [attr.data-id]="item.id"
                  [attr.data-type]="'billing'" [id]="[idx]+'_billing'" />
                <label class="form-check-label small" [for]="[idx]+'_billing'">Default Billing</label>
              </div>
            </div>
            <b>{{item.name}}</b>
            <div class="uk-margin-small"><span uk-icon="icon:receiver"></span> {{item.phone}}</div>
            <div class="uk-margin-small" *ngIf="item.company"><span uk-icon="icon:hashtag"></span> {{item.company}}
            </div>
            <div class="uk-margin-small"><span uk-icon="icon:location"></span> {{item.fullAddress}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <form id="saveTypes" name="saveTypes" (submit)="saveTypes($event)">
    <input type="hidden" name="user_id" value="">
    <input type="hidden" name="shipping" value="">
    <input type="hidden" name="billing" value="">
    <button type="submit" class="uk-button uk-button-default" *ngIf="chooseTypes">Save</button>
  </form>
</div>