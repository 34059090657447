export class ReviewItem {

    product_id: string;
    rate: number = 0;
    review: string;
    file_images: File[]; //When submit review
    images: string[]; //When load image

    fromJson(json: any): ReviewItem {
        const ret = Object.assign(new ReviewItem(), json);
        return ret;
    }

    listFromJson(json: Array<any>): ReviewItem[] {
        return json.map((i: any) => this.fromJson(i));
    }

    toJson() {
        return {
            product_id: this.product_id,
            rating: this.rate,
            review: this.review,
            file_images: this.file_images,
            images: this.images
        };
    }
}
