<div uk-grid>
  <div class="uk-width-auto">
    <div *ngIf="item.promotion?.type == 'bundle';else normalitem">
      <img [src]="item.product_info.image" data-src="{{item.product_info.image}}" width="80" alt="{{item.product_info.title}}" uk-img>
    </div>
    <ng-template #normalitem>
      <div>
        <img [src]="item.product_info.image" data-src="{{item.product_info.image}}" width="80" alt="{{item.product_info.title}}" uk-img>
      </div>
    </ng-template>
  </div>
  <div class="uk-width-expand uk-inline">
    <div class="uk-position-top-right"><a class="text-danger" (click)="del()"><span uk-icon="icon: close"></span></a></div>
    <div class="uk-margin-small-bottom">
      <a class="uk-h6 uk-text-secondary" [routerLink]="['/shop', item.parent_id]">{{item.product_info.title}}</a><br /><small class="uk-text-danger uk-margin-remove" *ngIf="item.promotion?.type == 'bundle'">Bundle Deal: {{item.product_info.title}} ({{rmdisc(item.promotion.discount)}})</small>
    </div>
    <div class="uk-grid uk-child-width-1-2@s">
      <div class="uk-text-muted">
        Sku:
        <span class="uk-margin-small-left uk-text-secondary">{{item.sku}}</span>
      </div>
      <div *ngIf="item.options.color">
        <div class="uk-text-muted">
          Color:
          <span class="uk-margin-small-left uk-text-secondary">{{item.options.color}}</span>
        </div>
      </div>
      <div *ngIf="item.options.size">
        <div class="uk-text-muted">
          Size:
          <span class="uk-margin-small-left uk-text-secondary">{{item.options.size}}</span>
        </div>
      </div>
      <!-- <div>
        <div uk-grid>
          <div class="uk-width-1-2 uk-text-muted">Flavour:</div>
          <div class="uk-width-1-2 uk-text-secondary">{{item.options.flavour || '-'}}</div>
        </div>
      </div> -->
    </div>
    <div class="uk-grid uk-flex uk-flex-right uk-flex-middle">
      <div class="uk-child-width-expand">
        <h6>{{item.quantity*item.price | price_format}}</h6>
      </div>
      <div class="uk-child-width-auto">
        <div id="qty" uk-grid>
          <div class="uk-button-group uk-width-auto">
            <div class="uk-inline">
              <a class="uk-margin-small-right uk-margin-small-left" (click)="dec()"><span uk-icon="icon: minus"></span></a>
              <input class="uk-input uk-form-width-xsmall shop-atc-input" type="text" value="{{item.quantity}}" disabled>
              <a class="uk-margin-small-right uk-margin-small-left" (click)="inc()"><span uk-icon="icon: plus"></span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
