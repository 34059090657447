<section class="uk-section-small uk-padding-remove-bottom">

  <div class="uk-container uk-container-expand">
    <!-- <div class="uk-grid-small uk-grid-divider uk-child-width-auto  uk-flex uk-flex-center" uk-grid>
    <div>
      <ul class="uk-subnav uk-subnav-pill" uk-margin>
        <li *ngFor="let c of list_of_sort" [class.uk-active]="selected_sort==c" (click)="sortSelected(c)"><a>{{c}}</a></li>
      </ul>
    </div>
    <div>
      <ul class="uk-subnav uk-subnav-pill" uk-margin>
        <li [class.uk-active]="filter.color==''&&filter.size==''"><a (click)="filterreset()">All</a></li>
      </ul>
    </div>
    <div>
      <ul class="uk-subnav uk-subnav-pill" uk-margin>
        <li *ngFor="let c of colors" [class.uk-active]="filter.color==c" (click)="filtercolor(c)"><a>{{c}}</a></li>
      </ul>
    </div>
    <div>
      <ul class="uk-subnav uk-subnav-pill" uk-margin>
        <li *ngFor="let s of sizes" [class.uk-active]="filter.size==s" (click)="filtersize(s)"><a>{{s}}</a></li>
      </ul>
    </div>
  </div> -->
    <div uk-grid>
      <div class="uk-width-auto uk-visible@s">
        <app-shop-sidebar></app-shop-sidebar>
      </div>
      <div class="uk-width-expand">
        <div class="uk-flex uk-flex-middle uk-flex-between">
          <h3 class="uk-margin-remove">{{title}}</h3>
          <div>
            <div class="uk-form-horizontal">
              <div>
                <label class="uk-form-label uk-text-right"><b>Sort By</b></label>
                <div class="uk-form-controls">
                  <select class="uk-select" (change)="sortSelected($event.target.value)">
                    <option *ngFor="let v of list_of_sort" [value]="v">
                      {{ v }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <app-loading [loaded]="products"></app-loading>
        <div *ngIf="products">
          <h2 class="uk-text-center uk-margin-large" *ngIf="products && products.length==0">No match for '{{search}}'</h2>
          <div class="search-results" infiniteScroll (scrolled)="onScroll()">
            <div class="uk-grid-small uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-4@m  uk-child-width-1-12@xl uk-flex-center" uk-grid>
              <app-shop-product *ngFor="let item of products" [product]="item">
              </app-shop-product>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
