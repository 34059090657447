import { Component, OnInit, Inject } from '@angular/core';
import { AccountProvider } from 'projects/shared/services/account.provider';
import { DOCUMENT } from '@angular/common';



@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
})
export class UserComponent implements OnInit {

  constructor(public account: AccountProvider, @Inject(DOCUMENT) private document) {

  }

  ngOnInit(): void {
  }

}
