import './genericmodel';

//note: get payment types from api

export class ShippingFees {

    active: string;
    id: string;
    title: string;
    shipping_fee: string;
    free: string;
    remark: string;


    fromJson(json: any): ShippingFees {
        const ret = Object.assign(new ShippingFees(), json);
        return ret;
    }

    listFromJson(json: Array<any>): ShippingFees[] {
        return json.map((i: any) => this.fromJson(i));
    }

    toJson() {
        return {
            active: this.active,
            id: this.id,
            title: this.title,
            shipping_fee: this.shipping_fee,
            free: this.free,
            remark: this.remark,

        };
    }

}