import { Component, Input, OnChanges } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss']
})

export class ReadMoreComponent implements OnChanges {

  @Input('text') text: string;
  @Input('maxLength') maxLength: number = 100;
  @Input('showToggleButton') showToggleButton: boolean;

  currentText: SafeHtml;

  public isCollapsed: boolean = true;

  constructor(
    private sanitizer: DomSanitizer
  ) {

  }
  toggleView() {
    this.isCollapsed = !this.isCollapsed;
    var target = document.getElementById('contentShow');
    if (this.isCollapsed) {
      target.classList.remove('hidden');
      target.classList.add('show');
    } else {
      target.classList.remove('show');
      target.classList.add('hidden');
    }

  }

  ngOnChanges() {
    this.determineView();
  }

  determineView() {
    if (this.text != null) {
      var n = this.text.indexOf("</p>", this.maxLength);
      if (n > 0) {
        var firstPart = this.text.substr(0, n + 4);

        if (!this.currentText) {
          this.isCollapsed = false;
          var secondPart = "<span id='contentShow' class='hidden'>" + this.text.substr(n + 4) + "</span>";
          this.currentText = this.sanitizer.bypassSecurityTrustHtml(firstPart.concat(secondPart));
        }
        else { //if (this.currentText) {
          this.isCollapsed = true;
          var secondPart = "<span id='contentShow' class='show'>" + this.text.substr(n + 4) + "</span>";
          this.currentText = this.sanitizer.bypassSecurityTrustHtml(firstPart.concat(secondPart));
        }
        // else {
        //   this.isCollapsed = false;
        //   var secondPart = "<span class='hidden'>" + this.text.substr(n + 4) + "</span>";
        //   this.currentText = this.sanitizer.bypassSecurityTrustHtml(firstPart.concat(secondPart));
        // }

      } else {
        this.showToggleButton = false;
        this.isCollapsed = false;
        this.currentText = this.sanitizer.bypassSecurityTrustHtml(this.text);
      }
    }

    // if (this.text && this.text.length <= this.maxLength) {
    //   this.currentText = this.text;
    //   this.isCollapsed = false;
    //   return;
    // }
    // if (this.text) {
    //   if (this.isCollapsed == true) {
    //     // var str = "apple <iframe></iframe>ff</iframe>";
    //     // var n = this.text.indexOf("</iframe>");
    //     // var currentText = this.text.substr(0, n + 9) + " <span class='hidden'> " + this.text.substr(n + 9) + "</span>";
    //     var n = this.text.indexOf("</iframe>");
    //     if (n > 0) {
    //       var firstPart = this.text.substr(0, n + 9);
    //       var secondPart = "<span class='hidden'>" + this.text.substr(n + 9) + "</span>";
    //     }
    //     // this.currentText = this.text.substring(0, this.maxLength) + "...";
    //   } else if (this.isCollapsed == false) {
    //     this.currentText = this.text;
    //   }
    // }
  }
}