<div id="page-title">
  <h2>{{id=='new' ? 'Add' : 'Edit'}} Address</h2>
</div>
<hr />
<form [formGroup]="addressForm" (submit)="save()">

  <app-address-form [parentForm]="addressForm"></app-address-form>

  <div class="uk-margin">
    <button class="uk-button uk-button-secondary" [disabled]="!addressForm.valid">Save</button>
  </div>
</form>
