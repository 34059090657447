  <div class="align-self-center p-5 w-100">
    <h3 class="text-center">{{appname}}</h3>
    <h1 class="text-center display-4">Reset Password</h1>

    <form [formGroup]="resetForm" (submit)="reset()">
      <div class="py-5">

        <div class="form-group">
          <label for="email">Email</label>
          <input formControlName="email" class="form-control" type="email" id="email" readonly>
        </div>
        <div class="form-group">
          <label for="password">New Password</label>
          <input formControlName="password" [type]="showpassword ? 'text' : 'password'" class="form-control" id="password" placeholder="Password" required>
          <div class="small text-muted">At least 8 characters</div>
        </div>

        <div class="form-check">
          <input type="checkbox" class="form-check-input" (change)="showpassword=$event.target.checked" id="showpassword">
          <label class="form-check-label" for="showpassword">
            Show Password
          </label>
        </div>
      </div>

      <button type="submit" [disabled]="!resetForm.valid" class="btn btn-secondary btn-block">Reset Password</button>
    </form>

  </div>
