import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomPipes } from 'projects/shared/pipes/custom.pipe';
import { ComponentsModuleModule } from '../../components/components.module';
import { ProductComponent } from './product/product.component';
import { ShopProductComponent } from './shop-product/shop-product.component';
import { ShopRoutingModule } from './shop-routing.module';
import { ShopComponent } from './shop.component';
import { FormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ShopSidebarComponent } from './shop-sidebar/shop-sidebar.component';
import { CarouselModule } from 'ngx-owl-carousel-o';

@NgModule({
  declarations: [
    ShopComponent,
    ProductComponent,
    ShopProductComponent,
    ShopSidebarComponent,
  ],
  imports: [
    NgbModule,
    FormsModule,
    // ReactiveFormsModule,
    CustomPipes,
    CommonModule,
    ComponentsModuleModule,
    ShopRoutingModule,
    InfiniteScrollModule,
    CarouselModule
  ],
})
export class ShopModule { }
