<app-loading [loaded]="payment"></app-loading>
<div *ngIf="payment" class="uk-text-center uk-container uk-section">
  <div *ngIf="payment.success; else Failed">
    <h3>Payment Successful!</h3>

    <p class="uk-heading-2xlarge uk-text-success">

      <span class="iconify" data-icon="feather:check-circle" data-inline="false"></span>
    </p>


    You will receive more updates via Email. <a [routerLink]="['/user', 'orders', refno]">View your order</a>
  </div>

  <ng-template #Failed>
    <h3>Payment Unsuccesful!</h3>

    <p class="uk-heading-2xlarge uk-text-danger">
      <span class="iconify" data-icon="bx:bx-message-alt-error" data-inline="false"></span>
    </p>

    Please try to <a [routerLink]="['/cart', 'checkout']">checkout</a> again. Or contact us and use this reference (#{{id}})
  </ng-template>

</div>
