import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'payment-received',
  templateUrl: './payment-received.component.html',
  styleUrls: ['./payment-received.component.scss']
})
export class PaymentReceived implements OnInit {

  refno: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.refno = this.route.snapshot.params['refno'];
  }

  ngOnInit() {
  }

}
