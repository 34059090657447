<div class="card shadow-sm border-0" [routerLink]="['/shop', item.product_id, item.url]">
  <img class="card-img-top" [src]="item.thumbnail">

  <div class="card-body">
    <p>{{item.title}}</p>

    <button class="btn btn-danger btn-block" (click)="remove_from_wishlist($event)">
      <span class="iconify" data-icon="mdi-heart"></span> Remove
    </button>
  </div>

</div>
