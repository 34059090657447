import './genericmodel';
import { Promo, Sku } from './sku';

export class Product implements GenericModel {
  id: string;
  url: string;
  title: string;
  content: string;
  updated_at: Date;
  created_at: Date;
  price: number;
  original_price: number;
  rating: number;
  options = {
    colors: [] as string[],
    sizes: [] as string[],
    flavour: [] as any[],
  };
  tags: string[] = [];
  images: string[] = [];
  skus: Sku[] = [];
  isPromo = false;
  parent_id: string;
  photo: string;
  name: string;
  colour: string;
  size: string;
  flavour: string;
  category: string;
  isBundle = false;
  dimension: string;
  isOutOfStock: boolean;

  // items >.<
  items = {
    discount: [] as string[],
    name: [] as string[],
    photo: [] as string[],
    product_id: [] as string[],
    promo_id: [] as string[],
    sku: [] as string[],
    stock: {
      display_max_stock: [] as string[],
      id: [] as string[],
      location: [] as string[],
      qty: [] as string[],
      type: [] as string[],
      warning_below_selling_price: [] as string[],
    },
    unit_price: [] as string[],
  };

  // for updating
  newimages: string[] = [];
  deleteimages: string[] = [];

  fromJson(json: any): Product {
    const ret = Object.assign(new Product(), json);
    ret.id = json._id;
    ret.updated_at = new Date(json.updated_at);
    ret.created_at = new Date(json.created_at);
    ret.isOutOfStock = json.out_of_stock;
    return ret;
  }

  listFromJson(json: Array<any>): Product[] {
    return json.map((i) => this.fromJson(i));
  }

  toJson() {
    return {
      id: this.id,
      url: this.url,
      title: this.title,
      content: this.content,
      price: this.price,
      original_price: this.original_price,
      options: this.options,
      tags: this.tags,
      images: this.images,
      skus: this.skus,
      newimages: this.newimages,
      deleteimages: this.deleteimages,
      rating: this.rating,
      parent_id: this.parent_id,
      photo: this.photo,
      name: this.name,
      colour: this.colour,
      size: this.size,
      flavour: this.flavour,
      category: this.category,
      isOutOfStock: this.isOutOfStock
    };
  }

  checkIsPromo(type) {
    //Only normal return isPromo
    for (let v of this.skus) {
      if (v.promotion) {
        for (let p of v.promotion) {
          if (p.type === type) {
            return true;
            break;
          }
        }
      }
    }
    return false;
  }
}
