import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomPipes } from 'projects/shared/pipes/custom.pipe';
import { ComponentsModuleModule } from '../../components/components.module';
import { NewsComponent } from './index/index.component';
import { NewsRoutingModule } from './news-routing.module';
import { NewsPageComponent } from './page/page.component';


@NgModule({
  declarations: [
    NewsComponent,
    NewsPageComponent,
  ],
  imports: [
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CustomPipes,
    ComponentsModuleModule,
    CommonModule,
    NewsRoutingModule,
  ],
})
export class NewsModule { }
