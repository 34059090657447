import './genericmodel';

export class MenuItem {
    title: string;
    url: string;
    items: MenuItem[] = [];

    fromJson(json: any): MenuItem {
        const ret = Object.assign(new MenuItem(), json);
        ret.items = json.items ? (new MenuItem()).listFromJson(json.items) : [];
        return ret;
    }

    listFromJson(json: Array<any>): MenuItem[] {
        return json.map((i: any) => this.fromJson(i));
    }

    toJson() {
        return {
            title: this.title,
            url: this.url,
            items: this.items,
        };
    }

}

export class Menu implements GenericModel {
    id: string;
    name: string;
    items: MenuItem[] = [];
    updated_at: Date;
    created_at: Date;

    fromJson(json: any): Menu {
        const ret = Object.assign(new Menu(), json);
        ret.id = json._id;
        ret.updated_at = new Date(json.updated_at);
        ret.created_at = new Date(json.created_at);
        ret.items = json.items ? (new MenuItem()).listFromJson(json.items) : [];
        return ret;
    }

    listFromJson(json: Array<any>): Menu[] {
        return json.map((i: any) => this.fromJson(i));
    }

    toJson() {
        return {
            id: this.id,
            name: this.name,
            items: this.items.map((i) => i.toJson()),
        };
    }

}


export class FooterMenu implements GenericModel {
    id: string;
    updated_at: Date;
    created_at: Date;

    setting: FooterMenuSetting;
    tag: FooterMenuTNC;
    category: FooterMenuTNC;

    fromJson(json: any): FooterMenu {
        const ret = Object.assign(new FooterMenu(), json);
        ret.id = json._id;
        ret.updated_at = new Date(json.updated_at);
        ret.created_at = new Date(json.created_at);
        return ret;
    }

    listFromJson(json: Array<any>): FooterMenu[] {
        return json.map((i: any) => this.fromJson(i));
    }

    toJson() {
        return {
            setting: this.setting,
            tag: this.tag,
            category: this.category,

        };
    }

}

export class FooterMenuTNC {

    name: string;

    fromJson(json: any): FooterMenuTNC {
        const ret = Object.assign(new FooterMenuTNC(), json);
        return ret;
    }

    listFromJson(json: Array<any>): FooterMenuTNC[] {
        return json.map((i: any) => this.fromJson(i));
    }

    toJson() {
        return {
            name: this.name,
        };
    }
}

export class FooterMenuSetting {

    description: string = "";
    facebook: string = "";
    instagram: string = "";
    whatsapp: string = "";
    address: string = "";
    phone: string = "";
    email: string = "";


    fromJson(json: any): FooterMenuSetting {
        const ret = Object.assign(new FooterMenuSetting(), json);
        return ret;
    }

    listFromJson(json: Array<any>): FooterMenuSetting[] {
        return json.map((i: any) => this.fromJson(i));
    }

    toJson() {
        return {
            description: this.description,
            facebook: this.facebook,
            instagram: this.instagram,
            whatsapp: this.whatsapp,
            address: this.address,
            phone: this.phone,
            email: this.email,
        };
    }
}