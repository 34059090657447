import './genericmodel';

export class Address implements GenericModel {

    id: string;
    name: string;
    phone: string;
    company: string;
    address_1: string;
    address_2: string;
    address_3: string;
    city: string;
    postcode: string;
    state: string;
    country: string;
    types: [];
    updated_at: Date;
    created_at: Date;

    public get frontAddress(): string {
        return `${this.address_1 ?? ""} ${this.address_2 ?? ""} ${this.address_3 ?? ""}`;
    }

    public get fullAddress(): string {
        return `${this.frontAddress} ${this.postcode}, ${this.city}, ${this.state}, ${this.country}`;
    }


    fromJson(json: any): Address {
        const ret = Object.assign(new Address(), json);
        ret.id = json._id;
        return ret;
    }

    listFromJson(json: Array<any>): Address[] {
        return json.map((i: any) => this.fromJson(i));
    }

    toJson() {
        return {
            id: this.id,
            name: this.name,
            phone: this.phone,
            company: this.company,
            address_1: this.address_1,
            address_2: this.address_2,
            address_3: this.address_3,
            city: this.city,
            postcode: this.postcode,
            state: this.state,
            country: this.country,
            types: this.types,
        };
    }
}

export class SetAddressDefault implements GenericModel {
    id: string;
    billing: string;
    shipping: string;
    updated_at: Date;
    created_at: Date;

    fromJson(json: any): SetAddressDefault {
        const ret = Object.assign(new SetAddressDefault(), json);
        ret.id = json._id;
        return ret;
    }

    listFromJson(json: Array<any>): SetAddressDefault[] {
        return json.map((i: any) => this.fromJson(i));
    }

    toJson() {
        return {
            id: this.id,
            billing: this.billing,
            shipping: this.shipping,

        };
    }
}
