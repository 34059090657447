import { Component, OnInit, Input } from '@angular/core';
import { Product } from 'projects/shared/models/product';
import { CartItem } from 'projects/shared/models/cart';
import { CartProvider } from '../../../services/cart.service';

@Component({
  selector: 'app-cart-table-row',
  templateUrl: './cart-table-row.component.html',
  styleUrls: ['./cart-table-row.component.scss']
})
export class CartTableRowComponent implements OnInit {

  @Input() item: CartItem;

  constructor(private cartProvider: CartProvider) { }

  ngOnInit(): void {
  }

  del() {
    this.cartProvider.removeItem(this.item);
  }

  dec() {
    this.item.quantity = this.item.quantity - 1;
    if (this.item.quantity < 1) { this.item.quantity = 1; }
    this.cartProvider.saveChanges();
  }

  inc() {
    this.item.quantity = this.item.quantity + 1;
    this.cartProvider.saveChanges();
  }

  rmdisc(v) {
    if (!v.includes("%")) {
      v = 'RM ' + parseInt(v).toFixed(2);
    };
    return v;
  }

}
