import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StarComponent } from './star.component';
import { RatingComponent } from './rating.component';

@NgModule({
  imports: [CommonModule],
  declarations: [StarComponent, RatingComponent],
  exports: [StarComponent, RatingComponent]
})
export class RatingModule { }
