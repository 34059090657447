<div [formGroup]="parentForm">
  <div class="uk-form-horizontal">
    <div class="uk-margin">
      <label class="uk-form-label uk-text-normal" for="name">Name <span class="uk-text-danger">*</span></label>
      <div class="uk-form-controls">
        <input name="name" formControlName="name" class="uk-input" [required]=required>
        <div class="uk-text-muted">Recipient name</div>
      </div>
    </div>
    <div class="uk-margin">
      <label class="uk-form-label uk-text-normal" for="phone">Phone No <span class="uk-text-danger">*</span></label>
      <div class="uk-form-controls">
        <input name="phone" formControlName="phone" class="uk-input" [required]="required" placeholder="0123456789">
        <div *ngIf="parentForm.controls.phone.invalid; else phoneSubtitle" class="uk-text-danger">Invalid Format,
          Numbers only</div>
        <ng-template #phoneSubtitle>
          <div class="uk-text-muted">Number to call when we
            deliver</div>
        </ng-template>
      </div>
    </div>
    <div class="uk-margin">
      <label class="uk-form-label uk-text-normal" for="company">Building Name</label>
      <div class="uk-form-controls">
        <input name="company" formControlName="company" class="uk-input">
        <div class="uk-text-muted">Office name / Building name ...</div>
      </div>
    </div>
    <div class="uk-margin">
      <label class="uk-form-label uk-text-normal" for="address_1">Address 1 <span class="uk-text-danger">*</span></label>
      <div class="uk-form-controls">
        <input name="address_1" formControlName="address_1" class="uk-input" [required]=required>
      </div>
    </div>
    <div class="uk-margin">
      <label class="uk-form-label uk-text-normal" for="address_2">Address 2</label>
      <div class="uk-form-controls">
        <input name="address_2" formControlName="address_2" class="uk-input">
      </div>
    </div>
    <div class="uk-margin">
      <label class="uk-form-label uk-text-normal" for="city">Postcode <span class="uk-text-danger">*</span></label>
      <div class="uk-form-controls">
        <input name="postcode" formControlName="postcode" class="uk-input" [required]=required>
      </div>
    </div>
    <div class="uk-margin">
      <label class="uk-form-label uk-text-normal" for="city">City <span class="uk-text-danger">*</span></label>
      <div class="uk-form-controls">
        <input name="city" formControlName="city" class="uk-input" [required]=required>
      </div>
    </div>
    <div class="uk-margin">
      <label class="uk-form-label uk-text-normal" for="state">State <span class="uk-text-danger">*</span></label>
      <div class="uk-form-controls">
        <input name="state" formControlName="state" class="uk-input" [required]=required>
      </div>
    </div>
    <div class="uk-margin">
      <label class="uk-form-label uk-text-normal" for="country">Country <span class="uk-text-danger">*</span></label>
      <div class="uk-form-controls">
        <select name="country" formControlName="country" class="uk-select" [required]=required (change)="shippingCountry($event.target.value)">
          <option value="">Please Select</option>
          <option *ngFor="let fee of shippingFees" [value]="fee.id">
            {{ fee.id }}
          </option>
        </select>
      </div>
    </div>
  </div>
</div>
