import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { News } from 'projects/shared/models/news';
import { BrowserTitle } from 'projects/shared/services/utilities.provider';
import { ApiProvider } from '../../../services/api.service';

@Component({
  selector: 'app-news-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class NewsPageComponent implements OnInit {
  url: string;
  news: News;
  constructor(private route: ActivatedRoute, private api: ApiProvider, private browserTitle: BrowserTitle) {
    // change the behavior for wildcard route
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    // strip leading '/'
    const id = this.route.snapshot.paramMap.get('id');
    this.api.getNews(id).then((result) => {
      this.news = result;
      this.browserTitle.setTitle(this.news.title);
    });
  }

  ngOnInit() {

  }

}
