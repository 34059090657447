<section *ngIf="product">
  <div class="uk-container uk-margin-small-top">
    <div class="uk-flex uk-flex-center">
      <div>
        <ul class="uk-breadcrumb">
          <li class="item"><a [routerLink]="['/']">Home</a></li>
          <li class="item"><a [routerLink]="['/shop']">Shop</a></li>
          <li class="item"><span>{{product.category}}</span></li>
          <li class="item"><span>{{product.title}}</span></li>
        </ul>
      </div>
    </div>
    <div class="uk-child-width-1 uk-child-width-1-2@m uk-grid-small uk-grid" data-uk-grid>
      <div>
        <owl-carousel-o [options]="customOptions" #owlCar>
          <ng-container *ngFor="let slide of product.images; let i = index">
            <ng-template carouselSlide [id]="product.id + i">
              <img [src]="slide" alt="Product image">
            </ng-template>
          </ng-container>
        </owl-carousel-o>
        <ul class="uk-thumbnav uk-margin-small">
          <li *ngFor="let img of product.images; let j = index">
            <a class="uk-cover-container" (click)="owlCar.to(product.id + j)">
              <canvas width="46" height="46"></canvas>
              <img [src]="img" alt="{{product.title}}" uk-cover>
            </a>
          </li>
        </ul>
      </div>
      <div class="uk-margin-medium-bottom">
        <div *ngIf="promotionsLabel.length != 0">
          <div *ngFor="let p of promotionsLabel" class="promotion-detail uk-margin-small">
            <span *ngIf="p.type == 'normal' || p.type == 'mnm'">
              <div class="uk-margin-remove">
                <h5 class="uk-margin-remove-bottom uk-text-uppercase">{{p.name?p.name:'-'}}</h5>
                <p class="uk-margin-remove">{{p.description?p.description:'-'}}</p>
                <!-- : {{rmdisc(p.discount)}} Off -->
              </div>
            </span>
          </div>
          <hr class="hr-dotted" />
        </div>
        <h4 class="shop-title uk-margin-remove">{{product.title}}</h4>
        <h3 class="shop-price uk-margin-remove-top">
          {{product.price|price_format}}
          <div class="uk-text-danger text-xs"
            *ngIf="selectedSku != null && selectedSku.price != selectedSku.original_price else promotionprice">
            <del>{{selectedSku.original_price|price_format}}</del>
          </div>
          <ng-template #promotionprice>
            <span class="uk-text-danger text-xs" *ngIf="product.skus[0].original_price != product.price">
              <del>{{product.skus[0].original_price|price_format}}</del>
            </span>
          </ng-template>
        </h3>
        <div class="uk-margin-small-bottom">
          <span *ngIf="product.skus.length>0">

            <div class="uk-flex uk-flex-middle uk-grid-divider uk-grid-small" style="font-size: 11pt;" uk-grid>
              <div>
                <span class="iconify" data-icon="mdi:barcode-scan" data-inline="false"></span><span
                  *ngIf="selectedSku">&nbsp;&nbsp;{{selectedSku.sku || '-'}}</span>
              </div>
              <div>
                <span class="uk-text-success"><span class="iconify" data-icon="gridicons:product"
                    data-inline="false"></span><span *ngIf="selectedSku">&nbsp;&nbsp;Stock
                    {{selectedSku.max}}</span></span>
              </div>
              <div *ngIf="dimensiontable">
                <!-- <h5 class="uk-margin-small uk-text-bolder uk-text-success" *ngIf="!product.options.sizes">Free Size</h5> -->
                <a class="uk-margin" uk-toggle="target: #size-guide; animation: uk-animation-fade"><span class="iconify"
                    data-icon="wpf:ruler" data-inline="false"></span>&nbsp;&nbsp;<u>Size Guide</u></a>
                <div id="size-guide" uk-modal>
                  <div class="uk-modal-dialog uk-modal-body">
                    <button class="uk-modal-close-default" type="button" uk-close></button>
                    <h2 class="uk-modal-title">Size Guide</h2>
                    <div class="uk-overflow-auto">
                      <table class="uk-table uk-table-small text-xs uk-table-striped">
                        <tr *ngFor="let row of dimensiontable">
                          <td *ngFor="let col of row">{{col}}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </span>
        </div>
        <h5 *ngIf="product.isOutOfStock" class="uk-margin-remove-top uk-text-danger">SOLD OUT<br>商品已下架</h5>
        <div id="shop-colors" *ngIf="product.skus.length>1 && !product.isOutOfStock">
          <hr class="hr-dotted" />
          <h5 class="uk-margin-remove-top uk-text-bolder">Please select the option:</h5>
          <div class="uk-grid uk-grid-small">
            <div *ngFor="let sku of product.skus">
              <button class="shop-item-color" [class]="selectedSku == sku ? 'uk-active':''" (click)="select_sku(sku)"
                [disabled]="sku.max <= 0">
                {{sku.colors}} {{sku.sizes}}
              </button>
            </div>
          </div>
          <!-- <div id="shop-colors" *ngIf="product.options.colors && product.options.colors.length > 0">
            <h5 class="uk-margin-remove uk-text-bolder">Color</h5>
            <span>
              <div class="uk-grid-match uk-grid-small uk-padding-remove" uk-grid>
                <div *ngFor="let item of product.options.colors">
                  <button class="shop-item-color" [class]="selectedColor == item ? 'uk-active':''" (click)="select_color(item)">
                    {{item}}
                  </button>
                </div>
              </div>
            </span>
          </div>
          <div id="shop-sizes" *ngIf="product.options.sizes && product.options.sizes.length > 0">
            <h5 class="uk-margin-remove-bottom uk-margin-medium-top uk-text-bolder">Size</h5>
            <span>
              <div class="uk-grid-match uk-grid-small uk-padding-remove uk-margin-small-bottom" uk-grid>
                <div *ngFor="let item of product.options.sizes" class="uk-margin-remove">
                  <button class="shop-item-size" [class]="selectedSize == item ? 'uk-active':''" (click)="select_size(item)" [class.disable]="activeSize && !activeSize.includes(item)">{{item}}</button>
                </div>
              </div>
            </span>
          </div> -->
        </div>
        <div *ngIf="!product.isOutOfStock" class="uk-clearfix">
          <div id="qty" class="uk-flex uk-margin-medium-top">
            <div class="uk-button-group uk-width-auto">
              <div class="uk-inline">
                <a class="uk-margin-small-right uk-margin-small-left" (click)="dec()"><span
                    uk-icon="icon: minus"></span></a>
                <input class="uk-input uk-form-width-xsmall shop-atc-input" type="text" value="{{quantity}}" disabled>
                <a class="uk-margin-small-right uk-margin-small-left" (click)="inc()"><span
                    uk-icon="icon: plus"></span></a>
              </div>
            </div>
            <button
              class="uk-button uk-text-bolder uk-margin-small-left uk-width-expand uk-button-secondary shop-atc-btn"
              (click)="add_to_cart($event)">
              Add to Cart
            </button>
          </div>
        </div>
        <section class="uk-section-small uk-padding-remove uk-margin-medium-top">
          <div class="uk-container">
            <ul class="uk-tab" uk-switcher="animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium">
              <li *ngIf="product.content" class="uk-active"><a href="">Description</a></li>
              <li><a href="">Delivery Info</a></li>
              <li *ngIf="reviews"><a href="">Reviews <small>({{reviewCount}})</small></a></li>
            </ul>
            <ul class="uk-switcher uk-margin">
              <li *ngIf="product.content" class="uk-active">
                <!-- <div *ngIf="product?.tags && product.tags.length > 0" class="uk-text-center">
                  <a class="uk-badge" *ngFor="let t of product.tags; let i = index" href="/shop?tags={{t}}">
                    <span class="iconify" data-icon="ei:tag" data-inline="false"></span> {{t}}
                  </a>
                </div> -->
                <read-more [text]="product?.content" [maxLength]="100" [showToggleButton]="true">
                </read-more>
              </li>
              <li>
                <span *ngIf="contentWebpage && contentWebpage.content" [innerHtml]="contentWebpage.content"></span>
              </li>
              <li *ngIf="reviews">
                <div *ngIf="reviewCount > 0;else noreview">
                  <article
                    class="uk-padding-small uk-comment uk-margin-small-bottom uk-comment-primary uk-visible-toggle"
                    *ngFor="let r of reviews; let reviews = index">
                    <header class="uk-comment-header uk-margin-remove">
                      <div class="uk-grid-small uk-flex-middle" uk-grid>
                        <!-- <div class="uk-width-auto">
                          <img class="uk-preserve-width uk-border-circle" src="{{r.avatar?r.avatar:'/assets/noimage.svg'}}" width="80" height="80" alt="">
                        </div> -->
                        <div class="uk-width-expand">
                          <div class="uk-flex">
                            <span *ngFor="let rating of rate" class="iconify" data-icon="ic:round-star"
                              data-inline="false" style="color: #e2a413;" data-width="10" data-height="10"></span>
                          </div>
                          <!-- <ngb-rating class="text-warning" [(rate)]="r.rate" max="5" [readonly]="true">
                          </ngb-rating> -->
                          <p class="uk-comment-body uk-margin-small uk-margin-remove-top">{{r.review}}</p>
                          <p class="uk-margin-remove text-xs">{{r.variants}}</p>
                          <div class="uk-clearfix text-xs">
                            <div class="uk-float-left">{{r.user}}</div>
                            <div class="uk-float-right">{{r.created_at}}</div>
                          </div>
                        </div>
                      </div>
                    </header>
                  </article>
                </div>
                <ng-template #noreview>
                  <div class="uk-text-center uk-flex uk-flex-center uk-flex-middle" uk-grid>
                    <img src="/assets/icons/Review-Greyscale.png" width="80" />
                    <p class="uk-padding-remove uk-margin-small-left">No review at the moment.</p>
                  </div>
                </ng-template>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
<section *ngIf="isBundle" class="uk-section-small uk-padding-small uk-margin-medium-top uk-background-primary">
  <div class="uk-container">
    <h3 class="uk-text-uppercase uk-text-center">Bundle selling products</h3>
    <!-- <div class="promotion-detail uk-alert-secondary uk-text-center" uk-alert>
        <div *ngFor="let bundle of bundlesBox">
          <span *ngFor="let sku of bundle.skus">
            {{sku.sku}} : {{bdl_total(bundle)}} <i>(Discount {{rmdisc(sku.promotion[0].discount)}})</i>
          </span>
        </div>
      </div> -->
    <div *ngFor="let bundle of bundlesBox" class="uk-flex-middle" uk-grid>
      <div class="uk-width-1 uk-width-2-3@m">
        <div *ngFor="let sku of bundle.skus" class="uk-child-width-1-3 uk-flex-center uk-grid-small uk-text-center"
          uk-grid>
          <div *ngFor="let item of bundle.items">
            <div class="uk-card" [routerLink]="['/shop', item.parent_id]">
              <div class="uk-card-media-top uk-margin-small-bottom">
                <img src="{{item.photo}}" [alt]="item.name">
              </div>
              <div class="uk-card-body uk-padding-remove">
                <!-- <h6 class="uk-text-meta">{{item.name}}</h6>
                  <p class="uk-text-meta uk-margin-remove-top"><del class="uk-text-danger">{{item.unit_price|price_format}}</del><b>{{bdl_discount(item.unit_price,item.discount)}}</b></p> -->
                <p class="uk-margin-remove">{{item.unit_price|price_format}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="uk-width-1 uk-width-1-3@m">
        <div class="uk-text-center uk-text-middle">
          <div *ngFor="let bundle of bundlesBox">
            <span *ngFor="let sku of bundle.skus">
              <div class="uk-h1">ONLY<br />{{bdl_total(bundle)}}</div>
              <p class="uk-text-lead uk-margin-remove uk-visible@m"><del
                  class="uk-text-danger">{{sku.original_price|price_format}}</del></p>
              <!-- {{bdl_saving(bundle)}} -->
              <p class="uk-margin-remove-top uk-margin-small-bottom">Buy and save upto
                {{rmdisc(sku.promotion[0].discount)}}</p>
            </span>
          </div>
          <button class="uk-button uk-button-secondary" (click)="bundle_to_cart(bundle.id)">
            ADD ALL
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- <section class="uk-margin-top uk-section-small uk-padding-remove-bottom">
  <div class="uk-container">
    <h3 class="uk-heading uk-text-uppercase uk-text-center"><span>Customers Also Viewed</span></h3>
    <div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slider>
      <ul class="uk-slider-items uk-child-width-1-4 uk-child-width-1-5@s uk-child-width-1-6@m uk-flex-center uk-text-center uk-grid uk-grid-small">
        <li *ngFor="let item of list">
          <div>
            <div class="uk-text-left@s uk-text-center">
              <a class="uk-link-reset" href="">
                <div class="uk-inline-clip uk-transition-toggle uk-margin-small-bottom" tabindex="0">
                  <img src="https://media.lovebonito.com/media/catalog/product/h/y/hy5031-014_3.jpg" alt="">
                  <img class="uk-transition-scale-up uk-position-cover" src="https://media.lovebonito.com/media/catalog/product/h/y/hy5031-014-1_3.jpg?width=480&height=672&fit=bounds" alt="">
                </div>
                <div class="uk-text-secondary">RM123</div>
              </a>
            </div>
          </div>
        </li>
      </ul>
      <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slider-item="previous"><span uk-icon="arrow-left"></span></a>
      <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slider-item="next"><span uk-icon="arrow-right"></span></a>
    </div>
  </div>
</section> -->
<app-loading [loaded]="product"></app-loading>