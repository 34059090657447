import './genericmodel';

//note: get payment types from api

export class OnlinePaymentTypes {

    active: string;
    id: string;
    title: string;
    apikey: string;
    secret: string;
    remark: string;


    fromJson(json: any): OnlinePaymentTypes {
        const ret = Object.assign(new OnlinePaymentTypes(), json);
        return ret;
    }

    listFromJson(json: Array<any>): OnlinePaymentTypes[] {
        return json.map((i: any) => this.fromJson(i));
    }

    toJson() {
        return {
            active: this.active,
            id: this.id,
            title: this.title,
            apikey: this.apikey,
            secret: this.secret,
            remark: this.remark,

        };
    }

}

export class OfflinePaymentTypes {

    active: string;
    id: string;
    title: string;
    number: string;
    remark: string;


    fromJson(json: any): OfflinePaymentTypes {
        const ret = Object.assign(new OfflinePaymentTypes(), json);
        return ret;
    }

    listFromJson(json: Array<any>): OfflinePaymentTypes[] {
        return json.map((i: any) => this.fromJson(i));
    }

    toJson() {
        return {
            active: this.active,
            id: this.id,
            title: this.title,
            number: this.number,
            remark: this.remark,

        };
    }

}