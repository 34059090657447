<a href="#" uk-totop uk-scroll></a>
<!-- <a *ngIf="footer.setting && footer.setting.whatsapp" href="{{footer.setting.whatsapp}}" class="whatsapp" target="_blank"><img src="assets/icons/Whatsapp.png" /> Whatsapp
  Us</a> -->
<footer class="uk-section-small uk-dark">
  <div class="uk-container">
    <div class="uk-child-width-1-4@m" uk-grid>
      <div *ngIf="footer.setting">
        <a [routerLink]="['/']" routerLinkActive="active"><img src="assets/logo.png" width="100" uk-img></a><br />
        <p>{{footer.setting.description}}</p>
        <ul class="uk-iconnav">
          <li *ngIf="this.footer.setting.facebook"><a href="{{this.footer.setting.facebook}}" target="_blank"><span
                class="iconify" data-icon="bx:bxl-facebook-circle" data-width="30" data-inline="false"></span></a>
          </li>
          <li *ngIf="this.footer.setting.instagram"><a href="{{this.footer.setting.instagram}}" target="_blank"><span
                class="iconify" data-icon="uil:instagram-alt" data-width="30" data-inline="false"></span></a></li>
          <li *ngIf="this.footer.setting.whatsapp"><a href="{{this.footer.setting.whatsapp}}" target="_blank"><span
                class="iconify" data-icon="ion:logo-whatsapp" data-width="30" data-inline="false"></span></a></li>
        </ul>
      </div>
      <div>
        <h5>INFORMATION</h5>
        <ul class="uk-list">
          <li [class.uk-active]="isActive(item.url)" *ngFor="let item of infommenu.items"><a
              href="{{item.url}}">{{item.title}}</a></li>
        </ul>
      </div>
      <div>
        <h5>CUSTOMER CARE</h5>
        <ul class="uk-list">
          <li [class.uk-active]="isActive(item.url)" *ngFor="let item of servicemmenu.items"><a
              href="{{item.url}}">{{item.title}}</a></li>
        </ul>
      </div>
      <div *ngIf="this.footer != null && this.footer.setting != null">
        <h5>CONTACT INFO</h5>
        <p *ngIf="this.footer.setting.address" style="white-space: pre-line">{{this.footer.setting.address}}</p>
        <p *ngIf="this.footer.setting.phone"><span uk-icon="receiver"></span> {{this.footer.setting.phone}}</p>
        <p *ngIf="this.footer.setting.email"><span uk-icon="mail"></span>{{this.footer.setting.email}}</p>
      </div>
      <!-- <div *ngIf="footer.tag">
        <h5>SHOP BY TAGS</h5>
        <ul class="uk-list">
          <li *ngFor="let tag of footer.tag">
            <a (click)="navClick(tag.name,'tag')" style="text-transform: capitalize;" [class.uk-active]="isActive(tag.name)">{{tag.name}}</a>
          </li>
        </ul>
      </div> -->
      <!-- <div *ngIf="footer.category">
        <h5>SHOP BY CATEGORY </h5>
        <ul class="uk-list">
          <li *ngFor="let cat of footer.category">
            <a (click)="navClick(cat.name,'cat')" [class.uk-active]="isActive(cat.name)">{{cat.name}}</a>
          </li>
        </ul>
      </div>
      <div>
        <h5>SERVICES </h5>
        <ul class="uk-list">
          <li [class.uk-active]="isActive(item.url)" *ngFor="let item of servicemmenu.items"><a href="{{item.url}}">{{item.title}}</a></li>
        </ul>
      </div> -->
      <div>
        <div [innerHtml]="copyright">{{copyright}}</div>
      </div>
    </div>
  </div>
</footer>