import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from 'projects/shared/components/basic-dialogs.component';
import { Address, SetAddressDefault } from 'projects/shared/models/address';
import { AccountProvider } from 'projects/shared/services/account.provider';
import { ToastService } from 'projects/shared/services/toast.provider';
import { BrowserTitle } from 'projects/shared/services/utilities.provider';
import { ApiProvider } from '../../../services/api.service';

@Component({
  selector: 'app-addressbook',
  templateUrl: './addressbook.component.html',
  styleUrls: ['./addressbook.component.scss']
})
export class AddressbookComponent implements OnInit {

  addresses: Address[];
  showTypes = true;
  chooseTypes = false;
  addressset: SetAddressDefault = new SetAddressDefault();

  constructor(
    public account: AccountProvider,
    title: BrowserTitle,
    private modal: NgbModal,
    private api: ApiProvider,
    private toast: ToastService,
  ) {
    title.setTitle('Address Book');

    this.api.getAddresses().then((results) => {
      this.addresses = results;
    });
  }


  ngOnInit(): void {
  }

  toggleShowTypes() {
    this.showTypes = !this.showTypes;
  }
  toggleChooseTypes() {
    this.chooseTypes = !this.chooseTypes;
  }

  onDelete(item: Address) {
    ConfirmDialogComponent.show(this.modal, 'Delete', 'Are you sure?').then((result) => {
      if (result === true) {
        this.api.deleteAddress(item.id).then((ret) => {
          // remove from memory
          const idx = this.addresses.indexOf(item);
          this.addresses.splice(idx, 1);
          if (ret.message) {
            this.toast.show(ret.message, { classname: 'text-light bg-success' });
          }
        }, (response) => {
          if (response.error.error) {
            this.toast.show(response.error.error, { classname: 'bg-warning' });
          } else {
            this.toast.show(response.message, { classname: 'bg-warning' });
          }
        });
      }
    });
  }

  chooseD() {
    this.toggleShowTypes();
    this.toggleChooseTypes();

    document.forms['saveTypes']['user_id'].value = this.account.currentUser.id;

    if (this.addresses) {
      this.addresses.forEach((p) => {
        p.types.filter((t) => {
          if (t === 'shipping') {
            document.forms['saveTypes']['shipping'].value = p.id;
          }
          if (t === 'billing') {
            document.forms['saveTypes']['billing'].value = p.id;
          }
        });
      });
    }

  }

  chooseThisTypes(e) {
    if (e.target.dataset.type === 'shipping') {
      document.forms['saveTypes']['shipping'].value = e.target.dataset.id;
    }
    if (e.target.dataset.type === 'billing') {
      document.forms['saveTypes']['billing'].value = e.target.dataset.id;
    }
  }
  saveTypes(f) {
    this.addressset.id = f.target['user_id'].value;
    this.addressset.shipping = f.target['shipping'].value;
    this.addressset.billing = f.target['billing'].value;

    this.api.postAddressesDefault(this.addressset).then((result) => {
      this.api.getAddresses().then((results) => {
        this.addresses = results;

      });
      this.toggleShowTypes();
      this.toggleChooseTypes();
      this.toast.show('Address Updated', { classname: 'text-light bg-success' });
    });
  }


}
