import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastService } from 'projects/shared/services/toast.provider';
import { CartProvider } from '../../../services/cart.service';

@Component({
  selector: 'app-cart-checkout-menu',
  templateUrl: './cart-checkout-menu.component.html',
  styleUrls: ['./cart-checkout-menu.component.scss']
})
export class CartCheckoutMenuComponent implements OnInit {

  constructor(public cart: CartProvider, private toast: ToastService) {
  }

  ngOnInit(): void {
  }

}
