import { environment } from '../environments/environment';

// define any app constants here
export const Constants = Object.assign({
  APP_NAME: 'Vintage Concept',
  APP_VERSION: '1.0.0',
  API_KEY: 'Your-Api-Key',
  API_BASEURL: '/api',
  SITE_URL: 'https://vintageconcept.my/',
}, environment);
