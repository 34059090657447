import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomPipes } from 'projects/shared/pipes/custom.pipe';
import { ComponentsModuleModule } from '../../components/components.module';
import { AddressbookComponent } from './addressbook/addressbook.component';
import { AddressbookEditComponent } from './addressbook/edit-address/edit-address.component';
import { CreditsComponent } from './credits/credits.component';
import { ProfileEditComponent } from './edit-profile/edit-profile.component';
import { MemberPointsComponent } from './member-points/member-points.component';
import { OrderItemComponent } from './orders/order-item/order-item.component';
import { OrderShowComponent } from './orders/order-show/order-show.component';
import { OrderTileComponent } from './orders/order-tile/order-tile.component';
import { OrdersComponent } from './orders/orders.component';
import { ProfileMenuComponent } from './profile-menu/profile-menu.component';
import { ProfileComponent } from './profile/profile.component';
import { UserRoutingModule } from './user-routing.module';
import { UserComponent } from './user.component';
import { VouchersComponent } from './vouchers/vouchers.component';
import { WishlistComponent } from './wishlist/wishlist.component';


@NgModule({
  declarations: [
    UserComponent,
    ProfileMenuComponent,
    ProfileComponent,
    AddressbookComponent,
    VouchersComponent,
    WishlistComponent,
    OrdersComponent,
    ProfileEditComponent,
    OrderShowComponent,
    AddressbookEditComponent,
    OrderItemComponent,
    OrderTileComponent,
    CreditsComponent,
    MemberPointsComponent,
  ],
  imports: [
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CustomPipes,
    ComponentsModuleModule,
    CommonModule,
    UserRoutingModule,
  ],
})
export class UserModule { }
