import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from 'projects/shared/components/basic-dialogs.component';
import { WishlistItem } from 'projects/shared/models/wishlist';
import { ToastService } from 'projects/shared/services/toast.provider';
import { WishlistProvider } from '../../services/wishlist.service';

@Component({
  selector: 'app-wishlist-tile',
  templateUrl: './wishlist-tile.component.html',
  styleUrls: ['./wishlist-tile.component.scss']
})
export class WishlistTileComponent implements OnInit {

  @Input() item: WishlistItem;

  constructor(private wishlist: WishlistProvider, private toast: ToastService, private modal: NgbModal) { }

  ngOnInit(): void {
  }

  remove_from_wishlist(event) {
    ConfirmDialogComponent.show(this.modal, 'Remove From Wishlist', 'Are you sure?').then((result) => {
      if (result === true) {
        this.wishlist.removeWishlistItem(this.item).then((ret) => {
          if (ret.message) {
            this.toast.show(ret.message, { classname: ret.success ? 'text-light bg-success' : 'text-light bg-danger' });
          }
        });
      }
    });
    event.stopPropagation();
  }

}
