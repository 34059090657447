import './genericmodel';


export class ApiResult implements GenericModel {

    id: string;
    message: string;
    updated_at: Date;
    created_at: Date;


    fromJson(json: any): ApiResult {
        const ret = Object.assign(new ApiResult(), json);
        return ret;
    }
    listFromJson(json: Array<ApiResult>): ApiResult[] {
        return json.map((i) => this.fromJson(i));
    }
    toJson() {
        return {
        };
    }
}

