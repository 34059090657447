import './genericmodel';
import { ReviewItem } from './reviewitem';

export class CmsReview implements GenericModel {

    id: string;
    review_items: ReviewItem[];
    billing_id: string;
    user_id: string;
    updated_at: Date;
    created_at: Date;

    fromJson(json: any): CmsReview {
        const ret = Object.assign(new CmsReview(), json);
        ret.id = json._id;
        return ret;
    }

    listFromJson(json: Array<any>): CmsReview[] {
        return json.map((i: any) => this.fromJson(i));
    }

    toJson() {
        return {
            id: this.id,
            billing_id: this.billing_id,
            user_id: this.user_id,
            review_items: this.review_items,
        };
    }
}
