import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomPipes } from 'projects/shared/pipes/custom.pipe';
import { ComponentsModuleModule } from '../../components/components.module';
import { PaymentReturnComponent } from './payment-return/payment-return.component';
import { PaymentRoutingModule } from './payment-routing.module';
import { PaymentWithTokenComponent } from './payment-with-token/payment-with-token.component';
import { OrderItemComponent } from './order-item/order-item.component';
import { PaymentReceived } from '../../pages/payment-received/payment-received.component';

@NgModule({
  declarations: [
    PaymentReturnComponent,
    PaymentWithTokenComponent,
    OrderItemComponent,
    PaymentReceived
  ],
  imports: [
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CustomPipes,
    CommonModule,
    ComponentsModuleModule,
    PaymentRoutingModule,
  ],
})
export class PaymentModule { }
