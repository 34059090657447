import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthGuard, SignedInGuard } from 'projects/shared/guards/auth-guard.guard';
import { CustomPipes } from 'projects/shared/pipes/custom.pipe';
import { AccountProvider, AccountEvents } from 'projects/shared/services/account.provider';
import { HttpProvider } from 'projects/shared/services/http.provider';
import { ToastService } from 'projects/shared/services/toast.provider';
import { BrowserTitle, SharedPrefs } from 'projects/shared/services/utilities.provider';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModuleModule } from './components/components.module';
import { Constants } from './constants';
import { HomeComponent } from './pages/home/home.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { WebpageComponent } from './pages/webpage/webpage.component';
import { ApiProvider } from './services/api.service';
import { CartProvider } from './services/cart.service';
import { WishlistProvider } from './services/wishlist.service';
import { MomentModule } from 'ngx-moment';
import { FacebookModule } from 'ngx-facebook';
import { TrackParcelComponent } from './pages/track-parcel/track-parcel.component';


export function init_app(account: AccountProvider, cart: CartProvider, wishlist: WishlistProvider) {

  return () => account.refreshTokenAndUser().then(() => {
    account.broadcast.subscribe((message) => {
      console.log('Received Event', message);
      if (message === AccountEvents.Login) {
        cart.updateCart();
        //wishlist.loadWishlist();
      }
    });

    if (account.isLoggedIn) {
      cart.updateCart();
      //wishlist.loadWishlist();
    }
  });
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PageNotFoundComponent,
    WebpageComponent,
    TrackParcelComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    CustomPipes,
    ComponentsModuleModule,
    AppRoutingModule,
    MomentModule,
    FacebookModule.forRoot()
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: init_app, deps: [AccountProvider, CartProvider, WishlistProvider], multi: true },
    { provide: 'appname', useValue: Constants.APP_NAME },
    BrowserTitle,
    { provide: 'baseUrl', useValue: Constants.API_BASEURL },
    { provide: 'apiKey', useValue: Constants.API_KEY },
    { provide: 'siteUrl', useValue: Constants.SITE_URL },
    HttpProvider,
    AccountProvider,
    AuthGuard, SignedInGuard,
    SharedPrefs,
    CartProvider,
    WishlistProvider,
    ApiProvider,
    { provide: 'api', useExisting: ApiProvider },
    ToastService,
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
