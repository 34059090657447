import { Component, Input, OnInit } from '@angular/core';
import { CartItem } from 'projects/shared/models/cart';

@Component({
  selector: 'app-order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.scss']
})
export class OrderItemComponent implements OnInit {

  @Input() item: CartItem;

  constructor() { }

  ngOnInit(): void {
  }

}
