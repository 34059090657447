import { Inject, OnInit, Injector } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountProvider } from 'projects/shared/services/account.provider';
import { ToastService } from 'projects/shared/services/toast.provider';
import { BrowserTitle } from 'projects/shared/services/utilities.provider';

export class BaseLoginComponent implements OnInit {

  showpassword = false;
  redir: string;
  loginForm: FormGroup;

  router: Router;
  activatedRoute: ActivatedRoute;
  account: AccountProvider;
  modal: NgbModal;
  browserTitle: BrowserTitle;
  toast: ToastService;
  fb: FormBuilder;
  appname: string;

  constructor(injector: Injector) {
    this.router = injector.get(Router);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.account = injector.get(AccountProvider);
    this.modal = injector.get(NgbModal);
    this.browserTitle = injector.get(BrowserTitle);
    this.toast = injector.get(ToastService);
    this.fb = injector.get(FormBuilder);
    this.appname = injector.get('appname');
    this.browserTitle.setTitle('Login');

    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });

    this.activatedRoute.queryParams.subscribe(params => {
      if (params.redir) {
        this.redir = params.redir;
      }
      if (this.account.isLoggedIn) {
        this.afterLogin();
      }
    });
  }

  ngOnInit(): void {
  }

  login() {
    this.account.login(this.loginForm.value).then(() => {
      this.afterLogin();
    })
      .catch((response) => {
        this.toast.show(response.error.error, { classname: 'bg-warning' });
        // MessageDialogComponent.show(this.modal, 'Login Error', error.error.error);
      });
  }

  afterLogin() {
    if (this.redir) {
      this.router.navigate([this.redir]);
    } else {
      this.router.navigate(['/']);
    }
  }
}

export class BaseForgotPasswordComponent implements OnInit {

  sent = false;
  error = '';
  resetForm: FormGroup;
  account: AccountProvider;
  browserTitle: BrowserTitle;
  toast: ToastService;
  fb: FormBuilder;
  appname: string;

  constructor(injector: Injector) {
    this.account = injector.get(AccountProvider);
    this.browserTitle = injector.get(BrowserTitle);
    this.toast = injector.get(ToastService);
    this.fb = injector.get(FormBuilder);
    this.appname = injector.get('appname');
    this.browserTitle.setTitle('Forgot Password');

    this.resetForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {
  }

  send() {
    this.error = '';
    this.account.forgotpassword(this.resetForm.value).then((ret) => {
      this.sent = true;
      this.toast.show(ret.message, { classname: 'text-light bg-success' });
    })
      .catch((response) => {
        if (response.error.error) {
          this.toast.show(response.error.error, { classname: 'bg-warning' });
        } else {
          this.toast.show(response.message, { classname: 'bg-warning' });
        }
      });
  }
}

export class BaseResetPasswordComponent implements OnInit {

  showpassword = false;
  error = '';
  resetForm: FormGroup;
  router: Router;
  activatedRoute: ActivatedRoute;
  account: AccountProvider;
  browserTitle: BrowserTitle;
  toast: ToastService;
  fb: FormBuilder;
  appname: string;

  constructor(injector: Injector) {
    this.router = injector.get(Router);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.account = injector.get(AccountProvider);
    this.browserTitle = injector.get(BrowserTitle);
    this.toast = injector.get(ToastService);
    this.fb = injector.get(FormBuilder);
    this.appname = injector.get('appname');
    this.browserTitle.setTitle('Reset Password');

    this.resetForm = this.fb.group({
      token: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.resetForm.patchValue(params);
    });

  }

  ngOnInit(): void {
  }

  reset() {
    this.error = '';
    this.account.resetpassword(this.resetForm.value).then((ret) => {
      this.toast.show(ret.message, { classname: 'text-light bg-success' });
      this.afterReset();
    })
      .catch((response) => {
        if (response.error.error) {
          this.toast.show(response.error.error, { classname: 'bg-warning' });
        } else {
          this.toast.show(response.message, { classname: 'bg-warning' });
        }
      });
  }

  afterReset() {
    this.router.navigate(['/auth', 'login']);
  }
}
