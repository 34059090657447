import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Order } from 'projects/shared/models/order';
import { ToastService } from 'projects/shared/services/toast.provider';
import { BrowserTitle } from 'projects/shared/services/utilities.provider';
import { ApiProvider } from 'projects/web/src/app/services/api.service';
import { CmsReview } from 'projects/shared/models/CmsReview';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ReviewItem } from 'projects/shared/models/reviewitem';

@Component({
  selector: 'app-order-show',
  templateUrl: './order-show.component.html',
  styleUrls: ['./order-show.component.scss']
})
export class OrderShowComponent implements OnInit {

  refno: string;
  order: Order;
  reviewItems = new Map<string, ReviewItem>();
  status: string;
  paymentForm: FormGroup;
  file: File;
  validation = { paymentproof: '' };
  notAllOpen = false;
  editableReview: boolean = false;

  saveDisabled = false;
  submitDisabled = false;


  constructor(
    private route: ActivatedRoute,
    private api: ApiProvider,
    private toast: ToastService,
    private title: BrowserTitle,
    private router: Router,
    private fb: FormBuilder,
  ) {
    this.refno = this.route.snapshot.paramMap.get('refno');
    this.status = this.route.snapshot.queryParamMap.get('status');
    this.title.setTitle('Order #' + this.refno);
    this.api.getOrder(this.refno).then((result) => {
      this.order = result;
      this.buildReviewItem();
      this.checkNotAllOpen();
    });

    this.paymentForm = this.fb.group({
      paymentproof: ['']
    });
  }

  ngOnInit(): void {
  }

  checkNotAllOpen() {
    for (let index = 0; index < this.order.items.length; index++) {
      if (!this.order.items[index].is_open) {
        this.notAllOpen = true;
        return false;
      }
    }
  }

  ratingVoted(value: number, productId: string) {
    this.reviewItems.get(productId).rate = value;
  }

  buildReviewItem() {
    //Can review
    if (this.order.isReview) {
      this.editableReview = true;
      this.order.items.forEach((v) => {
        let item = new ReviewItem();
        item.product_id = v.product_id;
        item.review = "";
        item.rate = 0;
        item.images = [];
        item.file_images = [];
        this.reviewItems.set(v.product_id, item);
      });
    }
  }

  canSubmitreview() {
    for (let [key, value] of this.reviewItems) {
      if (value.review == "" || value.rate == 0) return false;
    }
    return true;
  }

  canPay() {
    return !this.order.paid && this.order.payment.method !== 'cod';
  }

  pay() {
    if (this.order.token) {
      this.router.navigate(['/payment', '_t', this.order.token]);
    } else {
      this.toast.show('Error: Please contact site admin for manual payment.', { classname: 'text-light bg-warning' });
    }
  }
  uploadProof() {
    this.saveDisabled = true;

    const fd = new FormData();
    fd.append('payment_method', this.order.payment_method);
    fd.append('mode', this.order.payment_mode);
    fd.append('proof', this.file ? this.file : null);
    this.api.payTokenLink(this.order.id, fd).then((order) => {
      // this.router.navigate(['/user', 'orders', order.refno]);
      // window.location.reload();
      this.saveDisabled = false;
      if (order.payment_mode === 'offline') {
        this.router.navigate(['/payment', 'received', order.refno]);
      } else {
        window.location.href = order.checkout_url;
      }
    }).catch((e) => {
      this.saveDisabled = false;
    });
  }

  submitreview() {
    this.submitDisabled = true;

    let body = new CmsReview();
    body.review_items = [...this.reviewItems.values()];
    body.billing_id = this.order.id;
    body.user_id = this.order.user_id;
    let formData = this._createFormData(body);

    this.api.postReview(formData).then((response) => {
      this.toast.show('Thank you for taking the time to leave us a rating.', { classname: 'text-light bg-success' });
      window.location.reload();
    }).catch((e) => {
      this.toast.show('Error: Fail to update your review.', { classname: 'text-light bg-warning' });
      this.submitDisabled = false;
    });
  }

  uploadFile(filelist: FileList) {
    this.file = filelist.item(0);
    // check image size
    if (this.file.size > (1024 * 1024) * 10) {
      this.toast.show(this.file.name + ' is too large (' + (this.file.size / 1024 / 1024).toFixed(1) + ' MB)', { classname: 'uk-text-light uk-background-danger' });
      return;
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      this.paymentForm.patchValue({ paymentproof: event.target.result.toString() });
    };
    reader.readAsDataURL(this.file);
  }

  checktype(img) {
    const pdf = 'assets/pdf.svg';
    const ext = img.split('.').pop();
    if (ext === 'pdf') { return pdf; } else {
      return img;
    }
  }

  _createFormData(object: Object, form?: FormData, namespace?: string): FormData {
    const formData = form || new FormData();
    for (let property in object) {
      if (!object.hasOwnProperty(property)) {
        continue;
      }
      const formKey = namespace ? `${namespace}[${property}]` : property;
      if (object[property] instanceof Date) {
        formData.append(formKey, object[property].toISOString());
      } else if (typeof object[property] === 'object' && !(object[property] instanceof File)) {
        this._createFormData(object[property], formData, formKey);
      } else {
        formData.append(formKey, object[property]);
      }
    }
    return formData;
  }

  editReview() {
    this.editableReview = true;
  }
}
