import { Component, Input, Output, EventEmitter, HostBinding, HostListener } from '@angular/core';

@Component({
  selector: 'app-star',
  styles: [`
    :host {
      flex: .1;
      text-align: center;
      font-size: 16pt;
      color: rgba(0,0,0,.5);
      cursor: pointer;
    }
    :host(.active) {
      color: gold;
    }
  `],
  template: `★`
})
export class StarComponent {

  @Input() @HostBinding('class.active') active;
  @Output() setActive = new EventEmitter();

  @HostListener('click') emit() { this.setActive.emit(); }
}
