import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthModule } from './modules/auth/auth.module';
import { CartModule } from './modules/cart/cart.module';
import { ShopModule } from './modules/shop/shop.module';
import { UserModule } from './modules/user/user.module';
import { HomeComponent } from './pages/home/home.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { WebpageComponent } from './pages/webpage/webpage.component';
// import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { NewsModule } from './modules/news/news.module';
import { PaymentModule } from './modules/payment/payment.module';
import { TrackParcelComponent } from './pages/track-parcel/track-parcel.component';

const routes: Routes = [

  // system pages

  { path: '', component: HomeComponent },
  // { path: 'contact-us', component: ContactUsComponent },
  { path: 'track-parcel', component: TrackParcelComponent },
  { path: '404-not-found', component: PageNotFoundComponent },

  { path: 'cart', loadChildren: () => CartModule },
  { path: 'user', loadChildren: () => UserModule },
  { path: 'auth', loadChildren: () => AuthModule },
  { path: 'shop', loadChildren: () => ShopModule },
  { path: 'news', loadChildren: () => NewsModule },
  { path: 'payment', loadChildren: () => PaymentModule },
  // route to webpage module
  { path: '**', component: WebpageComponent },
];


const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 80],
  // enableTracing: true,
};

@NgModule({
  imports: [
    RouterModule.forRoot(routes, routerOptions),
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
