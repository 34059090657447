import { Component, OnInit } from '@angular/core';
import { MemberPointLog } from 'projects/shared/models/memberpoints';

@Component({
  selector: 'app-member-points',
  templateUrl: './member-points.component.html',
  styleUrls: ['./member-points.component.scss']
})
export class MemberPointsComponent implements OnInit {

  memberpointlogs: MemberPointLog[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
