<div>
  <div>
    <!-- <span class="uk-position-top-right uk-badge" [ngClass]="item.statusclass">{{item.status | titlecase}}</span> -->
    <ul class="uk-margin-remove-top uk-subnav uk-subnav-divider">
      <li><span></span>Date: {{item.created_at | date_display}}</li>
      <li><span></span>Order: <a [routerLink]="['/user', 'orders', item.refno]">#{{item.refno}}</a></li>
      <li *ngIf="item.delivery_at">
        <span></span>Tracking no.: #<a href="//track.aftership.com/{{item.delivery_trackingno}}" target="_blank">{{item.delivery_trackingno}}</a>
      </li>
      <li>Status: <span class="uk-label" style="color:#fff !important;margin-left: .5em;" [ngClass]="item.statusclass">{{item.status | titlecase}}</span></li>
      <li>Balance: {{item.balance|price_format}}</li>
    </ul>
    <!-- <div class="small">
      <div><span class="text-muted mr-2">{{item.created_at | date_display}}</span> Order Received</div>
      <div *ngIf="item.paid">
        <span class="text-muted mr-2">{{item.paid_at | date_display}}</span> <span
          class="badge badge-success">Paid</span>
      </div>
      <div *ngIf="item.processed_at">
        <span class="text-muted mr-2">{{item.processed_at | date_display}}</span> Processed
      </div>
      <div *ngIf="item.delivery_at">
        <span class="text-muted mr-2">{{item.delivery_at | date_display}}</span> Shipped out (<span class="iconify mr-2"
          data-icon="mdi-truck-delivery"></span> <a href="//track.aftership.com/{{item.delivery_trackingno}}"
          target="_blank">#{{item.delivery_trackingno}}</a>)
      </div>
      <div *ngIf="item.completed_at" class="text-success">
        <span class="text-muted mr-2">{{item.completed_at | date_display}}</span> Complete
      </div>
      <div *ngIf="item.cancelled_at" class="text-danger">
        <span class="text-muted mr-2">{{item.cancelled_at | date_display}}</span> Cancel
      </div>
    </div> -->
  </div>


  <div class="uk-alert uk-alert-success" *ngIf="item.isReview">
    <p>
      We welcome and appreciate your reviews! <span uk-icon="comment"></span><a [routerLink]="['/user', 'orders', item.refno]"> review now.</a>
    </p>
  </div>
  <div *ngIf="!item.paid && item.balance && item.payment_mode == 'offline'" class="uk-alert-danger" uk-alert>
    <a class="uk-alert-close" uk-close></a>
    <p>Please upload <a [routerLink]="['/user', 'orders', item.refno]"><b>Proof of Payment</b></a> for Offline Payments.<br />Otherwise please make payment by re-select a payment method.</p>
  </div>
  <a [attr.uk-toggle]="'target: #order-items-'+item.id+'; animation: uk-animation-fade'">Show order items</a>

  <ul [id]="'order-items-'+item.id" class="uk-list uk-margin-remove-bottom" hidden>
    <li *ngFor="let item of item.items">
      <app-order-item [item]="item"></app-order-item>
    </li>
  </ul>

</div>
