import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomPipes } from 'projects/shared/pipes/custom.pipe';
import { ComponentsModuleModule } from '../../components/components.module';
import { CartCheckoutMenuComponent } from './cart-checkout-menu/cart-checkout-menu.component';
import { CartRoutingModule } from './cart-routing.module';
import { CartTableRowComponent } from './cart-table-row/cart-table-row.component';
import { CartTableComponent } from './cart-table/cart-table.component';
import { CartComponent } from './cart.component';
import { CheckoutComponent } from './checkout/checkout.component';

@NgModule({
  declarations: [
    CartComponent,
    CheckoutComponent,
    CartTableComponent,
    CartCheckoutMenuComponent,
    CartTableRowComponent,
  ],
  imports: [
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CustomPipes,
    CommonModule,
    ComponentsModuleModule,
    CartRoutingModule,
  ],
})
export class CartModule { }
