import './genericmodel';
import { Webpage } from './webpage';

export class News extends Webpage {
    fromJson(json: any): News {
        const ret = Object.assign(new News(), super.fromJson(json));
        return ret;
    }
}

