<section class="uk-container uk-section">
  <div class="uk-flex-center" uk-grid>
    <div class="uk-width-large uk-card uk-card-default uk-card-body">
      <h1 class="uk-margin-remove-bottom">Sign In</h1>
      <h6 class="uk-margin-remove-top">Please add your details.</h6>
      <form class="uk-margin-medium uk-form-horizontal" [formGroup]="loginForm" (submit)="login()">
        <div class="uk-margin">
          <!-- <label class="uk-form-label" for="email">Email</label> -->

          <input formControlName="email" class="uk-input" type="email" id="email" placeholder="Email" required>

        </div>
        <div class="uk-margin">
          <!-- <label class="uk-form-label" for="password">Password</label> -->

          <div class="uk-inline uk-width-expand">
            <a class="form-check uk-form-icon uk-form-icon-flip">
              <input type="checkbox" class="uk-checkbox form-check-input" placeholder="Password" (change)="showpassword=$event.target.checked" uk-toggle="target: #iconeye; cls: show" id="showpassword" hidden>
              <label id="iconeye" for="showpassword">
                <span class="iconify" data-icon="la:eye" data-inline="false" data-width="25" data-height="25"></span>
              </label>
            </a>
            <input formControlName="password" [type]="showpassword ? 'text' : 'password'" class="uk-input" id="password" placeholder="Password" required>
          </div>

        </div>
        <button type="submit" class="uk-button uk-button-secondary uk-width-1-1" [disabled]="!loginForm.valid">Login</button>
      </form>

      <p class="uk-text-center">
        <a [routerLink]="['/auth', 'register']" class="uk-link-heading"><u>Sign Up</u></a> |
        <a [routerLink]="['/auth', 'forgot-password']" class="uk-link-heading"><u>Forgot Password?</u></a>
      </p>
    </div>
  </div>
</section>
