<section class="uk-container uk-section">
  <div class="uk-flex-center" uk-grid>
    <div class="uk-width-large uk-card uk-card-default uk-card-body">
      <h1>Forgot Password</h1>

      <form class="uk-margin-medium uk-form-horizontal" [formGroup]="resetForm" (submit)="send()" *ngIf="!sent; else sentMessage">

        <div class="uk-margin">
          <!-- <label for="loginemail">Email</label> -->
          <input type="email" class="uk-input" id="loginemail" placeholder="Your email" formControlName="email" required>
          <div class="uk-text-danger">{{error}}</div>
        </div>


        <button type="submit" [disabled]="!resetForm.valid" class="uk-button uk-button-secondary uk-width-1-1">Reset Password</button>
      </form>
      <p class="uk-text-center">
        <a [routerLink]="['/auth', 'login']" class="uk-link-heading"><u>Login</u></a> |
        <a [routerLink]="['/auth', 'register']" class="uk-link-heading"><u>Register</u></a>
      </p>
      <ng-template #sentMessage>
        <div class="uk-text-center">
          Password reset instruction has been sent to your email. Please check email for further steps.
        </div>
      </ng-template>
    </div>
  </div>
</section>
