<div class="bg-color-7 uk-card-small">
  <div class="uk-card-body">
    <h4 class="uk-margin-bottom">Order Summary</h4>
    <ul uk-accordion>
      <li>
        <p>Total {{cart.content.size}} items</p>
        <div class="text-xs" style="background-color: #fff;;padding:1em 1em 0 0">
          <ul>
            <li *ngFor="let item of cart.content.items" style="padding-bottom:1em"><b>{{item.product_info.title}}</b> |
              <span>Sku: {{item.sku}}</span><br />
              <span class="uk-margin-small-left" *ngIf="item.options.size"> - Size: {{item.options.size}} <br /></span>
              <span class="uk-margin-small-left" *ngIf="item.options.color"> - Color: {{item.options.color}}</span>
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <div class="uk-grid-small text-xs" uk-grid>
      <div class="uk-width-expand" uk-leader>Subtotal</div>
      <div>{{cart.content.subtotal | price_format}}</div>
    </div>
    <div class="uk-grid-small text-xs" *ngIf="cart.content.promocode.promocode_amount" uk-grid>
      <div class="uk-width-expand" uk-leader>Promo code: <b>{{cart.content.promocode.code}}</b><!--  ({{cart.content.promocode.value}})-->
      </div>
      <div class="uk-text-danger">-{{cart.content.promocode.promocode_amount | price_format}}</div>
    </div>
    <div class="uk-grid-small text-xs" *ngIf="cart.content.credit_amount > 0" uk-grid>
      <div class="uk-width-expand" uk-leader>Credit</div>
      <div class="uk-text-danger">-{{cart.content.credit_amount | price_format}}</div>
    </div>
    <div class="uk-grid-small text-xs" *ngFor="let charges of cart.content.charges" uk-grid>
      <div class="uk-width-expand" uk-leader>{{charges.title}}</div>
      <div [class.uk-text-danger]="charges.amount<0">{{charges.amount | price_format}}</div>
    </div>
    <hr />
    <div class="uk-grid-small" uk-grid>
      <div class="uk-width-expand uk-text-bold">Total</div>
      <div class="uk-text-bold">{{cart.content.total | price_format}}</div>
    </div>
  </div>
</div>
