<div id="page-title">
  <h2>Profile Settings</h2>
</div>
<hr>
<form [formGroup]="profileForm" (submit)="update()" autocomplete="off">
  <div class="uk-form-stacked uk-grid-match uk-grid-small" uk-grid>
    <div class="uk-width-2-2">
      <div class="uk-margin">
        <img *ngIf="user.avatar" class="uk-border-circle uk-comment-avatar uk-margin-small-bottom" [src]="user.avatar" style="width: 50px; height: 50px;" />
        <label class="uk-form-label">Profile Photo</label>
        <div class="uk-form-controls">
          <div class="small uk-text-danger">{{validation.newavatar}}</div>
          <input hidden #fileInput type="file" (change)="uploadFile($event.target.files)" accept="image/jpeg,image/png">
          <button class="uk-button uk-button-default" (click)="fileInput.click()">Select File</button>
          <button *ngIf="profileForm.value.newavatar" class="uk-button uk-button-muted uk-margin-small-left" (click)="profileForm.value.newavatar=''">Clear</button>
          <div class="uk-margin-small" *ngIf="profileForm.value.newavatar">
            <img [src]="profileForm.value.newavatar" style="max-width: 100px; max-height: 100px;">
          </div>
        </div>
      </div>
    </div>
    <div class="uk-width-1-2">
      <div class="uk-margin">
        <label class="uk-form-label">Name <span class="uk-text-danger">*</span></label>
        <div class="uk-form-controls">
          <input class="uk-input" formControlName="name">
          <div class="small uk-text-danger">{{validation.name}}</div>
        </div>
      </div>
    </div>
    <div class="uk-width-1-2">
      <div class="uk-margin">
        <label class="uk-form-label">Email <span class="uk-text-danger">*</span></label>
        <div class="uk-form-controls">
          <input class="uk-input" formControlName="email" type="email">
          <div class="small uk-text-danger">{{validation.email}}</div>
        </div>
      </div>
    </div>
    <div class="uk-width-1-2">
      <div class="uk-margin">
        <label class="uk-form-label">Contact No <span class="uk-text-danger">*</span></label>
        <div class="uk-form-controls">
          <input class="uk-input" formControlName="phone" type="tel" autocomplete="mobile">
          <div class="small uk-text-danger">{{validation.phone}}</div>
        </div>
      </div>
    </div>
    <div class="uk-width-1-2">
      <div class="uk-margin">
        <label class="uk-form-label">Password</label>
        <div class="uk-form-controls">
          <div class="uk-inline uk-width-expand">
            <a class="form-check uk-form-icon uk-form-icon-flip">
              <input type="checkbox" class="uk-checkbox form-check-input" placeholder="Password" (change)="showpassword=$event.target.checked" uk-toggle="target: #iconeye; cls: show" id="showpassword" hidden>
              <label id="iconeye" for="showpassword">
                <span class="iconify" data-icon="la:eye" data-inline="false" data-width="25" data-height="25"></span>
              </label>
            </a>
            <input class="uk-input" formControlName="newpassword" placeholder="New Password" [type]="showpassword ? 'text' : 'password'" autocomplete="new-password">
          </div>
          <div class="uk-text-muted" *ngIf="!validation.newpassword">At least 8 characters</div>
          <div class="uk-text-danger">{{validation.newpassword}}</div>
        </div>
      </div>
    </div>
  </div>
  <button class="uk-margin uk-button uk-button-secondary" [disabled]="!profileForm.valid">Update</button>
  <div class="uk-margin uk-text-muted text-xs">
    <p>Updated At: {{user.updated_at|date:'short'}}
      <br />Created At: {{user.created_at|date:'short'}}</p>
  </div>
</form>
