import './genericmodel';

export class BannerItem {
    image: string;
    title: string;
    caption: string;
    url: string;

    fromJson(json: any): BannerItem {
        const ret = Object.assign(new BannerItem(), json);
        return ret;
    }

    listFromJson(json: Array<any>): BannerItem[] {
        return json.map((i: any) => this.fromJson(i));
    }

    toJson() {
        return {
            image: this.image,
            title: this.title,
            caption: this.caption,
            url: this.url,
        };
    }

}

export class Banner implements GenericModel {
    id: string;
    name: string;
    items: BannerItem[] = [];
    updated_at: Date;
    created_at: Date;

    fromJson(json: any): Banner {
        const ret = Object.assign(new Banner(), json);
        ret.id = json._id;
        ret.updated_at = new Date(json.updated_at);
        ret.created_at = new Date(json.created_at);
        ret.items = new BannerItem().listFromJson(json.items);
        return ret;
    }

    listFromJson(json: Array<any>): Banner[] {
        return json.map((i) => this.fromJson(i));
    }

    toJson() {
        return {
            id: this.id,
            name: this.name,
            items: this.items.map((i) => i.toJson()),
        };
    }

}

