<section class="uk-container">
  <div id="page-title" class="uk-text-center">
    <h2>Payment Received!</h2>
  </div>
  <hr />
  <div class="uk-text-center">

    <div class="uk-heading-large uk-text-success">
      <span class="iconify" data-icon="bx:bx-check-circle" data-inline="false"></span>
    </div>

    <p>You will receive more updates via Email. <a [routerLink]="['/user', 'orders', refno]">View your order</a></p>
  </div>
</section>
