<header>
  <nav id="top-menu" uk-navbar style="background-color: #f7f8fa;" uk-sticky>
    <div class="uk-navbar-left">
      <ul class="uk-navbar-nav">
        <li class="uk-active">
          <!-- <a href="#">Vintage <strong>Concept</strong></a> -->
          <a [routerLink]="['/']" routerLinkActive="active"><img src="assets/logo.png" width="100"></a>
        </li>
      </ul>
    </div>
    <div class="uk-navbar-right">
      <ul class="uk-visible@m uk-navbar-nav">
        <li *ngFor="let item of menu?.items">
          <a *ngIf="!item.items||item.items.length==0; else dropdownitem" href="{{item.url}}" [class.uk-active]="isActive(item.url)">{{item.title}}</a>
          <ng-template #dropdownitem>
            <a [class.uk-active]="isActive(item.url)">{{item.title}}</a>
            <div class="uk-navbar-dropdown uk-text-small uk-width-small uk-margin-remove" uk-drop>
              <div>
                <ul class="uk-nav uk-navbar-dropdown-nav">
                  <li *ngFor="let subitem of item?.items"><a (click)="navClick(subitem.url)" [class.uk-active]="isActive(subitem.url)">{{subitem.title}}</a></li>
                </ul>
              </div>
            </div>
          </ng-template>
        </li>
        <li>
          <a [routerLink]="['/shop']" [class.uk-active]="isActive('/shop')">Shops</a>
          <div class="uk-navbar-dropdown uk-margin-remove" uk-drop="boundary: !nav; boundary-align: true; pos: bottom-justify;offset: -10">
            <div class="uk-child-width-1-6@m uk-child-width-1-7@l" uk-grid>
              <div *ngFor="let item of categories">
                <ul class="uk-nav uk-navbar-dropdown-nav">
                  <li class="uk-active"><a [class.uk-active]="isActive(item.url)" (click)="navClick('shop#'+item.url)">{{item.label}}</a></li>
                  <li *ngFor="let subitem of item.children"><a (click)="router.navigateByUrl('shop#'+subitem.url)" [class.uk-active]="isActive(subitem.label)">{{subitem.label}}</a></li>
                </ul>
              </div>
              <div *ngFor="let item of tagsmenu.items">
                <ul class="uk-nav uk-navbar-dropdown-nav">
                  <li class="uk-active"><a [class.uk-active]="isActive(item.url)">{{item.title}}</a></li>
                  <li *ngFor="let subitem of item?.items"><a href="{{subitem.url}}" [class.active]="isActive(subitem.title)">{{subitem.title}}</a></li>
                </ul>
              </div>
            </div>
          </div>
        </li>
        <li><a href="" uk-icon="icon: user"></a>
          <div class=" uk-margin-remove" uk-dropdown>
            <div *ngIf="account.isLoggedIn; else LoginSignup">
              <ul class="uk-nav uk-dropdown-nav">
                <li><a (click)="navClick('/user')"><span class="uk-margin-small-right uk-icon" uk-icon="user"></span> My
                    Account</a></li>
                <li><a (click)="navClick('/user/orders')"><span class="uk-margin-small-right uk-icon" uk-icon="file-text"></span> My Orders</a></li>
                <li><a (click)="navClick('/user/editprofile')"><span class="uk-margin-small-right uk-icon" uk-icon="pencil"></span> Edit Profile</a></li>
                <li><a (click)="navClick('/user/addressbook')"><span class="uk-margin-small-right uk-icon" uk-icon="home"></span> Address Book</a></li>
                <li class="uk-nav-divider"></li>
                <li><a (click)="logout()"><span class="uk-margin-small-right uk-icon" uk-icon="sign-out"></span>
                    Logout</a></li>
              </ul>
            </div>
            <ng-template #LoginSignup>
              <div>
                <ul class="uk-nav uk-dropdown-nav">
                  <li><a (click)="navClick('/auth/login')"><span class="uk-margin-small-right uk-icon" uk-icon="sign-in"></span>Login</a></li>
                  <li><a (click)="navClick('/auth/register')"><span class="uk-margin-small-right uk-icon" uk-icon="file-edit"></span>Sign Up</a></li>
                </ul>
              </div>
            </ng-template>
          </div>
        </li>
        <li><a [routerLink]="['/cart']" routerLinkActive="active"><span class="uk-margin-small-right" uk-icon="icon: cart"></span> <span class="cart-total-item-badge"
              [hidden]="cart.content.size==0">{{cart.content.size||'0'}}</span></a>
          <div class="uk-navbar-dropdown uk-text-small uk-padding-remove-bottom uk-width-large uk-margin-remove" uk-drop>
            <div *ngIf="cart.content.size > 0; else cartEmpty">
              <h5>Your Bag</h5>
              <hr>
              <div *ngFor="let item of cart.content.items" class="uk-grid-small" uk-grid>
                <img [src]="item.product_info.image" width="50" height="50">
                <div class="uk-width-expand text-xs">{{item.product_info.title}}<br />
                  <span *ngIf="item.options.size" class="uk-margin-medium-right">Size: {{item.options.size}}</span>
                  <span *ngIf="item.options.color">Color: {{item.options.color}} </span>
                </div>
                <div class="text-xs">{{item.price|price_format}} x {{item.quantity}} </div>
              </div>
              <hr />
              <div class="uk-grid-small text-xs" *ngFor="let charges of cart.content.charges" uk-grid>
                <div class="uk-width-expand" uk-leader>{{charges.title}}</div>
                <div [class.uk-text-danger]="charges.amount<0">{{charges.amount | price_format}}</div>
              </div>
              <div class="uk-grid-small uk-margin-bottom" uk-grid>
                <div class="uk-width-expand" uk-leader><b>Subtotal</b></div>
                <div><b>{{cart.content.subtotal | price_format}}</b></div>
              </div>
              <div class="uk-clearfix">
                <a class="uk-button uk-button-default uk-align-left" [routerLink]="['/cart']">View Cart</a>
                <a class="uk-button uk-button-primary uk-align-right" [routerLink]="['/cart/checkout']">Checkout</a>
              </div>
            </div>
            <ng-template #cartEmpty>
              <div class="uk-text-center">
                <p>Your shopping bag is empty.<br />Start shopping now!</p>
                <!-- <div class="uk-grid-small uk-child-width-auto uk-text-center" uk-grid>
                  <div>
                    <a class="uk-button uk-button-text" href="#">New In</a>
                  </div>
                  <div>
                    <a class="uk-button uk-button-text" href="#">Dresses</a>
                  </div>
                </div> -->
              </div>
            </ng-template>
          </div>
        </li>
      </ul>
      <a href="#" uk-icon="icon: menu" class="uk-hidden@m uk-navbar-toggle" uk-toggle="target: #sidenav"></a>
      <a [routerLink]="['/cart']" class="uk-hidden@m uk-margin-small-right uk-link-reset"><span uk-icon="icon: cart"></span><span class="cart-total-item-badge"
          [hidden]="cart.content.size==0">{{cart.content.size||'0'}}</span></a>
    </div>
  </nav>
</header>
<div id="sidenav" uk-offcanvas="mode: push; overlay: true; flip: true">
  <div class="uk-offcanvas-bar">
    <button class="uk-offcanvas-close" type="button" uk-close></button>
    <ul class="uk-margin uk-nav-default uk-nav-parent-icon" uk-nav>
      <li *ngFor="let item of menu?.items">
        <a *ngIf="!item.items||item.items.length==0; else Mobiledropdownitem" href="{{item.url}}" routerLinkActive="active">{{item.title}}</a>
        <ng-template #Mobiledropdownitem>
          <a [class.active]="isActive(item.url)">{{item.title}}</a>
          <ul class="uk-nav-sub">
            <li *ngFor="let subitem of item?.items"><a (click)="navClick(subitem.url)" [class.active]="isActive(subitem.url)">{{subitem.title}}</a></li>
          </ul>
        </ng-template>
      </li>
      <li class="uk-nav-header">Shops</li>
      <li><a [routerLink]="['/shop']">All Products</a></li>
      <li class="uk-parent" *ngFor="let item of categories">
        <a [class.active]="isActive(item.url)" (click)="navClick('shop#'+item.url)">{{item.label}}</a>
        <ul class="uk-nav-sub">
          <li *ngFor="let subitem of item.children"><a (click)="router.navigateByUrl('shop#'+subitem.url)" [class.active]="isActive(subitem.label)">{{subitem.label}}</a></li>
        </ul>
      </li>
      <li class="uk-nav-header">Deals</li>
      <li class="uk-parent" [class.uk-active]="isActive(item.url)" *ngFor="let item of tagsmenu.items">
        <a>{{item.title}}</a>
        <ul class="uk-nav-sub">
          <li *ngFor="let subitem of item?.items"><a href="{{subitem.url}}" [class.active]="isActive(subitem.title)">{{subitem.title}}</a></li>
        </ul>
      </li>
      <li class="uk-nav-divider"></li>
      <li class="uk-nav-header">Account</li>

      <ng-template [ngIf]="account.isLoggedIn" [ngIfElse]="MobileLoginSignup">
        <li><a (click)="navClick('/user')"><span class="uk-margin-small-right uk-icon" uk-icon="user"></span> My
            Account</a></li>
        <li><a (click)="navClick('/user/orders')"><span class="uk-margin-small-right uk-icon" uk-icon="file-text"></span> My Orders</a></li>
        <li><a (click)="navClick('/user/editprofile')"><span class="uk-margin-small-right uk-icon" uk-icon="pencil"></span> Edit Profile</a></li>
        <li><a (click)="navClick('/user/addressbook')"><span class="uk-margin-small-right uk-icon" uk-icon="home"></span> Address Book</a></li>
        <li class="uk-nav-divider"></li>
        <li><a (click)="logout()"><span class="uk-margin-small-right uk-icon" uk-icon="sign-out"></span> Logout</a></li>
      </ng-template>

      <ng-template #MobileLoginSignup>
        <li><a (click)="navClick('/auth/login')"><span class="uk-margin-small-right uk-icon" uk-icon="sign-in"></span>Login</a></li>
        <li><a (click)="navClick('/auth/register')"><span class="uk-margin-small-right uk-icon" uk-icon="file-edit"></span>Sign Up</a></li>
      </ng-template>
    </ul>
  </div>
</div>