import { Component, OnInit } from '@angular/core';
import { BrowserTitle } from 'projects/shared/services/utilities.provider';
import { ApiProvider } from '../../services/api.service';
import { Product } from 'projects/shared/models/product';
import { SessionStorageService } from 'angular-web-storage';
import { Webpage } from 'projects/shared/models/webpage';
import { News } from 'projects/shared/models/news';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
// import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  // providers: [NgbModalConfig, NgbModal]
})

export class HomeComponent implements OnInit {
  weeklySpecials: Product[] = [];
  featuredProducts: Product[] = [];
  hotProducts: Product[] = [];
  promoProducts: Product[] = [];
  newProducts: Product[] = [];
  contentWebpage: Webpage;
  homeVideoHtml: SafeHtml;
  // contentVideoWebpage: Webpage;
  news: News[] = [];

  // open(content) {
  //   this.modalService.open(content);
  // }

  constructor(
    // config: NgbModalConfig,
    // private modalService: NgbModal,
    private browserTitle: BrowserTitle,
    private api: ApiProvider,
    private cache: SessionStorageService,
    private sanitizer: DomSanitizer
  ) {
    // config.backdrop = 'static';
    // config.keyboard = false;
    this.browserTitle.setTitle('Home');
    this.cacheAndReloadProducts();
    // this.api.getWebpage('home-body').then((ret) => {
    //   this.contentWebpage = ret;
    // });
    this.api.getWebpage('home-video').then((ret) => {
      this.contentWebpage = ret;
      this.homeVideoHtml = this.sanitizer.bypassSecurityTrustHtml(this.contentWebpage.content);
    }, () => { });
  }

  cacheAndReloadProducts() {
    this.weeklySpecials = this.cache.get('home.special') || [];
    this.featuredProducts = this.cache.get('home.featured') || [];
    this.hotProducts = this.cache.get('home.hot') || [];
    this.promoProducts = this.cache.get('home.promo') || [];
    this.news = this.cache.get('home.news') || [];

    this.api.getProducts({ tags: ['weekly specials'] }).then((results) => {
      this.weeklySpecials = results;
      this.cache.set('home.special', results);
    });

    this.api.getProducts({ tags: ['featured'] }).then((results) => {
      this.featuredProducts = results;
      this.cache.set('home.featured', results);
    });

    this.api.getProducts({ tags: ['hot'] }).then((results) => {
      this.hotProducts = results;
      this.cache.set('home.hot', results);
    });
    this.api.getProducts({ tags: ['promo'] }).then((results) => {
      this.promoProducts = results;
      this.cache.set('home.promo', results);
    });

    this.api.getProducts({ tags: ['new'] }).then((results) => {
      this.newProducts = results;
      this.cache.set('home.new', results);
    });

    this.api.getAllNews({ tags: ['new'], limit: 6 }).then((results) => {
      this.news = results;
      this.cache.set('home.news', results);
    });
  }

  ngOnInit() {
  }

}
