import { Component, OnInit } from '@angular/core';
import { CreditLog } from 'projects/shared/models/credits';

@Component({
  selector: 'app-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.scss']
})
export class CreditsComponent implements OnInit {

  creditlogs: CreditLog[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
