import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NewsComponent } from './index/index.component';
import { NewsPageComponent } from './page/page.component';

const routes: Routes = [
  { path: '', component: NewsComponent },
  { path: ':id/:slug', component: NewsPageComponent },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
})
export class NewsRoutingModule { }
