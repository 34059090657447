<div id="page-title">
  <h2>My Orders <small class="uk-badge" *ngIf="which">{{which | titlecase}}</small></h2>
</div>
<hr />
<ul class="uk-flex-center" uk-tab>
  <li>
    <a [routerLink]="['/user', 'orders']" fragment="new">New Orders</a>
  </li>
  <li>
    <a [routerLink]="['/user', 'orders']" fragment="processing">Processing</a>
  </li>
  <li>
    <a [routerLink]="['/user', 'orders']" fragment="shipping">Shipping</a>
  </li>
  <li>
    <a [routerLink]="['/user', 'orders']" fragment="complete">Completed</a>
  </li>
  <li>
    <a [routerLink]="['/user', 'orders']" fragment="review">Needs Review</a>
  </li>
</ul>
<app-loading [loaded]="orders"></app-loading>
<div *ngIf="orders">
  <p class="uk-text-muted" *ngIf="orders.length==0; else note">Order is Empty.</p>
  <ng-template #note>
    <p class="uk-text-muted">Showing orders up to the past 3 months</p>
  </ng-template>

  <div *ngFor="let order of orders | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize" class="uk-margin uk-card uk-card-small uk-card-default uk-card-body">
    <app-order-tile [item]="order"></app-order-tile>
  </div>

  <ngb-pagination *ngIf="orders.length > pageSize" class="d-flex justify-content-center" [(page)]="page" [pageSize]="pageSize" [collectionSize]="orders.length"></ngb-pagination>
</div>
