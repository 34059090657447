<div class="uk-container uk-margin-small-top">
  <div *ngIf="cart.content.size > 0; else cartEmpty">
    <div id="page-title" class="uk-text-center">
      <h1>Shopping Cart</h1>
    </div>

    <div uk-grid>
      <!-- <div class="row">
    <h6 class="uk-heading">My Shopping Cart</h6>
    <p class="text-muted">{{cart.content.size}} items</p>
  </div>
  <hr> -->
      <div class="uk-width-expand@m">
        <app-cart-table></app-cart-table>
        <button class="uk-button uk-button-muted" (click)="clear()">Clear All</button>
      </div>
      <div class="uk-width-large@m">
        <div uk-sticky="offset: 60;media: @m">
          <app-cart-checkout-menu></app-cart-checkout-menu>
          <div>
            <a class="uk-button uk-button-secondary uk-width-expand uk-margin-small-top" style="border-radius: 50px;" [routerLink]="['/cart','checkout']">Proceed to Checkout</a>
            <p class="uk-text-default text-xs uk-text-center">Delivery fee, Tax calculated and Apply a Coupon Code, on the next step.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #cartEmpty>
    <div class="uk-vertical-align uk-text-center">
      <span class="iconify" data-icon="noto-v1:shopping-cart" data-inline="false" data-width="100px" data-height="100px"></span>
      <p>Your cart is empty. Go do <a [routerLink]="['/shop']">some shopping</a>!</p>
    </div>
  </ng-template>
