import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Payment } from 'projects/shared/models/payment';
import { ToastService } from 'projects/shared/services/toast.provider';
import { BrowserTitle } from 'projects/shared/services/utilities.provider';
import { ApiProvider } from '../../../services/api.service';
import { CartProvider } from '../../../services/cart.service';
import { AccountProvider } from 'projects/shared/services/account.provider';

@Component({
  selector: 'app-payment-return',
  templateUrl: './payment-return.component.html',
  styleUrls: ['./payment-return.component.scss']
})
export class PaymentReturnComponent implements OnInit {

  refno: string;
  id: string;
  payment: Payment;

  constructor(
    private api: ApiProvider,
    private route: ActivatedRoute,
    public cart: CartProvider,
    private toast: ToastService,
    private router: Router,
    private title: BrowserTitle,
    private account: AccountProvider
  ) {
    this.refno = this.route.snapshot.paramMap.get('refno');
    this.id = this.route.snapshot.queryParamMap.get('billplz[id]');

    this.api.getOrderPaymentStatus(this.refno, this.id).then((payment) => {
      this.payment = payment;
      if (payment.success && !payment.with_token) {
        this.cart.clearCart();
      }
    });
  }

  ngOnInit(): void {
  }

}
