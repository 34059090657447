import './genericmodel';

import { Promo } from './sku';
import { Product } from './product';

export class PaymentMethod implements GenericModel {
    id: string;
    title: string;
    image: string;
    remark: string;
    updated_at: Date;
    created_at: Date;

    fromJson(json: any): PaymentMethod {
        const ret = Object.assign(new PaymentMethod(), json);
        return ret;
    }

    listFromJson(json: Array<any>): PaymentMethod[] {
        return json.map((i: any) => this.fromJson(i));
    }

    toJson() {
        return {};
    }
}

export class DeliveryMethod implements GenericModel {
    id: string;
    title: string;
    image: string;
    remark: string;
    updated_at: Date;
    created_at: Date;

    fromJson(json: any): DeliveryMethod {
        const ret = Object.assign(new DeliveryMethod(), json);
        return ret;
    }

    listFromJson(json: Array<any>): DeliveryMethod[] {
        return json.map((i: any) => this.fromJson(i));
    }

    toJson() {
        return {};
    }
}


export class CheckoutInfo {
    delivery: any;
    billing: any;
    same_as_delivery: boolean;
    payment_method: string;
    delivery_method: string;
    mode: string;
    proof: any;
    pickup: boolean;
    pickup_info: PickupInfo;

    fromJson(json: any): CheckoutInfo {
        const ret = Object.assign(new CheckoutInfo(), json);
        ret.delivery = json.delivery ? json.delivery : {};
        ret.billing = json.billing ? json.billing : {};
        return ret;
    }

    listFromJson(json: Array<any>): CheckoutInfo[] {
        return json.map((i: any) => this.fromJson(i));
    }

    toJson() {
        return {
            delivery: this.delivery,
            billing: this.billing,
            same_as_delivery: this.same_as_delivery,
            payment_method: this.payment_method,
            delivery_method: this.delivery_method,
            mode: this.mode,
            proof: this.proof,
            pickup: this.pickup,
            pickup_info: this.pickup_info,
        };
    }
}

export class PickupInfo {
    pickup_name: string;
    pickup_phone: string;
    pickup_message: string;

    fromJson(json: any): PickupInfo {
        const ret = Object.assign(new PickupInfo(), json);
        return ret;
    }

    listFromJson(json: Array<any>): PickupInfo[] {
        return json.map((i: any) => this.fromJson(i));
    }
    toJson() {
        return {
            pickup_name: this.pickup_name,
            pickup_phone: this.pickup_phone,
            pickup_message: this.pickup_message
        };
    }
}

export class CartItem {
    id: string;
    quantity: number;
    price: number;
    parent_id: string;
    product_id: string;
    sku: string;
    name: string
    product_info: any = {
        title: '', url: '', image: ''
    };
    options: any = {
        color: '', size: ''
    };
    review: any = {
        rating: '', review: ''
    };
    promotion: Promo;
    product: Product;
    photo: string;
    total: number;
    bundle: { bundle_id: any; discount: any; };
    is_open: number;

    fromJson(json: any): CartItem {
        const ret = Object.assign(new CartItem(), json);
        ret.id = json._id;
        ret.product = json.product;
        return ret;
    }

    listFromJson(json: Array<any>): CartItem[] {
        return json.map((i: any) => this.fromJson(i));
    }
    toJson() {
        return {
            sku: this.sku,
            quantity: this.quantity,
            price: this.price,
            options: this.options,
            product_info: this.product_info,
            parent_id: this.parent_id,
            product_id: this.product_id,
            promotion: this.promotion,
            product: this.product,
            photo: this.photo,
            total: this.total,
            bundle: this.bundle,
            name: this.name,
            is_open: this.is_open
        };
    }

    toRatingJson() {
        return {
            id: this.id,
            product_id: this.product_id,
            review: this.review,
        };
    }
}

export class CartCharges {
    title: string;
    amount: number;
    type: string;
    id: string;

    fromJson(json: any): CartCharges {
        const ret = Object.assign(new CartCharges(), json);
        return ret;
    }

    listFromJson(json: Array<any>): CartCharges[] {
        return json.map((i: any) => this.fromJson(i));
    }

    toJson() {
        return {
            title: this.title,
            amount: this.amount,
            type: this.type,
            id: this.id,
        };
    }
}

export class CartPromocode {
    code: string;
    value: string;
    promocode_amount: number;

    fromJson(json: any): CartPromocode {
        const ret = Object.assign(new CartPromocode(), json);
        return ret;
    }

    listFromJson(json: Array<any>): CartPromocode[] {
        return json.map((i: any) => this.fromJson(i));
    }

    toJson() {
        return {
            code: this.code,
            value: this.value,
            promocode_amount: this.promocode_amount,
        };
    }
}

export class Cart implements GenericModel {
    id = 'new';
    user_id: string;
    session_id: string;
    updated_at: Date;
    created_at: Date;
    items: CartItem[] = [];

    size: number;
    subtotal: number;
    charges: CartCharges[] = [];
    promocode: CartPromocode = new CartPromocode();
    credit_amount: number;

    total: number;

    checkout: CheckoutInfo = new CheckoutInfo();

    fromJson(json: any): Cart {
        const ret = Object.assign(new Cart(), json);
        ret.id = json._id;
        ret.updated_at = new Date(json.updated_at);
        ret.created_at = new Date(json.created_at);
        ret.items = json.items ? (new CartItem()).listFromJson(json.items) : [];
        ret.charges = json.charges ? (new CartCharges()).listFromJson(json.charges) : [];
        ret.checkout = json.checkout ? (new CheckoutInfo()).fromJson(json.checkout) : new CheckoutInfo();
        ret.promocode = json.promocode ? (new CartPromocode()).fromJson(json.promocode) : new CartPromocode();
        return ret;

    }

    listFromJson(json: Array<any>): Cart[] {
        return json.map((i) => this.fromJson(i));
    }

    toJson() {
        return {
            id: this.id,
            promocode: this.promocode,
            items: this.items.map((i) => i.toJson()),
            charges: this.charges.map((i) => i.toJson()),
            checkout: this.checkout.toJson(),
        };
    }
}


export class PaymentMode implements GenericModel {
    id: string;
    updated_at: Date;
    created_at: Date;
    mode: string;
    active: boolean;

    fromJson(json: any): PaymentMode {
        const ret = Object.assign(new PaymentMode(), json);
        ret.mode = json.mode;
        ret.active = json.active;
        return ret;
    }

    listFromJson(json: Array<any>): PaymentMode[] {
        return json.map((i: any) => this.fromJson(i));
    }

    toJson() {
        return {
            mode: this.mode,
            active: this.active,
        };
    }
}

export class OfflinePayments implements GenericModel {
    id: string;
    title: string;
    image: string;
    remark: string;
    updated_at: Date;
    created_at: Date;

    fromJson(json: any): OfflinePayments {
        const ret = Object.assign(new OfflinePayments(), json);
        return ret;
    }

    listFromJson(json: Array<any>): OfflinePayments[] {
        return json.map((i: any) => this.fromJson(i));
    }

    toJson() {
        return {};
    }
}

//test
export class CartPayments implements GenericModel {
    id: string;
    updated_at: Date;
    created_at: Date;
    mode: string;
    active: boolean;
    payments: PaymentMethod;

    fromJson(json: any): CartPayments {
        const ret = Object.assign(new CartPayments(), json);
        ret.mode = json.mode;
        ret.active = json.active;
        return ret;
    }

    listFromJson(json: Array<any>): PaymentMode[] {
        return json.map((i: any) => this.fromJson(i));
    }

    toJson() {
        return {
            mode: this.mode,
            active: this.active,
        };
    }
}
//test