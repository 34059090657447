<section class="uk-container uk-margin-small-top">
  <div uk-grid>
    <div class="uk-visible@m uk-width-medium@m">
      <app-profile-menu></app-profile-menu>
    </div>
    <div class="uk-width-expand@m">
      <router-outlet></router-outlet>
    </div>
  </div>
</section>
