<h5>Welcome, {{account.currentUser.name}}</h5>
<div id="sidebar" class="bg-color-2 uk-padding-small">

  <!-- <img src="{{account.currentUser.avatar}}" class="rounded align-self-center mr-3" width="40"
      alt="{{account.currentUser.name}}"> -->
  <!-- <div class="media-body">
      <p class="mb-0">Hi, {{account.currentUser.name}}</p>
    </div> -->
  <h4 class="uk-heading">Orders</h4>
  <ul class="uk-list">
    <li>
      <a class="uk-link-deco" [routerLink]="['/user']">Dashboard</a>
    </li>
    <li>
      <a class="uk-link-deco" [routerLink]="['/user', 'orders']">My Orders</a>
    </li>
    <!-- <li>
      <a class="uk-link-deco" [routerLink]="['/user', 'orders']" fragment="processing">Processing</a>
    </li>
    <li>
      <a class="uk-link-deco" [routerLink]="['/user', 'orders']" fragment="shipping">Shipping</a>
    </li>
    <li>
      <a class="uk-link-deco" [routerLink]="['/user', 'orders']" fragment="review">Needs Review</a>
    </li>
    <li>
      <a class="uk-link-deco" [routerLink]="['/user', 'orders']" fragment="complete">Completed</a>
    </li> -->
  </ul>
  <h4 class="uk-heading">Profile</h4>
  <ul class="uk-list">
    <li>
      <a class="uk-link-deco" [routerLink]="['/user', 'editprofile']">Edit Profile</a>
    </li>
    <li>
      <a class="uk-link-deco" [routerLink]="['/user', 'addressbook']">Address Book</a>
    </li>
  </ul>
</div>
