import { Component, OnInit } from '@angular/core';
import { AccountProvider } from 'projects/shared/services/account.provider';
import { Order } from 'projects/shared/models/order';
import { ApiProvider } from '../../../services/api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss']
})
export class ProfileMenuComponent implements OnInit {
  orders: Order[];
  which: string;

  constructor(
    public account: AccountProvider,
    private api: ApiProvider,
    private route: ActivatedRoute,
  ) {
    this.route.fragment.subscribe((f) => {
      this.api.getOrders(f).then((results) => {
        this.which = f;
        this.orders = results;
      });
    });
  }

  ngOnInit(): void {
  }

}
