import { EventEmitter, Component, OnInit, Input, Output } from '@angular/core';
import { Cart } from 'projects/shared/models/cart';
import { CartProvider } from '../../../services/cart.service';

@Component({
  selector: 'app-cart-table',
  templateUrl: './cart-table.component.html',
  styleUrls: ['./cart-table.component.scss']
})
export class CartTableComponent implements OnInit {

  constructor(public cart: CartProvider) {
  }

  ngOnInit(): void {
  }

}
