import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent, MessageDialogComponent } from 'projects/shared/components/basic-dialogs.component';
import { LoadingComponent } from 'projects/shared/components/loading.component';
import { ProfileDashboardComponent } from 'projects/shared/components/profile-dashboard';
import { TagsInputComponent } from 'projects/shared/components/tags-input.component';
import { CustomPipes } from 'projects/shared/pipes/custom.pipe';
import { AddressFormComponent } from './address-form/address-form.component';
import { CarouselComponent } from './carousel/carousel.component';
import { FooterComponent } from './footer/footer.component';
import { ProductTileComponent } from './product-tile/product-tile.component';
import { TopMenuComponent } from './top-menu/top-menu.component';
import { WishlistTileComponent } from './wishlist-tile/wishlist-tile.component';
import { ReadMoreComponent } from './read-more/read-more.component';
import { RatingModule } from './rating/rating.module';


@NgModule({
  declarations: [
    AddressFormComponent,
    CarouselComponent,
    FooterComponent,
    ProductTileComponent,
    TopMenuComponent,
    LoadingComponent,
    MessageDialogComponent,
    ConfirmDialogComponent,
    TagsInputComponent,
    ProfileDashboardComponent,
    WishlistTileComponent,
    ReadMoreComponent,
  ],
  exports: [
    AddressFormComponent,
    CarouselComponent,
    FooterComponent,
    ProductTileComponent,
    TopMenuComponent,
    LoadingComponent,
    MessageDialogComponent,
    ConfirmDialogComponent,
    TagsInputComponent,
    ProfileDashboardComponent,
    WishlistTileComponent,
    ReadMoreComponent,
    RatingModule
  ],
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CustomPipes,
    CommonModule,
  ]
})
export class ComponentsModuleModule { }
