// user account singleton

import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiProvider } from 'projects/web/src/app/services/api.service';
import { Subject } from 'rxjs';
import { User } from '../models/user';
import { HttpProvider } from './http.provider';
import { SharedPrefs } from './utilities.provider';

export enum AccountEvents {
    Login, UserUpdated, Logout
}

@Injectable({ providedIn: 'root' })
export class AccountProvider {



    public constructor(private sharedprefs: SharedPrefs, private http: HttpProvider, @Inject('api') private api: ApiProvider, public router: Router) {

        const acc = this.sharedprefs.get('account.user');
        if (acc) {
            this.setUser(new User().fromJson(JSON.parse(acc)));
        }

        this.accessToken = this.sharedprefs.get('account.token');
        if (this.accessToken) {
            this.http.accessToken = this.accessToken;
        }
    }


    isAdmin: boolean = false;
    isLoggedIn = false;
    currentUser: User = new User();
    accessToken: string;

    /*
      example of subscriber

      this.account.broadcast.subscribe((message) => {
        console.log('a message from space', message);
        });
    */
    broadcast = new Subject<AccountEvents>();

    // cannot simply refresh token (will cause other API to fail)
    refreshTokenAndUser(): Promise<any> {
        if (this.accessToken) {
            return this.refreshToken().then(() => {
                return this.updateuser();
            }).catch((e) => {
                //Fail redirect back homepage
                this.router.navigate(['/']);
            });
        } else {
            return new Promise((resolve) => resolve());
        }
    }

    private setUser(user: User) {

        this.currentUser = user;
        this.isLoggedIn = true;
        if (user.roles.includes('admin')) this.isAdmin = true;
        this.broadcast.next(AccountEvents.UserUpdated);
    }

    private unset() {
        this.sharedprefs.remove('account.user');
        this.sharedprefs.remove('account.token');
        this.accessToken = null;
        this.http.accessToken = null;
        this.currentUser = new User();
        this.isLoggedIn = false;
    }

    updateuser(): Promise<User> {
        return this.api.getMe().then((u) => {
            this.setUser(u);
            this.sharedprefs.set('account.user', JSON.stringify(u));
            return u;
        });
    }

    register(json: any): Promise<any> {
        return this.api.register(json).then((t) => {
            if (!t || !t.token) {
                throw new Error('invalid_return');
            }
            this.accessToken = t.token;
            this.http.accessToken = this.accessToken;
            this.sharedprefs.set('account.token', t.token);
            this.broadcast.next(AccountEvents.Login);

            return this.updateuser();
        });
    }

    login(json: any): Promise<any> {
        return this.api.login(json).then((t) => {
            if (!t || !t.token) {
                throw new Error('invalid_return');
            }
            this.accessToken = t.token;
            this.http.accessToken = this.accessToken;
            this.sharedprefs.set('account.token', t.token);
            this.broadcast.next(AccountEvents.Login);

            return this.updateuser();
        });
    }

    logout(): Promise<any> {
        return this.api.logout().finally(() => {
            this.unset();
            this.broadcast.next(AccountEvents.Logout);
        });
    }

    refreshToken() {
        return this.api.refreshToken().then((t) => {
            if (!t || !t.token) {
                throw new Error('invalid_token');
            }
            this.accessToken = t.token;
            this.http.accessToken = this.accessToken;
            this.sharedprefs.set('account.token', t.token);
        }).catch((e) => {
            this.unset();
            this.broadcast.next(AccountEvents.Logout);
            throw (e);
        });
    }

    forgotpassword(json: any): Promise<any> {
        return this.api.forgotpassword(json);
    }

    resetpassword(json: any): Promise<any> {
        return this.api.resetpassword(json);
    }

    updateProfile(json: any) {
        return this.api.updateprofile(json).then((u) => {
            this.setUser(u);
            this.sharedprefs.set('account.user', JSON.stringify(u));
        }).catch((e) => {
            if (e.status === 401) {
                this.unset();
            }
            throw (e);
        });
    }


}
