// sample api singleton

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class HttpProvider {

    accessToken: string;

    constructor(private http: HttpClient,
        private injector: Injector,
        @Inject('apiKey') private apiKey: string,
        @Inject('baseUrl') private baseUrl: string,
    ) {
    }

    getData(url: string, headers?: HttpHeaders) {
        return this.http.get(this.createUrl(url), { headers: headers || this.createRequestHeader() });
    }

    postData(url: string, data: any, headers?: HttpHeaders) {
        return this.http.post(this.createUrl(url),
            JSON.stringify(data),
            { headers: headers || this.createRequestHeader() });
    }

    postForm(url: string, data: FormData, headers?: HttpHeaders) {
        return this.http.post(this.createUrl(url), data, { headers: headers || this.createRequestHeader() });
    }

    putData(url: string, data: any, headers?: HttpHeaders) {
        return this.http.put(this.createUrl(url), JSON.stringify(data), { headers: headers || this.createRequestHeader() });
    }

    deleteData(url: string, data: any, headers?: HttpHeaders) {
        return this.http.request('delete', this.createUrl(url), { headers: headers || this.createRequestHeader(), body: data });
    }

    private createRequestHeader() {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: '*/*',
            'X-API-Key': this.apiKey,
            Authorization: 'Bearer ' + this.accessToken,
        });
        return headers;
    }

    private createUrl(url: string) {
        return this.baseUrl + url;
    }

    // added for file uploads
    apiHeader(): any {
        return {
            // 'Content-Type': 'application/json',
            // Accept: '*/*',
            'X-API-Key': this.apiKey,
            Authorization: 'Bearer ' + this.accessToken,
        };
    }
    fileUploadUrl(url: string): string {
        return this.baseUrl + url;
    }

}
