import { Component, OnInit, Inject } from '@angular/core';
import { CartProvider } from '../../../services/cart.service';
import { ToastService } from 'projects/shared/services/toast.provider';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { BrowserTitle } from 'projects/shared/services/utilities.provider';
import { ApiProvider } from '../../../services/api.service';
import { User } from 'projects/shared/models/user';
import { AccountProvider } from 'projects/shared/services/account.provider';
import { MessageDialogComponent } from 'projects/shared/components/basic-dialogs.component';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  showpassword = false;

  signupForm = this.fb.group({
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(8)]],
    agreetnc: ['', Validators.required],
    // phone: ['',[ Validators.required, Validators.pattern('\\d{8,12}')]],
    // address: ['', Validators.required],
    // city: ['', Validators.required],
    // postcode: ['', Validators.required],
    // state: ['', Validators.required],
  });
  validation = { name: '', email: '', password: '' };

  constructor(public cart: CartProvider, private toast: ToastService,
    private fb: FormBuilder, private modal: NgbModal,
    private router: Router, private title: BrowserTitle,
    private account: AccountProvider,
    @Inject('appname') public appname: string) {
    this.title.setTitle('Sign Up');
  }

  ngOnInit(): void {
  }

  signup() {
    this.validation = { name: '', email: '', password: '' };
    this.account.register(this.signupForm.value).then((result) => {
      MessageDialogComponent.show(this.modal, 'Welcome!', `Welcome to ${this.appname}! Let's take you to shopping`).finally(() => {
        this.router.navigate(['/shop']);
      });
    }, (response) => {
      if (response.error.validation) {
        this.validation = response.error.validation;
      } else if (response.error.error) {
        this.toast.show(response.error.error, { classname: 'text-light bg-warning' });
      } else {
        this.toast.show(response.message, { classname: 'text-light bg-warning' });
      }
    });
  }

}
