<div class="uk-grid-small" uk-grid>
  <div>
    <div class="uk-cover-container">
      <canvas width="60" height="60"></canvas>
      <img [src]="item.photo" data-src="{{item.photo}}" alt="{{item.product_info.title}}" uk-cover>
    </div>
  </div>
  <!-- <div><img [src]="item.photo" data-src="{{item.photo}}" width="30" alt="{{item.product_info.title}}" uk-img></div> -->
  <div class="uk-width-expand">
    <span *ngIf="item.product;else openItem">
      <a [routerLink]="['/shop', item.product.parent_id]"><b>{{item.product.name}}</b></a>
      <div class="text-xs uk-text-muted">
        <span>Sku: {{item.sku}}</span>
        <span class="uk-margin-small-left" *ngIf="item.product.colour">Color: {{item.product.colour || '-'}}</span>
        <span class="uk-margin-small-left" *ngIf="item.product.size">, Size: {{item.product.size || '-'}}</span>
        <span class="uk-margin-small-left" *ngIf="item.product.flavour">, Flavour: {{item.product.flavour || '-'}}</span>
      </div>
    </span>
    <ng-template #openItem><a>{{item.name}}</a></ng-template>
    <span><b>{{item.total | price_format}}</b> x {{item.quantity}}</span>
  </div>
</div>
