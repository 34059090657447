<div *ngIf="order">
  <div *ngIf="status=='success'" class="uk-text-center">
    <span class="iconify" data-icon="flat-ui:paper-bag" data-inline="false" data-width="50" data-height="50"></span>
    <h1>ORDER CONFIRMATION</h1>
    <p>{{order.shipping.name}}, Thank you for your order!</p>
    <p>We've Received your order and will contact you as soon as your package is shipped. You can find your purchase
      information below.</p>
  </div>
</div>

<h3 class="uk-text-center">
  Order Summary
</h3>
<div class="uk-padding-small" style="border:2px solid #f8f8f8">
  <div class="uk-clearfix">
    <div class="uk-float-left">
      <div><b>Order #{{refno}}</b> <span *ngIf="order" class="uk-label uk-margin-small-left"
          [ngClass]="order.statusclass">{{order.status | titlecase}}</span></div>
    </div>
    <div class="uk-float-right" *ngIf="order && order.delivery_at">
      <div><b>Tracking Number</b> <a href="//track.aftership.com/{{order.delivery_trackingno}}"
          target="_blank">{{order.delivery_trackingno}}</a></div>
    </div>
  </div>
  <hr>
  <app-loading [loaded]="order"></app-loading>

  <div *ngIf="order">
    <div *ngIf="!order.paid && order.balance && order.payment_mode == 'offline'" class="uk-alert-danger" uk-alert>
      <a class="uk-alert-close" uk-close></a>
      <p>Please upload <b>Proof of Payment</b> for Offline Payments below.<br />Otherwise please make payment by
        re-select a payment method.</p>
    </div>

    <div class="uk-alert uk-alert-success" *ngIf="order.isReview">
      <a class="uk-alert-close" uk-close></a>
      <p>We welcome and appreciate your reviews! <a href="#review" uk-scroll><span uk-icon="comment"></span></a></p>
    </div>
    <div class="uk-child-width-1-2 uk-grid">
      <div *ngIf="order.pickup">
        <h5>SELF PICKUP</h5>
        <div [innerHTML]="order.remark_text"></div>
      </div>
      <div *ngIf="!order.pickup" class="">
        <h5>SHIPPING</h5>
        <p>
          <span uk-icon="user" class="uk-margin-small-right"></span>{{order.shipping.name}}<br>
          <span uk-icon="receiver" class="uk-margin-small-right"></span>{{order.shipping.phone}}</p>
        <p class="uk-margin-small-top">
          <span uk-icon="location" class="uk-margin-small-right"></span>
          <span *ngIf="order.shipping.company"><b>{{order.shipping.company}}</b><br /></span>
          <span *ngIf="order.shipping.address">{{order.shipping.address}}, </span>
          <span *ngIf="order.shipping.address_1">{{order.shipping.address_1}}, </span>
          <span *ngIf="order.shipping.address_2">{{order.shipping.address_2}}, </span>
          <span *ngIf="order.shipping.address_3">{{order.shipping.address_3}}, </span>
          <span *ngIf="order.shipping.postcode">{{order.shipping.postcode}} </span>
          <span *ngIf="order.shipping.city">{{order.shipping.city}}, </span>
          <span *ngIf="order.shipping.state">{{order.shipping.state}}</span>
        </p>
      </div>

      <div class="">
        <h5>BILLING</h5>
        <p>
          <span uk-icon="user" class="uk-margin-small-right"></span>{{order.billing.name}}<br>
          <span uk-icon="receiver" class="uk-margin-small-right"></span>{{order.billing.phone}}
        </p>
        <p class="uk-margin-small-top">
          <span uk-icon="location" class="uk-margin-small-right"></span>
          <span *ngIf="order.billing.company"><b>{{order.billing.company}}</b><br /></span>
          <span *ngIf="order.billing.address">{{order.billing.address}}, </span>
          <span *ngIf="!order.billing.address">
            <span *ngIf="order.billing.address_1">{{order.billing.address_1}}, </span>
            <span *ngIf="order.billing.address_2">{{order.billing.address_2}}, </span>
            <span *ngIf="order.billing.address_3">{{order.billing.address_3}}, </span>
          </span>
          <span *ngIf="order.billing.postcode">{{order.billing.postcode}} </span>
          <span *ngIf="order.billing.city">{{order.billing.city}}, </span>
          <span *ngIf="order.billing.state">{{order.billing.state}}</span>
        </p>
      </div>
    </div>
    <hr />
    <ul class="uk-list uk-margin-remove-bottom" [ngClass]="order.isReview == false ? 'uk-child-width-1-2 uk-grid' : ''">
      <li id="review" *ngFor="let item of order.items; let idx = index">
        <div class="uk-grid-small" uk-grid>
          <app-order-item class="uk-width-expand" [item]="item"></app-order-item>
          <div *ngIf="item.product && reviewItems.get(item.product_id)" class="uk-inline">
            <a class="uk-position-top-right" uk-tooltip="Review" uk-icon="icon: comment"
              [attr.uk-toggle]="'target: #item'+(item.product_id)"></a>
            <div id="item{{item.product_id}}" hidden>
              <!-- <small class="uk-text-danger">Please rate this product and comment</small><br> -->
              <!-- <ngb-rating class="uk-text-warning uk-h4" [(rate)]="ratings[item.product_id]" max="5" required></ngb-rating> -->
              <app-rating value="reviewItems.get(item.product_id).rate" (vote)="ratingVoted($event,item.product_id)">
              </app-rating>
              <textarea class="uk-textarea" rows="3" [(ngModel)]="reviewItems.get(item.product_id).review"
                placeholder="Review for {{item.name}}" required></textarea>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="uk-clearfix uk-margin" *ngIf="order.isReview && notAllOpen">
      <button class="uk-float-right uk-button uk-button-small uk-button-primary"
        [disabled]="!canSubmitreview() || submitDisabled" (click)="submitreview()">Submit Review</button>
    </div>
    <hr>
    <div class="uk-flex-right" uk-grid>
      <div id="proof" class="uk-width-expand" *ngIf="order.payment_mode == 'offline'">
        <h5 class="uk-heading"><span class="iconify" data-icon="mdi-receipt"></span>Proof of Payment <i><small>(Limit of
              10MB)</small></i></h5>
        <div>
          <div *ngFor="let r of order.records" uk-lightbox>
            <a *ngFor="let img of r.files" [href]="img"><img style="margin:1px" [src]="checktype(img)" width="60"
                class="uk-margin-small-bottom"></a>
          </div>
        </div>
        <div class="" *ngIf="!order.paid && order.status == 'new'">
          <div class="uk-text-danger">{{validation.paymentproof}}</div>
          <input hidden #fileInput type="file" (change)="uploadFile($event.target.files)" accept="image/jpeg,image/png">
          <div>
            <button class="uk-button uk-button-primary" (click)="fileInput.click()">Select File</button>
            <div *ngIf="paymentForm.value.paymentproof">
              <img [src]="paymentForm.value.paymentproof" style="max-height: 100px;padding: 10px 0;"><br />
            </div>
            <button *ngIf="paymentForm.value.paymentproof" (click)="uploadProof()"
              [disabled]="!paymentForm.value.paymentproof || saveDisabled"
              class="uk-button uk-button-secondary">Upload</button>
          </div>
        </div>
        <div class="uk-margin" *ngIf="order.status == 'new'">
          <hr>
          <h5 class="uk-heading"><span class="iconify" data-icon="mdi-cash-usd"></span> Re-select a payment method</h5>
          <button *ngIf="canPay()" class="uk-button uk-button-primary" (click)="pay()">Change Payment Method</button>
        </div>
      </div>
      <div>
        <div class="uk-flex uk-flex-right">
          <div class="uk-width-medium@m text-xs uk-background-muted uk-padding-small">
            <h5>Order Total</h5>
            <div *ngIf="order.delivery_method" class="uk-grid-small" uk-grid>
              <div class="uk-width-expand">Delivery</div>
              <div>{{order.delivery_method | uppercase}}</div>
            </div>
            <div class="uk-grid-small" uk-grid>
              <div class="uk-width-expand">Payment</div>
              <div>{{order.payment_method | uppercase}}</div>
            </div>
            <div class="uk-grid-small" uk-grid>
              <div class="uk-width-expand">Subtotal</div>
              <div>{{order.subtotal | price_format}}</div>
            </div>
            <div *ngFor="let c of order.charges" class="uk-grid-small" uk-grid>
              <div class="uk-width-expand">{{c.title}}</div>
              <div>{{c.amount | price_format}}</div>
            </div>

            <div *ngIf="order.promocode.promocode_amount" class="uk-grid-small" uk-grid>
              <div class="uk-width-expand">{{order.promocode.code}} ({{order.promocode.value}})</div>
              <div>-{{order.promocode.promocode_amount | price_format}}</div>
            </div>
            <hr>
            <div class="uk-grid-small" uk-grid>
              <div class="uk-width-expand">Total</div>
              <div>{{order.total | price_format}}</div>
            </div>
            <div class="uk-grid-small uk-text-bolder" uk-grid>
              <div class="uk-width-expand">Payment Made</div>
              <div class="uk-text-success" *ngIf="order">{{order.total_paid | price_format}}</div>
            </div>
            <div *ngIf="order.balance" class="uk-grid-small" uk-grid>
              <div class="uk-width-expand">Balance Due</div>
              <div><b>{{order.balance | price_format}}</b></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="order" class="text-xs uk-margin">
  <div>Order Received: <small>{{order.created_at |date_display}}</small></div>
  <div *ngIf="order.paid" class="uk-text-success">Order Paid: <small>{{order.paid_at | date_display}}</small></div>
  <div *ngIf="order.completed_at" class="uk-text-success">Order Completed:
    <small>{{order.completed_at | date_display}}</small></div>
  <div *ngIf="order.cancelled_at" class="uk-text-danger">Order Cancelled:
    <small>{{order.cancelled_at | date_display}}</small></div>
  <div *ngIf="order.processed_at">Order Processed: <small>{{order.processed_at | date_display}}</small></div>
</div>