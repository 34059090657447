<section class="uk-section uk-section-small uk-container">
  <article class="uk-article">
    <h3 class="uk-article-title uk-margin-remove-bottom" *ngIf="webpage?.title" [innerHtml]="webpage?.title"></h3>
    <ul class="uk-breadcrumb uk-margin-remove-top">
      <li><a [routerLink]="['/']">Home</a></li>
      <li><a [routerLink]="['/']+webpage.url">{{webpage?.title}}</a></li>
    </ul>
    <div *ngIf=" webpage" [innerHtml]="webpage?.content | safeHtml" class="uk-section-small"></div>
  </article>
</section>
