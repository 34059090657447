import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Menu } from 'projects/shared/models/menu';
import { ApiProvider } from '../../../services/api.service';
import { Category } from 'projects/shared/models/category';


@Component({
  selector: 'app-shop-sidebar',
  templateUrl: './shop-sidebar.component.html',
  styleUrls: ['./shop-sidebar.component.scss'],
})
export class ShopSidebarComponent implements OnInit {
  @Output('navClick') myEvent: EventEmitter<any> = new EventEmitter();

  selectedIndex: number;

  menu: Menu = new Menu();
  isMenuCollapsed = true;

  categories: Category[];
  keywords: string;
  tagsmenu = new Menu();
  @Input() links: string;
  @Input() name: string;

  constructor(
    private api: ApiProvider,
    public router: Router,
    private route: ActivatedRoute,
  ) {
    this.keywords = this.route.snapshot.queryParamMap.get('search') || '';

    // you can call featured products here similar to home.component.ts and show them under Best Seller
  }

  ngOnInit(): void {
    this.api.getMenu(this.name || 'SHOP').then((result) => {
      this.menu = result;
    }, () => { });

    this.api.getMenu(this.links || 'PRODUCT_TAGS').then((result) => {
      this.tagsmenu = result;
      console.log(this.tagsmenu);
    }, () => { });

    this.api.getCategories().then((result) => {
      this.categories = result;
    }, () => { });
  }

  isActive(url: string) {
    if (url === '/shop') { return (this.router.url === url); }
    return this.router.url.startsWith(url);
  }

  onKeyup(e) {
    if (e.type === 'click' || e.keyCode === 27 || e.keyCode === 13) {
      this.isMenuCollapsed = !this.isMenuCollapsed;
      this.router.navigate(['/shop'], { queryParams: { search: this.keywords } });
      this.navClick('');
    }
  }

  navClick(url) {
    this.myEvent.emit(url);
  }

  setIndex(index: number, id) {
    this.selectedIndex = index;
    document.getElementById(id).removeAttribute("hidden");
  }

  removeIndex(index: number, id) {
    this.selectedIndex = null;
    document.getElementById(id).hidden = true;
  }

}
