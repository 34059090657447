import { Component, Input } from '@angular/core';
import { User } from '../models/user';
import { ApiProvider } from 'projects/web/src/app/services/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-dashboard',
  template: `
    <div class="uk-child-width-1-3 uk-grid">
      <div>
        <div class="uk-card uk-box-shadow-small uk-card-default uk-text-center" style="cursor:pointer" (click)="statpage('processing')">
            <div class="uk-card-body">
            <h3 class="uk-text-secondary">{{user.dashboard.new_orders || 0}}</h3>
            <div class="uk-text-muted">New Orders</div>
            </div>
        </div>
        </div>
      <div>
        <div class="uk-card uk-box-shadow-small uk-card-default uk-text-center" style="cursor:pointer" (click)="statpage('shipping')">
            <div class="uk-card-body">
            <h3 class="uk-text-secondary">{{user.dashboard.pending_shipment || 0}}</h3>
            <div class="uk-text-muted">Pending Shipment</div>
            </div>
        </div>
        </div>
      <div>
        <div class="uk-card uk-box-shadow-small uk-card-default uk-text-center" style="cursor:pointer" (click)="statpage('review')">
            <div class="uk-card-body">
            <h3 class="uk-text-secondary">{{user.dashboard.pending_review || 0}}</h3>
            <div class="uk-text-muted">Pending Review</div>
            </div>
        </div>
        </div>
    </div>

    <div class="uk-child-width-1-2 uk-grid">
      <div>
        <div class="uk-card uk-card-primary uk-text-center">
            <div class="uk-card-body">
            <h2 class="uk-text-secondary">{{(user.dashboard.member_points || 0) | number}}</h2>
            <div class="uk-text-secondary">Member Points</div>
            </div>
        </div>
        </div>

      <div>
        <div class="uk-card uk-card-primary uk-text-center">
            <div class="uk-card-body">
            <h2 class="uk-text-secondary">{{(user.dashboard.credits || 0)|price_format}}</h2>
            <div class="uk-text-secondary">Credits</div>
            </div>
        </div>
      </div>
        <!--
      <div>
        <div class="uk-card uk-card-primary uk-text-center">
            <div class="uk-card-body">
            <h3 class="uk-text-secondary">{{user.dashboard.vouchers || 0}}</h3>
            <div class="uk-text-muted">Vouchers</div>
            </div>
        </div>
        </div>
        -->
    </div>
  `
})
export class ProfileDashboardComponent {
  @Input() user: User;
  constructor(private api: ApiProvider, private router: Router) { }

  ngOnInit(): void {
    // Retrieve dashboard values
    this.api.getDashboard().then((result) => {
      this.user.dashboard = result;
    });
  }

  statpage(stat) {
    this.router.navigate(['/user', 'orders'], { fragment: stat });
  }
}
